import { BaseService } from './base-service';
import { GetCustomReportResultFileRequestDTO, GetCustomReportResultFileResponseDTO } from '@/core/dto/file';

export class FileService extends BaseService {
	private static instance: FileService;

	private constructor(public readonly basePath: string = '/file') {
		super();
	}

	public static getInstance(): FileService {
		if (!FileService.instance) {
			FileService.instance = new FileService();
		}
		return FileService.instance;
	}

	public async getCustomReportResultFile(
		params: GetCustomReportResultFileRequestDTO
	): Promise<GetCustomReportResultFileResponseDTO> {
		const url = this.basePath + `/custom-report-result/${params.custom_report_id}/${params.file_id}`;
		const { data } = await this.getInstance().post<GetCustomReportResultFileResponseDTO>(url, { ...params });
		return data;
	}
}
