import React from 'react';
import { Chart, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { NeedleSVG } from '@/components/Icons';
import { NeedleContainer, Scale, SpeedometerContainer } from './styles';

Chart.register(ArcElement);

type SpinnerProps = {
	level: number;
};

export const Spinner: React.FC<SpinnerProps> = ({ level }) => {
	let colors = ['#2CC852', '#FFDE31', '#F78A38', '#E74150', '#9B54E2'];
	if (level === 0) {
		colors = ['#E6E6E6', '#D9D9D9', '#C8C8C8', '#B3B3B3', '#999999'];
	}
	const options = {
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				enabled: false
			},
			datalabels: {
				display: false
			}
		},
		rotation: -90,
		circumference: 180,
		cutout: '60%',
		maintainAspectRatio: false,
		responsive: true
	};

	return (
		<SpeedometerContainer>
			<Scale>
				<Doughnut
					data={{
						datasets: [
							{
								backgroundColor: colors,
								borderColor: colors,
								borderWidth: 1,
								data: [1, 1, 1, 1, 1],
								rotation: -90
							}
						]
					}}
					options={options}
				/>
			</Scale>
			<NeedleContainer level={level}>
				<NeedleSVG />
			</NeedleContainer>
		</SpeedometerContainer>
	);
};
