export const REPORT_COMMENT_DEBOUNCE_TIME = 3000;
export const URL_LOGO = 'https://kinebot-statics.s3.amazonaws.com/kinebot_logo.png';
export const DIAGNOSTIC_COLORS = ['#ABABAB', '#40BF5F', '#FDC365', '#F07575', '#8E85EF'];

export const ACCEPTABLE_RISK_COLOR = '#2CC852';
export const MODERATE_RISK_COLOR = '#F8D627';
export const HIGH_RISK_COLOR = '#F78A38';
export const VERY_HIGH_RISK_COLOR = '#E74150';
export const SERIOUS_RISK_COLOR = '#9B54E2';

export const EWA_CUSTOM_REPORT = 'ewa_kinebot';
