interface HigherRisksFoundAttributes {
	readonly total_reports: number;
	readonly risk_counts: number[];
	readonly risk_percentages: number[];
}

export class HigherRisksFound {
	public readonly total_reports: number;
	public readonly risk_counts: number[];
	public readonly risk_percentages: number[];

	constructor({ total_reports, risk_counts, risk_percentages }: HigherRisksFoundAttributes) {
		this.total_reports = total_reports;
		this.risk_counts = risk_counts;
		this.risk_percentages = risk_percentages;
	}
}
