import React from 'react';
import { I18n } from '@aws-amplify/core';
import type { DefaultOptionType } from 'antd/lib/select';

import { UserAvatar } from '@/components/ui/UserAvatar';
import { Text } from '@/components/Typography';
import { FormFieldProps } from './types';
import * as S from './styles';

interface ResponsibleProps extends FormFieldProps {
	options?: DefaultOptionType[];
}

export function Responsible({
	options = [],
	formName = [''],
	isLoading = false,
	isDisabled = false,
	isRequired = false
}: Readonly<ResponsibleProps>) {
	const title = I18n.get('Responsible');
	const errorMessage = I18n.get('Select the responsible');

	const userOptions = options.map((user) => {
		return {
			...user,
			label: (
				<>
					<UserAvatar
						name={user.label as string}
						size="small"
						style={{ marginRight: '10px', marginBottom: '3px' }}
					/>
					<Text>{user.label}</Text>
				</>
			)
		};
	});

	return (
		<S.CustomFormItem
			label={title}
			required={isRequired}
			labelCol={{ span: 24 }}
			name={[...formName, 'responsible_id']}
			rules={[{ required: isRequired, message: errorMessage }]}
		>
			<S.CustomSelect
				allowClear
				placeholder={title}
				loading={isLoading}
				disabled={isDisabled}
				options={userOptions}
			/>
		</S.CustomFormItem>
	);
}
