import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Button } from 'antd';

import { BackButton } from '../../components/BackButton';
import { usePersonalisedReportContext } from '../../../context';
import { CreateOrUpdateGenericStepKeyResult } from '../../../context/types';
import { CreateOrUpdateService, StepFormFields } from '../../../context/services';
import { useCalculateScores, useUpdateGenericStepKeyResult } from './hooks';

const { useFormInstance } = Form;

interface FooterProps {
	step_id: string;
}

export function Footer({ step_id }: Readonly<FooterProps>) {
	const form = useFormInstance();
	const {
		personalisedReport,
		step_setting_id,
		organization_id,
		company_id,
		report_id,
		handlePreviousStep,
		handleNextStep,
		scrollToError
	} = usePersonalisedReportContext();

	const { isLoading: calculatingScores, mutateAsync: calculateScores } = useCalculateScores();
	const { isLoading: updatingReport, mutateAsync: updateResult } = useUpdateGenericStepKeyResult();

	const stepKeys = personalisedReport.steps.find(({ id }) => id === step_id)?.step_keys;

	function handleOnPreviousClick(): void {
		handlePreviousStep();
	}

	async function handleOnNextClick(): Promise<void> {
		try {
			const validated = await form.validateFields();
			if (validated) {
				const values: StepFormFields = await form.getFieldValue([`${step_id}`]);
				const createOrUpdateService = new CreateOrUpdateService();
				const payload = createOrUpdateService.mapPayload(values, {
					stepKeys,
					report_id,
					company_id,
					organization_id,
					step_setting_id,
					result_id: personalisedReport.result_id
				});

				if (createOrUpdateService.isGenericStepsPayload(payload)) {
					await update(payload);
				}
			}
		} catch (error: any) {
			scrollToError(error);
		}
	}

	async function update(payload: CreateOrUpdateGenericStepKeyResult): Promise<void> {
		const report = await updateResult(payload);
		if (report.length > 0) {
			await calculateScores({
				company_id,
				organization_id,
				custom_report_id: report_id,
				custom_report_result_id: personalisedReport.result_id
			});
			handleNextStep();
		}
	}

	const isDisabled = calculatingScores || updatingReport;
	const isLoading = calculatingScores || updatingReport;

	return (
		<Row>
			<Col xs={24} style={{ marginTop: '2rem' }}>
				<Row justify="center">
					<BackButton onPreviousClick={handleOnPreviousClick} disabled={isDisabled} />
					<Col>
						<Button
							type="primary"
							htmlType="submit"
							loading={isLoading}
							disabled={isDisabled}
							onClick={handleOnNextClick}
						>
							{I18n.get('Next')}
						</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
