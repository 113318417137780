import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Create } from './Form';
import { Title } from '@/components/Typography';
import { OrganizationDTO } from '@/core/dto/merge-pea';
import { defaultState, MergeAEPContext } from './context';
import { Context, PEAData, PEAQueryParams, SelectedPEA, State } from './types';

export function MergePea() {
	const [state, setState] = useState<State>(defaultState);

	function nextStep(): void {
		setState((prev) => ({ ...prev, currentStep: prev.currentStep + 1 }));
	}

	function prevStep(): void {
		setState((prev) => ({ ...prev, currentStep: prev.currentStep - 1 }));
	}

	function setOrganizations(organizations: OrganizationDTO[]): void {
		setState((prev) => ({ ...prev, organizations }));
	}

	function setOrganizationId(organizationId: string): void {
		setState((prev) => ({ ...prev, organizationId }));
	}

	function setCompanyId(companyId: string): void {
		setState((prev) => ({ ...prev, companyId }));
	}

	function setSectorId(sectorId: string): void {
		setState((prev) => ({ ...prev, sectorId }));
	}

	function setWorkstation(workstation: string): void {
		setState((prev) => ({ ...prev, workstation }));
	}

	function setAnalyst(analyst: string): void {
		setState((prev) => ({ ...prev, analyst }));
	}

	function setFileName(fileName: string): void {
		setState((prev) => ({ ...prev, fileName }));
	}

	function setCollectionDate(collectionDate: string[]): void {
		setState((prev) => ({ ...prev, collectionDate }));
	}

	function setCreatedAt(createdAt: string[]): void {
		setState((prev) => ({ ...prev, createdAt }));
	}

	function setSelectedPEA(selectedPEA: SelectedPEA[]): void {
		setState((prev) => ({ ...prev, selectedPEA }));
	}

	function setData(data: PEAData): void {
		setState((prev) => ({
			...prev,
			data: {
				...prev.data,
				...data
			}
		}));
	}

	function setQueryParams(changedQueryParam: PEAQueryParams): void {
		setState((prev) => ({
			...prev,
			queryParams: {
				...changedQueryParam
			}
		}));
	}

	const context: Context = {
		...state,
		nextStep,
		prevStep,
		setData,
		setOrganizationId,
		setCompanyId,
		setOrganizations,
		setSectorId,
		setWorkstation,
		setCollectionDate,
		setCreatedAt,
		setAnalyst,
		setFileName,
		setSelectedPEA,
		setQueryParams
	};

	return (
		<MergeAEPContext.Provider value={context}>
			<Row gutter={[0, 32]} justify={'center'}>
				<Col span={12}>
					<Title style={{ textAlign: 'center' }} level={4}>
						Ergonomic Workplace Analysis
					</Title>
				</Col>
				<Col span={24}>
					<Create />
				</Col>
			</Row>
		</MergeAEPContext.Provider>
	);
}
