import React, { createContext, ReactNode, useContext, useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { Form } from 'antd';
import moment from 'moment';

import * as Socket from '@/hooks/useSocket';
import { useCalculateCustomReportStepKeyRisk } from '../hooks';
import { usePersonalisedReportContext } from '../../../../context';
import { AntdDropdownOption, DropdownOptions } from '../../../../context/types';
import { Context, ergonomicToolReportSocketMapper, Methods, States } from './types';
import { InformationsResponse, PersonalisedReportStepKeysResponse } from '../../../../hooks';
import { useCreateReport } from '@/hooks';
import { FileTools, ToolType } from '@/components/ToolCard/types';
import { useGetActionPlanFromReport } from '@/hooks/v2/useGetActionPlanFromReport';

const { useFormInstance, useWatch } = Form;

type PersonalisedReportStepKeyProviderProps = {
	stepId: string;
	children: ReactNode;
	stepKeyFieldName: string[];
	stepKey: PersonalisedReportStepKeysResponse;
};

type SocketResponse =
	| Socket.RebaResponse
	| Socket.NioshResponse
	| Socket.KimPpResponse
	| Socket.KimMhoResponse
	| Socket.AngleTimeResponse
	| Socket.StrainIndexResponse
	| Socket.LibertyMutualResponse
	| null;

const PersonalisedReportStepKeyContext = createContext<Context>({} as Context);

const TOOL_URL = {
	reba: 'reba',
	rula: 'rula',
	niosh: 'niosh',
	'kim-pp': 'kim_pp',
	'kim-mho': 'kim_mho',
	'strain-index': 'strain_index',
	'liberty-mutual': 'liberty_mutual'
};

export function PersonalisedReportStepKeyProvider({
	stepId,
	stepKey,
	children,
	stepKeyFieldName
}: Readonly<PersonalisedReportStepKeyProviderProps>) {
	const form = useFormInstance();
	const { personalisedReport, file_id, company_id, organization_id } = usePersonalisedReportContext();
	const exposureId = useWatch([...stepKeyFieldName, 'exposure_id'], form);
	const consequenceId = useWatch([...stepKeyFieldName, 'consequence_id'], form);
	const probabilityId = useWatch([...stepKeyFieldName, 'probability_id'], form);
	const createReport = useCreateReport();

	async function handleCreateTool(toolName: ToolType) {
		const { basic_information } = personalisedReport || {};
		const tool_url = TOOL_URL[toolName] as FileTools;
		let id = basic_information?.[tool_url]?.id;

		if (!id) {
			const result = await createReport.mutateAsync({
				file_id: file_id ?? undefined,
				tool: `/ergonomic-tool/${toolName}`,
				activity_id: basic_information?.activity_id as string,
				evaluator_id: basic_information?.evaluator_id as string,
				collection_date: basic_information?.collection_date as Date,
				cellule_id: basic_information?.cellule_id,
				report_name: basic_information?.report_name as string,
				workstation_id: basic_information?.workstation_id as string,
				sector_id: basic_information?.sector_id as string,
				organization_id,
				company_id
			});

			id = result.file_id ?? result.id;
		}

		const url = `/reporting/${toolName}/${id}?organization_id=${organization_id}&company_id=${company_id}`;
		window.open(url, '_blank');
	}

	function mapErgonomicTools(tools: Socket.IReportState, informations?: InformationsResponse): Socket.IReportState {
		if (!informations) {
			return {} as Socket.IReportState;
		}
		const { file_id } = informations;
		const ergonomicTool: Socket.IReportState = defineTools(tools, informations, file_id);
		return ergonomicTool;
	}

	function defineTools(
		tools: Socket.IReportState,
		informations: InformationsResponse,
		file_id: string | undefined
	): Socket.IReportState {
		const ergonomicTool = {} as Socket.IReportState;
		for (const tool in tools) {
			const typedTool = tool as Socket.Tools;
			const report = tools[typedTool];
			const ergonomicToolResponse = informations[ergonomicToolReportSocketMapper[typedTool]] as SocketResponse;
			ergonomicTool[typedTool] = {
				status: toolExists(report, file_id, ergonomicToolResponse) ?? false,
				data: report.data ?? ergonomicToolResponse ?? undefined
			};
		}
		return ergonomicTool;
	}

	function toolExists(
		report: Socket.ReportEvent,
		file_id: string | undefined,
		ergonomicToolResponse: SocketResponse
	): boolean | undefined {
		return checkToolByFile(report, file_id) ?? fileHasTool(ergonomicToolResponse, file_id);
	}

	function checkToolByFile(tool: Socket.ReportEvent, file_id?: string) {
		const existingToolForFile = tool?.data?.file_id === file_id;
		if (!existingToolForFile) {
			return undefined;
		}
		return tool.status;
	}

	function fileHasTool(tool: Socket.ErgonomicToolReportResponse | null, file_id?: string) {
		if (file_id) {
			return !!tool?.id;
		}

		const hasTool =
			hasReba(tool) ||
			hasStrainIndex(tool) ||
			hasNiosh(tool) ||
			hasKimPp(tool) ||
			hasKimMho(tool) ||
			hasLibertyMutual(tool);

		return hasTool;
	}

	function hasReba(tool: Socket.ErgonomicToolReportResponse | null): boolean {
		return !!(tool as Socket.RebaResponse)?.score_seconds;
	}

	function hasStrainIndex(tool: Socket.ErgonomicToolReportResponse | null): boolean {
		return (
			!!(tool as Socket.StrainIndexResponse)?.score_left_rsi ||
			!!(tool as Socket.StrainIndexResponse)?.score_right_rsi
		);
	}

	function hasNiosh(tool: Socket.ErgonomicToolReportResponse | null): boolean {
		return !!(tool as Socket.NioshResponse)?.lifting_index;
	}

	function hasKimPp(tool: Socket.ErgonomicToolReportResponse | null): boolean {
		return !!(tool as Socket.KimPpResponse)?.score;
	}

	function hasKimMho(tool: Socket.ErgonomicToolReportResponse | null): boolean {
		return !!(tool as Socket.KimMhoResponse)?.risk_score;
	}

	function hasLibertyMutual(tool: Socket.ErgonomicToolReportResponse | null): boolean {
		return (
			!!(tool as Socket.LibertyMutualResponse)?.percentile_man ||
			!!(tool as Socket.LibertyMutualResponse)?.percentile_woman
		);
	}

	const {
		data: levelRisk,
		isLoading: gettingLevelRisk,
		isFetching: fetchingLevelRisk,
		isError: errorCalculatingRisk
	} = useCalculateCustomReportStepKeyRisk({
		company_id,
		organization_id,
		step_key_id: stepKey.id,
		exposure_id: exposureId,
		consequence_id: consequenceId,
		probability_id: probabilityId,
		custom_report_score_scale_id: personalisedReport?.custom_report_score_scale_id
	});

	const {
		data: actionPlan,
		isFetching: fetchingActionPlan,
		isLoading: gettingActionPlan,
		isError: errorGettingActionPlan
	} = useGetActionPlanFromReport({
		file_id: file_id ?? '',
		company_id: company_id,
		organization_id: organization_id,
		custom_report_step_key_id: stepKey.id,
		custom_report_result_id: personalisedReport?.result_id
	});

	useEffect(() => {
		const actionPlanFieldName = [...stepKeyFieldName, 'action_plans'];
		form.setFieldValue([...actionPlanFieldName, 'deadline'], moment(actionPlan?.due_date));
		form.setFieldValue([...actionPlanFieldName, 'description'], actionPlan?.description);
		form.setFieldValue([...actionPlanFieldName, 'title'], actionPlan?.title);
		form.setFieldValue([...actionPlanFieldName, 'responsible_user_id'], actionPlan?.responsible_user_id);
	}, [actionPlan]);

	if (levelRisk) {
		form.setFieldValue([...stepKeyFieldName, 'score'], levelRisk.score);
		form.setFieldValue([...stepKeyFieldName, 'result'], levelRisk.result);
	}

	function mapStepKeyDropdownOptions(options: DropdownOptions): AntdDropdownOption[] {
		return options.map(({ id, description }) => ({
			value: id,
			label: I18n.get(description)
		}));
	}

	const actionPlans = {
		errorGettingActionPlan,
		gettingActionPlan: gettingActionPlan || fetchingActionPlan,
		actionPlan: errorGettingActionPlan ? undefined : actionPlan
	};

	const states: States = {
		stepId,
		stepKeyFieldName,
		stepKeyId: stepKey.id,
		checked: stepKey.checked,
		actionPlanData: actionPlans,
		hasInjury: stepKey.has_injury,
		ergonomicTools: stepKey.ergonomic_tools,
		stepKeySettingId: stepKey.custom_report_step_key_setting_id,
		riskLevelData: {
			levelRisk,
			errorCalculatingRisk,
			gettingRisk: gettingLevelRisk || fetchingLevelRisk
		},
		activity_id: personalisedReport.basic_information?.activity_id
	};

	const methods: Methods = {
		handleCreateTool,
		mapErgonomicTools,
		mapStepKeyDropdownOptions
	};

	const context: Context = {
		...states,
		...methods
	};

	return (
		<PersonalisedReportStepKeyContext.Provider value={context}>
			{children}
		</PersonalisedReportStepKeyContext.Provider>
	);
}

export function usePersonalisedReportStepKeyContext() {
	const context = useContext(PersonalisedReportStepKeyContext);
	return context;
}
