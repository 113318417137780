import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { Row, Col, Button, Dropdown, Form } from 'antd';
import { CheckOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';

import { ModalDelete } from '../../../ModalDelete';
import { UserAvatar } from '@/components/ui/UserAvatar';
import { ResponsibleDropdown, TaskName } from '../../components/Fields';
import { DueDateDropdown } from '../../components/Fields/DueDateDropdown';

import { useDeleteActionPlanTask } from '@/hooks/v2/useDeleteActionPlanTask';
import { useUpdateActionPlanTask } from '@/hooks/v2/useUpdateActionPlanTask';
import type { GetActionPlanTasksResponse } from '@/hooks/v2/useGetActionPlanTasks';
import { GetResponsibleOptionsResponse } from '@/hooks/v2/useGetResponsibleOptions';
import { GetActionPlanFromReportResponse } from '@/hooks/v2/useGetActionPlanFromReport';
import { useUpdateActionPlanTaskStatus } from '@/hooks/v2/useUpdateActionPlanTaskStatus';
import * as S from './styles';

const { useFormInstance, useWatch } = Form;

interface TaskItemProps {
	users?: GetResponsibleOptionsResponse[];
	data: GetActionPlanTasksResponse;
	actionPlan: GetActionPlanFromReportResponse;
}

export function TaskItem({ data, users, actionPlan }: Readonly<TaskItemProps>) {
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);

	const form = useFormInstance();
	const fieldName = useMemo(() => ['edit_task', data.id], [data.id]);
	const isChecked = useWatch([...fieldName, 'is_completed']);

	const company_id = actionPlan.company.id;
	const organization_id = actionPlan.organization.id;

	const { mutateAsync: deleteTask } = useDeleteActionPlanTask();
	const { mutateAsync: updateTask } = useUpdateActionPlanTask();
	const { mutateAsync: updateTaskStatus } = useUpdateActionPlanTaskStatus();

	async function handleOnDelete(): Promise<void> {
		const payload = {
			company_id,
			organization_id,
			action_plan_task_id: data.id
		};

		await deleteTask(payload);
	}

	async function handleOnSaveEdition(): Promise<void> {
		const formValues = form.getFieldValue(fieldName);

		const payload = {
			company_id,
			organization_id,
			title: formValues.task_name,
			action_plan_task_id: data.id,
			due_date: formValues.due_date,
			responsible_user_id: formValues.responsible_id
		};

		await updateTask(payload);
		handleToggleEdition();
	}

	async function handleToggleCheck(): Promise<void> {
		const payload = {
			company_id,
			organization_id,
			action_plan_task_id: data.id
		};

		await updateTaskStatus(payload);
	}

	function handleToggleEdition(): void {
		setIsEditing(!isEditing);
	}

	function handleToggleDelete(): void {
		setIsDeleteOpen(!isDeleteOpen);
	}

	useEffect(() => {
		form.setFieldValue([...fieldName, 'task_name'], data.title);
		form.setFieldValue([...fieldName, 'is_completed'], data.is_completed);
		form.setFieldValue([...fieldName, 'due_date'], data.due_date ? moment(data.due_date) : undefined);
		form.setFieldValue([...fieldName, 'responsible_id'], data.responsible_user_id);
	}, [form, fieldName, data.title, data.is_completed, data.due_date, data.responsible_user_id]);

	const dropdownActions = [
		{
			key: 'edit',
			label: (
				<div style={{ color: '#2F54EB', fontSize: '1.5rem' }}>
					<EditOutlined />
				</div>
			),
			onClick: handleToggleEdition
		},
		{
			key: 'delete',
			label: (
				<div style={{ color: '#E74150', fontSize: '1.5rem' }}>
					<DeleteOutlined />
				</div>
			),
			onClick: handleToggleDelete
		}
	];

	if (isEditing) {
		return (
			<Row justify="space-between" align="middle">
				<Col span={19}>
					<TaskName formName={fieldName} />
				</Col>
				<Col>
					<DueDateDropdown formName={fieldName} />
				</Col>
				<Col>
					<ResponsibleDropdown formName={fieldName} options={users} />
				</Col>
				<Col>
					<Button
						size="small"
						type="primary"
						shape="circle"
						icon={<CheckOutlined />}
						onClick={handleOnSaveEdition}
					/>
				</Col>
			</Row>
		);
	}

	return (
		<>
			<Row justify="space-between" align="middle">
				<Col span={18}>
					<Form.Item name={[...fieldName, 'is_completed']} style={{ margin: 0 }} valuePropName="checked">
						<S.Description $checked={isChecked} onClick={handleToggleCheck}>
							{data?.title}
						</S.Description>
					</Form.Item>
				</Col>
				<Col span={6}>
					<Row>
						<Col span={13} style={{ display: 'flex', justifyContent: 'center' }}>
							<span style={{ fontSize: '0.8rem' }}>
								{data?.due_date ? moment(data?.due_date).format('L') : '-'}
							</span>
						</Col>
						<Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
							<UserAvatar name={data.responsible_user?.name} />
						</Col>
						<Col span={2}>
							<Dropdown trigger={['click']} placement="bottomRight" menu={{ items: dropdownActions }}>
								<S.TransparentButton style={{ fontSize: 25 }}>
									<EllipsisOutlined />
								</S.TransparentButton>
							</Dropdown>
						</Col>
					</Row>
				</Col>
			</Row>
			<ModalDelete
				open={isDeleteOpen}
				onConfirm={handleOnDelete}
				onCancel={handleToggleDelete}
				description="Are you sure you want to delete the selected task?"
			/>
		</>
	);
}
