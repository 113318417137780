import React, { useContext } from 'react';
import { I18n } from '@aws-amplify/core';
import { Form, Select, message } from 'antd';

import { useEvaluators } from '@/hooks';
import { MergeAEPContext } from '../../context';

export function Analyst() {
	const { organizationId, companyId, setAnalyst, queryParams, setQueryParams } = useContext(MergeAEPContext);

	const { isLoading, data, isError } = useEvaluators({ organization_id: organizationId, company_id: companyId });

	function handleOnChange(value: string): void {
		setAnalyst(value);
		setQueryParams({ ...queryParams, analyst: value });
	}

	if (isError) {
		message.error(I18n.get('Failed to search for analysts'));
	}

	const options = data?.map(({ id, name }) => ({ label: name, value: id }));

	return (
		<Form.Item name="analyst">
			<Select
				loading={isLoading}
				onChange={handleOnChange}
				placeholder={I18n.get('Analyst')}
				disabled={!organizationId || !companyId}
				options={options}
			/>
		</Form.Item>
	);
}
