import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { DeleteOutlined } from '@ant-design/icons';
import { Paragraph } from '@/components/Typography';
import * as S from './styles';

interface ModalDeleteProps {
	open: boolean;
	description: string;
	onCancel(): void;
	onConfirm(): void;
}

export function ModalDelete({ open, description, onCancel, onConfirm }: Readonly<ModalDeleteProps>) {
	return (
		<S.ModalStyled width={500} footer={null} open={open} onCancel={onCancel} centered>
			<Row justify="center" gutter={[0, 30]}>
				<Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<DeleteOutlined style={{ color: S.COLOR_DANGER, fontSize: 70 }} />
				</Col>
				<Col span={22}>
					<Paragraph style={{ fontSize: '1.6rem', textAlign: 'center' }}>{description}</Paragraph>
				</Col>
				<Col>
					<Row justify="center" gutter={[25, 0]}>
						<Col>
							<S.SecondaryButton onClick={onCancel}>{I18n.get('Cancel')}</S.SecondaryButton>
						</Col>
						<Col>
							<S.PrimaryButton type="primary" onClick={onConfirm}>
								{I18n.get('Confirm')}
							</S.PrimaryButton>
						</Col>
					</Row>
				</Col>
			</Row>
		</S.ModalStyled>
	);
}
