import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/action-plans';

type Origin = {
	file_id: string | null;
	report_id: string | null;
	name: string | null;
};

type Workstation = {
	id: string;
	name: string;
};

type Line = {
	id: string;
	name: string;
};

type Sector = {
	id: string;
	name: string;
};

type Company = {
	id: string;
	name: string;
};

type Organization = {
	id: string;
	name: string;
};

type Author = {
	name: string;
	url_logo: string | null;
};

export interface GetActionPlanFromReportRequest {
	id?: string;
	file_id: string;
	company_id: string;
	organization_id: string;
	custom_report_result_id?: string;
	custom_report_step_key_id?: string;
	custom_report_sub_step_key_id?: string;
	preliminary_analysis_step_id?: string;
}

export interface GetActionPlanFromReportResponse {
	id: string;
	title: string;
	status: string;
	score: number | null;
	priority: number;
	investment_range: number;
	investment_value: number | null;
	description: string | null;
	due_date: Date;
	lexo_rank: string;
	file_id: string | null;
	activity_id: string | null;
	workstation_id: string;
	user_id: string;
	responsible_user_id: string;
	action_plan_origin_id: string;
	completed_at: Date | null;
	created_at: Date;
	updated_at: Date;
	deleted_at: null;
	author: Author;
	organization: Organization;
	company: Company;
	sector: Sector;
	line: Line;
	origin?: Origin;
	workstation: Workstation;
}

export const useGetActionPlanFromReport = (params: GetActionPlanFromReportRequest) => {
	return useQuery([QUERY_KEYS.GET_ACTION_PLAN_FROM_REPORT, params], () => Service.findOneFromReport(params), {
		enabled: !!params.organization_id && !!params.company_id,
		retry: 0
	});
};
