import React, { useContext } from 'react';
import { I18n } from '@aws-amplify/core';
import { Form, DatePicker } from 'antd';

import { MergeAEPContext } from '../../context';

export function CollectionDate() {
	const { organizationId, companyId, setCollectionDate, queryParams, setQueryParams } = useContext(MergeAEPContext);

	function handleOnChange(date: any, dateString: string[]) {
		setCollectionDate(dateString);
		setQueryParams({
			...queryParams,
			collectionDate: date ? [date?.[0]?.toISOString(), date?.[1]?.toISOString()] : undefined
		});
	}

	return (
		<Form.Item label={I18n.get('Collection date')} name="collectionDate">
			<DatePicker.RangePicker
				format="L"
				disabled={!organizationId || !companyId}
				onChange={handleOnChange}
				showTime={{ format: 'HH:mm:00' }}
				style={{ width: '100%' }}
			/>
		</Form.Item>
	);
}
