import React, { useContext, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form } from 'antd';
import moment from 'moment';

import { useGetCustomReportByName } from '@/views/Dashboard/hooks/useGetCustomReportByName';
import { useListCustomReportResultPaginated } from '@/hooks';
import { PaginationCustom } from '@/components/Pagination';
import { BaseReportsPaginatedResponse } from '@/types';
import { EWA_CUSTOM_REPORT } from '@/utils/constants';
import { MergeAEPContext } from '../context';
import { TableList } from './TableList';
import { Filter } from './Filter';
import { SelectedPEA } from '../types';
import { TextCustom } from './styles';

interface ChecklistProps {
	onClearFilter: (organizationId?: string) => void;
}

export function Checklist({ onClearFilter }: Readonly<ChecklistProps>) {
	const [page, setPage] = useState<number>(1);
	const [limit, setLimit] = useState<number>(10);

	const {
		queryParams,
		organizationId,
		data: { pea_list },
		setQueryParams
	} = useContext(MergeAEPContext);

	const { companyId, sectorId, fileName, workstationId, createdAt, analyst, collectionDate } = queryParams;

	const { data: pea, isLoading: isLoadingPea } = useGetCustomReportByName({ name: EWA_CUSTOM_REPORT });

	const { isLoading, data, refetch } = useListCustomReportResultPaginated({
		collection_date_start: collectionDate?.[0] || undefined,
		collection_date_end: collectionDate?.[1] || undefined,
		created_at_start: createdAt?.[0] || undefined,
		created_at_end: createdAt?.[1] || undefined,
		workstation_id: workstationId || undefined,
		original_name: fileName || undefined,
		company_id: companyId || undefined,
		sector_id: sectorId || undefined,
		organization_id: organizationId,
		custom_report_id: pea?.id,
		isSelectedReport: true,
		evaluator_id: analyst,
		is_consolidated: true,
		offset: page - 1,
		limit
	});

	function handleOnChangePagination(page: number, pageSize: number): void {
		setLimit(pageSize);
		setPage(page);
	}

	function handleOnChangeRowsPerPage(limit: number): void {
		setLimit(limit);
	}

	function mountingPEA(pea: BaseReportsPaginatedResponse): SelectedPEA {
		const { id, file_name, score, created_at, collection_date, evaluator_name } = pea;
		const dateOfCollection = moment(collection_date).format('DD-MM-YYYY');
		const createdAt = moment(created_at).format('DD-MM-YYYY');
		return {
			analystName: evaluator_name,
			score: score?.result,
			fileName: file_name,
			dateOfCollection,
			createdAt,
			key: id,
			id
		};
	}

	async function handleFilter(): Promise<void> {
		const element = document.getElementById('tableList');
		element?.scrollIntoView({ behavior: 'smooth' });
		setQueryParams({ ...queryParams, clearFilter: false });
		refetch();
	}

	return (
		<Col lg={24} xl={20} xxl={16}>
			<Row gutter={[10, 10]}>
				<Col span={24}>
					<Filter onFilter={handleFilter} onClearFilter={onClearFilter} />
				</Col>
				<Col>
					<TextCustom>
						{
							"To generate this document, it's necessary that the EWA's must be consolidated, so the list below only shows the consolidated EWA's."
						}
					</TextCustom>
				</Col>
				<Col span={24} id="tableList">
					<Form.Item
						name="pea_list"
						rules={[
							() => ({
								validator() {
									return !pea_list || pea_list.length === 0 || !pea_list[0]
										? Promise.reject(I18n.get('Select a file'))
										: Promise.resolve();
								}
							})
						]}
					>
						<TableList isLoading={isLoading || isLoadingPea} data={data?.rows.map(mountingPEA)} />
						<PaginationCustom
							current={page}
							pageSize={limit}
							showSizeChanger={false}
							total={data?.count || 0}
							onChange={handleOnChangePagination}
							onRowsPerPageChange={handleOnChangeRowsPerPage}
						/>
					</Form.Item>
				</Col>
			</Row>
		</Col>
	);
}
