import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { InfoTooltip } from './components/Tooltips/Information';
import type { FormFieldProps } from './types';
import * as S from './styles';

export function Priority({
	formName = [''],
	isLoading = false,
	isDisabled = false,
	isRequired = false
}: Readonly<FormFieldProps>) {
	const title = I18n.get('Priority');

	const options = [
		{ label: I18n.get('Low'), value: 1 },
		{ label: I18n.get('Medium'), value: 2 },
		{ label: I18n.get('High'), value: 3 }
	];

	return (
		<Row>
			<Col>
				<span style={{ fontSize: '1.1rem' }}>{title}</span>
			</Col>
			<Col span={12}>
				<InfoTooltip title={I18n.get('Categorization of the urgency of action plan resolution')} />
			</Col>
			<Col span={24}>
				<S.CustomFormItem name={[...formName, 'priority']} required={isRequired}>
					<S.CustomSelect
						allowClear
						options={options}
						loading={isLoading}
						placeholder={title}
						disabled={isDisabled}
						style={{ marginTop: '13px' }}
					/>
				</S.CustomFormItem>
			</Col>
		</Row>
	);
}
