import React, { useState } from 'react';
import moment from 'moment';
import { Row, Col, Form } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import type { FormFieldProps } from './types';
import * as S from './styles';

const { useWatch } = Form;

interface DueDateDropdownProps extends FormFieldProps {
	limitDate?: Date;
}

export function DueDateDropdown({
	formName = [''],
	isRequired = false,
	limitDate = undefined
}: Readonly<DueDateDropdownProps>) {
	const fieldName = [...formName, 'due_date'];
	const dueDate = useWatch(fieldName);
	const [isOpen, setIsOpen] = useState<boolean>(false);

	function handleToggleDatePicker(): void {
		setIsOpen(!isOpen);
	}

	return (
		<Row>
			<Col>
				<S.IconButton $isSelected={!!dueDate} onClick={handleToggleDatePicker}>
					<CalendarOutlined />
				</S.IconButton>
			</Col>
			<Col>
				<S.CustomFormItem required={isRequired} name={fieldName}>
					<S.InvisibleDatePicker
						open={isOpen}
						placement="bottomRight"
						onOpenChange={handleToggleDatePicker}
						disabledDate={limitDate ? (current) => current > moment(limitDate) : undefined}
					/>
				</S.CustomFormItem>
			</Col>
		</Row>
	);
}
