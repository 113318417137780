import { QUERY_KEYS } from '@/utils/query-keys';
import { useQuery } from '@tanstack/react-query';
import { CustomReportService } from '@/infra/services/custom-report';

export interface GetCustomReportByNameParams {
	name: string;
}

const Service = CustomReportService.getInstance();

export const useGetCustomReportByName = (params: GetCustomReportByNameParams) => {
	return useQuery([QUERY_KEYS.GET_CUSTOM_REPORT_EVOLUTION, params], () => Service.findOneByName(params), {
		retry: 1
	});
};
