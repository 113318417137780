import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
	Reba,
	Niosh,
	Injury,
	Response,
	Exposure,
	AngleTime,
	ActionPlan,
	ToolsNames,
	Probability,
	Consequence,
	StrainIndex,
	KimPushPull,
	ErgonomicTool,
	LibertyMutual,
	KimManualHandling,
	CustomReportResult,
	WorkerSelfEvaluation,
	CustomReportStepKeyResult,
	CustomReportSubStepKeyResult,
	CustomReportResultWorkCondition,
	CustomReportResultCharacteristic
} from '@/types';
import Api from '@/services/api';
import { ActionPlanTask } from '@/types/ActionPlans';
import { BaseContext } from '@/components/ui/Inputs/types/request';

export type GetResultDTO = BaseContext & {
	file_id?: string;
	report_id: string;
	custom_report_result_id?: string;
};

export type ErgonomicToolResponse = Pick<ErgonomicTool, 'id' | 'name' | 'title' | 'subtitle' | 'description'>;

export type PersonalisedReportResultInformationsResponse = Pick<
	CustomReportResult,
	| 'id'
	| 'sum_score'
	| 'role_name'
	| 'updated_at'
	| 'worst_score'
	| 'average_score'
	| 'activity_name'
	| 'collection_date'
>;

export type PersonalisedReportResultExposureResponse = Pick<Exposure, 'id' | 'name' | 'description'>;
export type PersonalisedReportResultInjuryResponse = Pick<Injury, 'id' | 'name' | 'description'>;
export type PersonalisedReportResultProbabilityResponse = Pick<Probability, 'id' | 'name' | 'description'>;
export type PersonalisedReportResultConsequenceResponse = Pick<Consequence, 'id' | 'name' | 'description'>;
export type PersonalisedReportResultBasicStepKeyResponse = Pick<CustomReportStepKeyResult, 'id' | 'score' | 'result'>;
export type PersonalisedReportResultBasicSubStepKeyResponse = Pick<
	CustomReportSubStepKeyResult,
	'id' | 'score' | 'result'
>;
type PersonalisedReportResultSubStepKeyResponse = PersonalisedReportResultBasicSubStepKeyResponse & {
	description: string;
	exposure: PersonalisedReportResultExposureResponse;
	probability: PersonalisedReportResultProbabilityResponse;
	consequence: PersonalisedReportResultConsequenceResponse;
	sub_step_keys_injuries: PersonalisedReportResultInjuryResponse[];
};

export type PersonalisedReportResultStepKeyResponse = PersonalisedReportResultBasicStepKeyResponse & {
	description: string;
	exposure: PersonalisedReportResultExposureResponse;
	injuries: PersonalisedReportResultInjuryResponse[];
	probability: PersonalisedReportResultProbabilityResponse;
	consequence: PersonalisedReportResultConsequenceResponse;
	sub_step_keys_results?: PersonalisedReportResultSubStepKeyResponse[];
};

export enum HandCouplingMapper {
	'GOOD' = 'Good',
	'FAIR' = 'Fair',
	'POOR' = 'Poor'
}

export enum FrequencyTimeFormatMapper {
	'MINUTE' = 'minute',
	'HOUR' = 'hour'
}

export enum TaskMapper {
	'Lifts' = 'lifts',
	'Lowers' = 'lowers',
	'Pushes' = 'pushes',
	'Pulls' = 'pulls',
	'Carries' = 'carries'
}

export enum TaskNameMapper {
	'Lift' = 'Lift',
	'Lower' = 'Lower',
	'Push' = 'Push',
	'Pull' = 'Pull',
	'Carry' = 'Carry'
}

export enum SystemOfUnitsName {
	'imperial' = 'imperial',
	'metric' = 'metric'
}

export type SummaryFrequency = {
	value: number;
	type: TaskMapper;
	interval: FrequencyTimeFormatMapper;
};

export type PersonalisedReportResultStepResponse = {
	id: string;
	description: string;
	step_keys: PersonalisedReportResultStepKeyResponse[];
};

export type NioshResponse = Pick<
	Niosh,
	| 'id'
	| 'risk'
	| 'mass_m'
	| 'file_id'
	| 'updatedAt'
	| 'lifting_index'
	| 'reference_weight'
	| 'recommended_weight_limit'
>;

export type KimMhoResponse = Pick<
	KimManualHandling,
	| 'id'
	| 'file_id'
	| 'duration'
	| 'risk_load'
	| 'updatedAt'
	| 'risk_score'
	| 'force_transfer'
	| 'left_force_intensity'
	| 'right_force_intensity'
>;
export type LibertyMutualBasicResponse = Pick<
	LibertyMutual,
	| 'id'
	| 'file_id'
	| 'object_weight'
	| 'initial_force'
	| 'percentile_man'
	| 'sustained_force'
	| 'end_hand_height'
	| 'percentile_woman'
	| 'start_hand_height'
	| 'end_hand_distance'
	| 'horizontal_distance'
	| 'start_hand_distance'
	| 'vertical_hand_height'
	| 'percentile_man_initial'
	| 'percentile_man_sustain'
	| 'percentile_woman_initial'
	| 'percentile_woman_sustain'
>;
export type LibertyMutualResponse = LibertyMutualBasicResponse & {
	unit: string;
	task_name: string;
	frequency: SummaryFrequency;
	hand_coupling: HandCouplingMapper;
	system_of_units: SystemOfUnitsName;
};
export type StrainIndexResponse = Pick<
	StrainIndex,
	| 'id'
	| 'file_id'
	| 'updatedAt'
	| 'left_risk'
	| 'right_risk'
	| 'score_left_rsi'
	| 'score_right_rsi'
	| 'score_left_borg_scale'
	| 'score_right_borg_scale'
	| 'score_left_wrist_posture'
	| 'score_right_wrist_posture'
	| 'score_left_daily_duration'
	| 'score_right_daily_duration'
	| 'score_left_exertion_duration'
	| 'score_right_exertion_duration'
	| 'score_left_efforts_per_minute'
	| 'score_right_efforts_per_minute'
>;
export type AngleTimeResponse = Pick<AngleTime, 'id' | 'file_id' | 'range_risk_id'>;
export type KimPpBasicResponse = Pick<KimPushPull, 'mass' | 'vehicle' | 'updatedAt'>;
export type KimPpResponse = KimPpBasicResponse & {
	note: number;
	distance?: string;
	duration?: string;
	risk_range: string;
	measurements: string;
	possible_health_consequences: string;
	probability_of_physical_overload: string;
};
export type RebaBodyPartScores = {
	id: number;
	text: string;
	level: number;
	percentage: number;
};
export type RebaData = {
	id: number;
	name: string;
	scores: RebaBodyPartScores[];
};
export type RebaDataResponse = RebaData[];
export type RebaBasicResponse = Pick<Reba, 'id' | 'file_id' | 'force' | 'coupling' | 'repetition' | 'score_seconds'>;
export type RebaResponse = RebaBasicResponse & {
	data: RebaDataResponse[];
};

export type DeadlineResponse = {
	day: number;
	year: number;
	month: number;
	full_date: Date;
};
export type ActionPlanTaskResponse = Pick<ActionPlanTask, 'id' | 'title' | 'is_completed'>;
export type ActionPlanBasicResponse = Pick<ActionPlan, 'id' | 'title' | 'description' | 'created_at'>;
export type ActionPlanResponse = ActionPlanBasicResponse & {
	responsible_user: string;
	deadline: DeadlineResponse;
};
export type ActionPlanWithTaskResponse = ActionPlanResponse & {
	action_plan_tasks?: ActionPlanTaskResponse[];
};

export type PersonalisedReportResultBasicResponse = Pick<
	CustomReportResult,
	'id' | 'comment' | 'consolidated' | 'file_id'
>;

export type WorkerSelfEvaluationResponse = Pick<WorkerSelfEvaluation, 'id' | 'description' | 'score' | 'name'>;
export type CharacteristicsBasicResponse = Pick<
	CustomReportResultCharacteristic,
	| 'id'
	| 'working_population_men'
	| 'working_population_women'
	| 'total_working_population'
	| 'working_population_others'
	| 'particularities_description'
	| 'worker_verbalization_description'
>;
export type CharacteristicsResponse = CharacteristicsBasicResponse & {
	working_population_men_percentage: number;
	working_population_women_percentage: number;
	working_population_others_percentage: number;
	worker_self_evaluation: WorkerSelfEvaluationResponse;
};

export type WorkConditionBasicResponse = Pick<
	CustomReportResultWorkCondition,
	'id' | 'place_description' | 'custom_report_result_id' | 'expected_task_description' | 'performed_task_description'
>;

export type WorkConditionResponse = WorkConditionBasicResponse & {
	work_hours: number;
	work_minutes: number;
};

export type ResultInformations = PersonalisedReportResultInformationsResponse & {
	id: string;
	result: number;
	duration: string;
	sector_name: string;
	cellule_name: string;
	company_name: string;
	evaluator_name: string;
	workstation_name: string;
	organization_name: string;
	reba: RebaResponse | null;
	file_original_name: string;
	niosh: NioshResponse | null;
	kim_pp: KimPpResponse | null;
	kim_mho: KimMhoResponse | null;
	angle_time: AngleTimeResponse | null;
	strain_index: StrainIndexResponse | null;
	action_plans?: ActionPlanWithTaskResponse[];
	liberty_mutual: LibertyMutualResponse | null;
};

type Tools = {
	[ToolsNames.rula]: null;
	[ToolsNames.reba]: RebaResponse | null;
	[ToolsNames.niosh]: NioshResponse | null;
	[ToolsNames.kim_pp]: KimPpResponse | null;
	[ToolsNames.kim_mho]: KimMhoResponse | null;
	[ToolsNames.angle_time]: AngleTimeResponse | null;
	[ToolsNames.strain_index]: StrainIndexResponse | null;
	[ToolsNames.liberty_mutual]: LibertyMutualResponse | null;
};

export type PersonalisedReportResultResponse = PersonalisedReportResultBasicResponse & {
	tools?: Tools;
	basic_information: ResultInformations;
	work_conditions?: WorkConditionResponse;
	characteristics?: CharacteristicsResponse;
	steps: PersonalisedReportResultStepResponse[];
};

async function getResults<T, Body>(payload: Body): Promise<T> {
	const url = '/custom-report/result/find-one';
	const { data } = await Api.get<T>(url, {
		params: payload
	});

	return data;
}

export function useGetResult({
	file_id,
	report_id,
	company_id,
	organization_id,
	custom_report_result_id
}: GetResultDTO) {
	const parameters = { organization_id, company_id, custom_report_result_id, report_id, file_id };
	const result = useQuery<PersonalisedReportResultResponse, AxiosError<Response>>(
		['personalised_report_result', parameters],
		() => getResults<PersonalisedReportResultResponse, GetResultDTO>(parameters),
		{
			retry: 0,
			enabled: !!organization_id && !!company_id && (!!custom_report_result_id || !!file_id)
		}
	);
	return {
		...result,
		data: result.data ?? ({} as PersonalisedReportResultResponse)
	};
}
