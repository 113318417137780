import pt_BR from 'antd/es/locale/pt_BR';
import en_US from 'antd/es/locale/en_US';
import de_DE from 'antd/es/locale/de_DE';
import zh_CN from 'antd/es/locale/zh_CN';
import type { LocaleKey } from './types';
import type { Locale } from 'antd/lib/locale-provider';

export enum LocaleKeyEnum {
	PT_BR = 'pt-BR',
	EN_US = 'en-US',
	DE_DE = 'de-DE',
	ZH_CN = 'zh-CN'
}

export const browserLanguage: string = navigator.language;

const translations: Record<LocaleKey, Locale> = {
	[LocaleKeyEnum.PT_BR]: pt_BR,
	[LocaleKeyEnum.EN_US]: en_US,
	[LocaleKeyEnum.DE_DE]: de_DE,
	[LocaleKeyEnum.ZH_CN]: zh_CN
};

export const localeProvider: Locale = translations[browserLanguage];
