import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';
import { I18n } from '@aws-amplify/core';
import { FileService } from '@/infra/services/file';

interface GetCustomReportResultFileParams {
	custom_report_id: string;
	organization_id: string;
	company_id: string;
	file_id: string;
}

const Service = FileService.getInstance();

export const useGetCustomReportResultFile = () => {
	return useMutation({
		mutationFn: (params: GetCustomReportResultFileParams) => Service.getCustomReportResultFile(params),
		onError: (err: any) => {
			const title = 'Oops! Something happened.';
			const message = err.response?.data?.message ?? 'Failed to get file custom report result';
			notification.error({ message: I18n.get(title), description: I18n.get(message) });
		}
	});
};
