import React from 'react';
import { Menu } from 'antd';
import type { MenuProps } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router';
import * as Icon from '@ant-design/icons';
import { useLocation } from 'react-router-dom';

import Can from '@/components/Can';
import { MenuItem, ItemParams } from './types';
import { PersonalisedReportSVG } from '@/components/Icons/PersonalisedReportSVG';
import { RotationIcon } from '@/assets/icons/Rotation';

export function MenuOptions() {
	const history = useHistory();
	const location = useLocation();
	const selectKey = location.pathname;

	function getItem({ label, key = null, icon, children, type, disabled = false }: ItemParams): MenuItem {
		return {
			key,
			icon,
			children,
			label,
			type,
			disabled
		} as MenuItem;
	}

	const items: MenuItem[] = [
		getItem({ label: I18n.get('Dashboard'), key: '/', icon: <Icon.BarChartOutlined /> }),
		getItem({ label: I18n.get('Upload'), key: '/upload', icon: <Icon.CloudUploadOutlined /> }),
		getItem({ label: I18n.get('Files'), key: '/files', icon: <Icon.FolderOpenOutlined /> }),
		getItem({ label: I18n.get('Reports'), key: '/reporting', icon: <Icon.FilePdfOutlined /> }),
		getItem({
			label: I18n.get('Multitasks and rotation'),
			key: '/rotation',
			icon: <RotationIcon width={15} height={15} />
		}),
		getItem({
			key: null,
			label: I18n.get('EWA'),
			icon: <Icon.FileProtectOutlined />,
			children: [
				getItem({
					label: I18n.get('New document'),
					key: '/super-pea/new-document',
					icon: <Icon.FileTextOutlined />
				}),
				getItem({
					label: I18n.get('File history'),
					key: '/super-pea/file-history',
					icon: <Icon.ProfileOutlined />
				})
			]
		}),
		getItem({ label: I18n.get('Action plans'), key: '/action-plans', icon: <Icon.ReconciliationOutlined /> }),
		getItem({
			key: null,
			label: I18n.get('Settings'),
			icon: <Icon.InfoCircleOutlined />,
			children: [
				getItem({ label: I18n.get('My account'), key: '/settings/account', icon: <Icon.InfoCircleOutlined /> }),
				getItem({
					label: I18n.get('Preferences'),
					key: '/settings/preferences',
					icon: <Icon.ControlOutlined />,
					disabled: !Can('settings', 'preferences')
				}),
				getItem({
					label: I18n.get('Manage users'),
					key: '/users',
					icon: <Icon.TeamOutlined />,
					disabled: !Can('settings', 'management')
				}),
				getItem({
					label: I18n.get('Manage companies'),
					key: '/settings/companies',
					icon: <Icon.PartitionOutlined />,
					disabled: !Can('settings', 'management')
				}),
				getItem({
					label: I18n.get('Personalised reports'),
					key: '/settings/personalised-reports',
					icon: (
						<PersonalisedReportSVG
							width={15}
							height={15}
							margin="0 0.5rem 0 0"
							disabled={!Can('settings', 'personalise_report')}
						/>
					),
					disabled: !Can('settings', 'personalise_report')
				})
			]
		})
	];

	const handleOnClick: MenuProps['onClick'] = ({ key }): void => {
		history.push(key);
	};

	return (
		<Menu
			theme="light"
			mode="inline"
			items={items}
			onClick={handleOnClick}
			selectedKeys={[selectKey]}
			defaultSelectedKeys={[selectKey]}
		/>
	);
}
