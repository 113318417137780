import { BaseService } from './base-service';
import { ResponsibleDTO, GetResponsibleOptionsParamsDTO } from '@/core/dto/responsible';

class UserService extends BaseService {
	constructor(public readonly basePath: string = '/user') {
		super();
	}

	async listOptions(params: GetResponsibleOptionsParamsDTO): Promise<ResponsibleDTO[]> {
		const url = this.basePath + `/user-list/${params.organization_id}/${params.company_id}`;
		const { data } = await this.getInstance().get(url);
		return data;
	}
}

const Service = Object.freeze(new UserService());
export { Service };
