import React, { SVGProps } from 'react';

export function RotationIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g clip-path="url(#clip0_2416_3570)">
				<path
					d="M9.90329 35.1176C8.83357 30.3495 9.67303 25.3571 12.2457 21.1874C13.5405 19.0836 15.2028 17.2719 17.1917 15.8117C19.2518 14.3012 21.5696 13.225 24.0816 12.6205C26.5989 12.0146 29.1564 11.9119 31.6845 12.3236C34.1298 12.7165 36.4487 13.5701 38.577 14.85C39.2318 15.2438 39.8562 15.6725 40.4489 16.1309L38.3171 18.8C38.2662 18.8631 38.2343 18.9391 38.2252 19.0193C38.216 19.0994 38.2299 19.1805 38.2653 19.2532C38.3007 19.3259 38.3561 19.3873 38.4252 19.4302C38.4943 19.4732 38.5743 19.496 38.6559 19.496L47.4045 19.5023C47.6832 19.5014 47.8925 19.2415 47.8272 18.976L45.7984 10.5588C45.7125 10.21 45.2583 10.1098 45.0383 10.3944L43.0006 12.9471C37.5857 8.7196 30.3311 6.91999 23.1111 8.65772C10.7617 11.63 3.13086 23.8388 5.90107 36.0588C5.91335 36.1147 5.93682 36.1676 5.97011 36.2144C6.00339 36.2612 6.04582 36.301 6.09488 36.3313C6.14394 36.3616 6.19865 36.3819 6.25578 36.391C6.3129 36.4001 6.3713 36.3979 6.42752 36.3843L9.58727 35.6238C9.81372 35.5693 9.95316 35.3428 9.90329 35.1176Z"
					fill="#262626"
				/>
				<path
					d="M18.7201 47.2223C18.0652 46.8285 17.4408 46.3998 16.8481 45.9414L18.9746 43.2735C19.0255 43.2105 19.0574 43.1345 19.0666 43.0543C19.0757 42.9741 19.0618 42.893 19.0264 42.8203C18.9911 42.7476 18.9356 42.6863 18.8665 42.6433C18.7974 42.6004 18.7175 42.5776 18.6358 42.5776L9.88724 42.5712C9.60854 42.5721 9.39922 42.8321 9.46461 43.0975L11.4959 51.5252C11.5818 51.8739 12.036 51.9742 12.256 51.6896L14.2937 49.1369C17.1728 51.3744 20.5682 52.9313 24.2006 53.6395L24.2006 49.4669C22.2695 49.006 20.4201 48.2495 18.7201 47.2223Z"
					fill="#262626"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M46.7572 28.4556H37.6466V32.0342H32.9235V27.3001C32.9235 25.4327 34.516 23.9032 36.4665 23.9032H48.0181C49.9658 23.9032 51.5611 25.43 51.5611 27.3001V32.0342H46.8129L46.7572 28.4556Z"
					fill="#262626"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M47.309 44.8475C47.309 47.5591 45.0045 49.7699 42.1762 49.7699C39.3479 49.7699 37.0421 47.5591 37.0421 44.8475V43.5825H25.7901V55.3962C25.7901 55.7369 26.0646 56 26.392 56H58.0371C58.3673 56 58.6668 55.7369 58.6668 55.3962V43.5825H47.3619L47.309 44.8475Z"
					fill="#262626"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M39.8386 43.5825V44.8448C39.8386 46.0804 40.8794 47.109 42.2002 47.109C43.489 47.109 44.5618 46.0831 44.5618 44.8448V43.5825H39.8386Z"
					fill="#262626"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M34.9839 34.6666H26.6146C26.1478 34.6666 25.7647 35.034 25.7647 35.4814V40.9275H58.6149V35.4814C58.6149 35.034 58.2318 34.6666 57.765 34.6666H49.3957C49.3121 34.6933 49.2313 34.6933 49.1199 34.6933C49.0363 34.6933 48.929 34.6933 48.8454 34.6666H35.5063C35.4227 34.6933 35.3141 34.6933 35.2305 34.6933C35.1789 34.6933 35.0675 34.6933 34.9839 34.6666Z"
					fill="#262626"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2416_3570">
					<rect width="64" height="64" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
