import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { SelectWithCreation } from '@/components/ui/Inputs';
import { useGetOrganizations } from '@/hooks/useGetOrganizations';
import { hierarchyHandler, HierarchiesField } from '@/utils/HierarchyHandler';
import { PlaceholderText } from './styles';

interface OrganizationsProps {
	disabled?: boolean;
}

const { useFormInstance } = Form;

export function Organizations({ disabled }: Readonly<OrganizationsProps>) {
	const form = useFormInstance();
	const fieldName = HierarchiesField.ORGANIZATION;

	const {
		isFetching: isGettingOrganizations,
		data: organizations,
		isError: errorGettingOrganizations
	} = useGetOrganizations();

	function handleOnChange(id: string): void {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(fieldName);
		if (fieldsToReset && id) {
			form.resetFields(fieldsToReset);
			form.setFieldValue(fieldName, id);
		}
	}

	function handleOnClearOrganization(): void {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(fieldName);
		if (fieldsToReset) {
			form.resetFields(fieldsToReset);
		}
	}

	const isDisabled = !organizations || errorGettingOrganizations || disabled;

	return (
		<Form.Item name={fieldName} label={I18n.get('Company')} labelCol={{ span: 24 }}>
			<SelectWithCreation
				showSearch
				allowClear
				selectOptions={organizations}
				loading={isGettingOrganizations}
				onClear={handleOnClearOrganization}
				onChange={handleOnChange}
				placeholder={<PlaceholderText>Company</PlaceholderText>}
				disabled={isDisabled}
			/>
		</Form.Item>
	);
}
