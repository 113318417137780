import React, { useContext } from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Title } from '@/components/Typography';
import { ResultsContext } from '../../context';
import { Topic, Value } from './styles';

export function Summary() {
	const {
		file_name,
		role_name,
		sector_name,
		analyst_name,
		company_name,
		cellule_name,
		activity_name,
		collection_date,
		workstation_name,
		organization_name
	} = useContext(ResultsContext);

	return (
		<Col lg={24} xl={12} offset={1}>
			<Row>
				<Title level={3}>Company</Title>
			</Row>
			<Row align="middle">
				<Col span={24}>
					<Row align="top">
						<Col span={12}>
							<Row>
								<Topic>{I18n.get('Company')}:</Topic>
								<Value>{organization_name}</Value>
							</Row>
							<Row>
								<Topic>{I18n.get('Industrial site')}:</Topic>
								<Value>{company_name}</Value>
							</Row>
							<Row>
								<Topic>{I18n.get('Sector')}:</Topic>
								<Value>{sector_name}</Value>
							</Row>
							<Row>
								<Topic>{I18n.get('Workstation')}:</Topic>
								<Value>{workstation_name}</Value>
							</Row>
							{cellule_name && (
								<Row>
									<Topic>{I18n.get('Cellule')}:</Topic>
									<Value>{cellule_name}</Value>
								</Row>
							)}
							<Row>
								<Topic>{I18n.get('Analyst')}:</Topic>
								<Value>{analyst_name}</Value>
							</Row>
						</Col>
						<Col span={11}>
							<Row>
								<Topic>{I18n.get('File')}:</Topic>
								<Value>{file_name}</Value>
							</Row>
							<Row>
								<Topic>{I18n.get('Date')}:</Topic>
								<Value>{collection_date && moment(collection_date).format('DD/MM/YYYY')}</Value>
							</Row>
							<Row>
								<Topic>{I18n.get('Role')}:</Topic>
								<Value>{role_name}</Value>
							</Row>
							<Row>
								<Topic>{I18n.get('Job')}:</Topic>
								<Value>{activity_name}</Value>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	);
}
