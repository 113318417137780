import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useCompanies } from '@/hooks/v1';
import { PlaceholderText } from './styles';
import { SelectWithCreation } from '@/components/ui/Inputs';
import { HierarchiesField, hierarchyHandler } from '@/utils/HierarchyHandler';

const { useFormInstance, useWatch } = Form;

export function Companies({ disabled }: { disabled?: boolean }) {
	const form = useFormInstance();

	const companyId = useWatch(HierarchiesField.COMPANY, form);
	const organizationId = useWatch(HierarchiesField.ORGANIZATION, form);

	const fieldName = HierarchiesField.COMPANY;

	const {
		data: companies,
		isFetching: isGettingCompanies,
		isError: errorGettingCompanies
	} = useCompanies(organizationId);

	function handleSelectCompany(id: string) {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(fieldName);
		if (fieldsToReset && id) {
			form.resetFields(fieldsToReset);
			form.setFieldValue(fieldName, id);
		}
	}

	function handleClearCompany() {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(fieldName);
		if (fieldsToReset) {
			form.resetFields(fieldsToReset);
		}
	}

	return (
		<Form.Item name={fieldName} label={I18n.get('Industrial site')} labelCol={{ span: 24 }}>
			<SelectWithCreation
				showSearch
				allowClear
				value={companyId}
				selectOptions={companies}
				loading={isGettingCompanies}
				onClear={handleClearCompany}
				onChange={handleSelectCompany}
				placeholder={<PlaceholderText>Industrial site</PlaceholderText>}
				disabled={!organizationId || !companies || errorGettingCompanies || disabled}
			/>
		</Form.Item>
	);
}
