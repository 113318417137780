import React from 'react';
import { Row, Col, Form, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ResponsibleDropdown, TaskName } from '../../components/Fields';
import { DueDateDropdown } from '../../components/Fields/DueDateDropdown';
import { useCreateActionPlanTask } from '@/hooks/v2/useCreateActionPlanTask';
import { GetResponsibleOptionsResponse } from '@/hooks/v2/useGetResponsibleOptions';
import { GetActionPlanFromReportResponse } from '@/hooks/v2/useGetActionPlanFromReport';

const { useWatch, useFormInstance } = Form;

interface CreationProps {
	users?: GetResponsibleOptionsResponse[];
	actionPlan: GetActionPlanFromReportResponse;
}

export function Creation({ actionPlan, users }: Readonly<CreationProps>) {
	const fieldName = ['task'];
	const form = useFormInstance();
	const { mutateAsync: createTask } = useCreateActionPlanTask();

	const taskName = useWatch([...fieldName, 'task_name']);

	async function handleAdd(): Promise<void> {
		const values = form.getFieldValue(fieldName);

		const payload = {
			is_completed: false,
			title: values.task_name,
			due_date: values.due_date,
			action_plan_id: actionPlan.id,
			company_id: actionPlan.company.id,
			organization_id: actionPlan.organization.id,
			responsible_user_id: values.responsible_id
		};

		await createTask(payload);

		cleanAllFields();
	}

	function cleanAllFields(): void {
		form.setFieldValue([...fieldName, 'due_date'], undefined);
		form.setFieldValue([...fieldName, 'task_name'], undefined);
		form.setFieldValue([...fieldName, 'responsible_id'], undefined);
	}

	return (
		<Row justify="space-between" align="middle">
			<Col span={19}>
				<TaskName formName={fieldName} />
			</Col>
			<Col>
				<DueDateDropdown formName={fieldName} limitDate={actionPlan.due_date} />
			</Col>
			<Col>
				<ResponsibleDropdown formName={fieldName} options={users} />
			</Col>
			<Col>
				<Button
					type="primary"
					shape="circle"
					onClick={handleAdd}
					icon={<PlusOutlined />}
					disabled={!taskName}
				/>
			</Col>
		</Row>
	);
}
