import React, { ReactElement, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import * as Icon from '@ant-design/icons';
import { Button, Col, Dropdown, Row, Space, Tooltip } from 'antd';

import { DownloadVideoIcon } from '../../../../views/ListReport/components';
import { OptionDropdown } from '../OptionDropdown';
import { FilesListResponse } from '@/hooks';
import { CustomButton } from '../styles';
import { FileStatus } from '@/types';
import Can from '@/components/Can';

export type FileType = {
	id: string;
	status?: FileStatus;
	original_name: string;
};

interface ActionsForFileProps {
	isDropDown?: boolean;
	file: FilesListResponse;
	onEdit: (id: string) => void;
	icon?: ReactElement<any, any>;
	onDelete: (id: string) => void;
	onDownload: (file: FileType) => void;
	onRedirectPreliminary: (file: FilesListResponse) => void;
	onShowReportOptionsReports: (file: FilesListResponse) => void;
}

export function ActionsForFile({
	file,
	icon,
	onEdit,
	onDelete,
	isDropDown,
	onDownload,
	// onRedirectPreliminary,
	onShowReportOptionsReports
}: Readonly<ActionsForFileProps>) {
	const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);

	function handleOpenChange(open: boolean): void {
		setOpenDropdownId(open ? file.id : null);
	}

	function handleChooseOption(functionToCall: () => void): void {
		functionToCall();
		setOpenDropdownId(null);
	}

	function handleShowReportsModal(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
		event.stopPropagation();
		handleChooseOption(() => onShowReportOptionsReports(file));
	}

	function handleDownloadFile(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
		event.stopPropagation();
		handleChooseOption(() => onDownload(file));
	}

	function handleDeleteFile(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
		event.stopPropagation();
		handleChooseOption(() => onDelete(file.id));
	}

	function handleEditFile(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
		event.stopPropagation();
		handleChooseOption(() => onEdit(file.id));
	}

	const processedFile = file.status === FileStatus.PROCESSED;
	const extractedData = file.status === FileStatus.EXTRACTED_DATA;
	const dataAvailable = processedFile || extractedData;

	const permissionRole = Can('delete', 'upload');

	const items = [
		{
			key: -1,
			disabled: !processedFile,
			icon: <Icon.LineChartOutlined style={{ color: '#2F54EB' }} />,
			label: (
				<OptionDropdown disabled={!processedFile} onClick={handleShowReportsModal}>
					{I18n.get('Create report')}
				</OptionDropdown>
			)
		},
		{
			key: 0,
			disabled: !dataAvailable,
			icon: <Icon.EditOutlined style={{ color: '#2F54EB' }} />,
			label: (
				<OptionDropdown disabled={!processedFile} onClick={handleEditFile}>
					{I18n.get('Edit')}
				</OptionDropdown>
			)
		},
		// {
		// 	key: 1,
		// 	disabled: !dataAvailable,
		// 	icon: <Icon.FileTextOutlined style={{ color: '#2F54EB' }} />,
		// 	label: (
		// 		<OptionDropdown disabled={!dataAvailable} onClick={handlePreliminaryAnalysis}>
		// 			{I18n.get('EWA')}
		// 		</OptionDropdown>
		// 	)
		// },
		{
			key: 2,
			disabled: !processedFile,
			icon: <DownloadVideoIcon style={{ color: '#2F54EB' }} height={15} width={18} />,
			label: (
				<OptionDropdown disabled={!processedFile} onClick={handleDownloadFile}>
					Download
				</OptionDropdown>
			)
		},
		{
			key: 3,
			danger: true,
			disabled: !permissionRole,
			icon: <Icon.DeleteOutlined />,
			label: (
				<CustomButton>
					<OptionDropdown disabled={!permissionRole} onClick={handleDeleteFile}>
						Delete
					</OptionDropdown>
				</CustomButton>
			)
		}
	];

	return (
		<>
			{isDropDown ? (
				<Dropdown
					menu={{ items }}
					placement="bottomLeft"
					trigger={['click']}
					onOpenChange={handleOpenChange}
					open={openDropdownId === file.id}
				>
					<Button type="link" onClick={(e) => e.stopPropagation()}>
						<Space>{icon ?? <Icon.MenuOutlined style={{ color: 'black' }} />}</Space>
					</Button>
				</Dropdown>
			) : (
				<Row justify="center">
					<Col xs={24}>
						<Row justify="space-around" align="middle">
							<Col xs={4}>
								<Tooltip title={I18n.get('Create report')}>
									<Button
										type="link"
										disabled={!processedFile}
										onClick={handleShowReportsModal}
										icon={<Icon.LineChartOutlined style={{ fontSize: '25px' }} />}
									/>
								</Tooltip>
							</Col>
							<Col xs={4}>
								<Tooltip title={I18n.get('Edit file hierarchy')}>
									<Button
										type="link"
										onClick={handleEditFile}
										disabled={!processedFile}
										icon={<Icon.EditOutlined style={{ fontSize: '25px' }} />}
									/>
								</Tooltip>
							</Col>
							{/* <Col xs={4}>
								<Tooltip title={I18n.get('EWA')}>
									<Button
										type="link"
										disabled={!dataAvailable}
										onClick={handlePreliminaryAnalysis}
										icon={<Icon.FileTextOutlined style={{ fontSize: '25px' }} />}
									/>
								</Tooltip>
							</Col> */}
							<Col xs={4}>
								<Tooltip title={I18n.get('Download')}>
									<Button
										size="large"
										type="link"
										disabled={!processedFile}
										onClick={handleDownloadFile}
										icon={<DownloadVideoIcon height={25} width={25} />}
									/>
								</Tooltip>
							</Col>
							<Col xs={4}>
								<Tooltip title={I18n.get('Delete')}>
									<Button
										danger
										type="link"
										size="large"
										onClick={handleDeleteFile}
										disabled={!permissionRole}
										icon={<Icon.DeleteOutlined style={{ fontSize: '25px' }} />}
									/>
								</Tooltip>
							</Col>
						</Row>
					</Col>
				</Row>
			)}
		</>
	);
}
