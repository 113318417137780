import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Tasks } from './Tasks';
import { Footer } from './Footer';
import { Title } from '@/components/Typography';
import { RiskEvaluation } from '../components/Risk';
import { DueDate } from '../components/Fields/DueDate';
import { Priority } from '../components/Fields/Priority';
import { Description } from '../components/Fields/Description';
import { Responsible } from '../components/Fields/Responsible';
import { ActionPlanName } from '../components/Fields/ActionPlanName';
import { InvestmentRange } from '../components/Fields/InvestmentRange';
import { useGetResponsableOptions } from '@/hooks/v2/useGetResponsibleOptions';
import { GetActionPlanFromReportResponse } from '@/hooks/v2/useGetActionPlanFromReport';

const { useForm } = Form;

interface UpdateActionPlanProps {
	onClose(): void;
	score: number;
	result: number;
	actionPlan: GetActionPlanFromReportResponse;
	activityId?: string;
}

export function UpdateActionPlan({ onClose, result, score, actionPlan, activityId }: Readonly<UpdateActionPlanProps>) {
	const [form] = useForm();
	const fieldName = useMemo(() => ['update_action_plan', actionPlan.id], [actionPlan.id]);

	const { data: users } = useGetResponsableOptions({
		organization_id: actionPlan.organization.id,
		company_id: actionPlan.company.id
	});

	useEffect(() => {
		form.setFieldValue([...fieldName, 'priority'], actionPlan.priority);
		form.setFieldValue([...fieldName, 'action_plan_name'], actionPlan.title);
		form.setFieldValue([...fieldName, 'description'], actionPlan.description);
		form.setFieldValue([...fieldName, 'due_date'], moment(actionPlan.due_date));
		form.setFieldValue([...fieldName, 'responsible_id'], actionPlan.responsible_user_id);
		form.setFieldValue([...fieldName, 'investment_range'], actionPlan.investment_range);
	}, [actionPlan, form, fieldName]);

	return (
		<Form form={form}>
			<Row gutter={[30, 30]}>
				<Col span={24}>
					<Title level={3} style={{ margin: 0 }}>
						{I18n.get('Update action plan')}
					</Title>
				</Col>
				<Col span={18}>
					<ActionPlanName formName={fieldName} isRequired />
				</Col>
				<Col span={6}>
					<RiskEvaluation result={result} score={score} />
				</Col>
				<Col span={12}>
					<Responsible formName={fieldName} options={users} isRequired />
				</Col>
				<Col span={12}>
					<DueDate formName={fieldName} isRequired />
				</Col>
				<Col span={12}>
					<Priority formName={fieldName} />
				</Col>
				<Col span={12}>
					<InvestmentRange formName={fieldName} />
				</Col>
				<Col span={24}>
					<Description formName={fieldName} />
				</Col>
				<Col span={24}>
					<Tasks users={users} actionPlan={actionPlan} />
				</Col>
				<Col span={24}>
					<Footer formName={fieldName} onClose={onClose} actionPlan={actionPlan} activityId={activityId} />
				</Col>
			</Row>
		</Form>
	);
}
