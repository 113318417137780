import React from 'react';
import * as S from './styles';
import { UpdateActionPlan } from './UpdateActionPlan';
import { CreateActionPlan } from './CreateActionPlan';
import { GetActionPlanFromReportResponse } from '@/hooks/v2/useGetActionPlanFromReport';

const MODAL_WIDTH = 700;

interface ActionPlanModalProps {
	onClose(): void;
	score?: number;
	result?: number;
	isOpen: boolean;
	fileId?: string;
	activityId?: string;
	companyId: string;
	organizationId: string;
	customReportResultId?: string;
	customReportStepKeyId?: string;
	customReportSubStepKeyId?: string;
	actionPlan?: GetActionPlanFromReportResponse;
	relatedReports: { report: string; id: string }[];
}

export function ActionPlanModal({
	isOpen,
	onClose,
	actionPlan,
	companyId,
	organizationId,
	relatedReports,
	customReportStepKeyId,
	customReportSubStepKeyId,
	fileId = '',
	activityId = '',
	score = 0,
	result = 0,
	customReportResultId = ''
}: Readonly<ActionPlanModalProps>) {
	const hierarchy = {
		file_id: fileId,
		activity_id: activityId,
		company_id: companyId,
		organization_id: organizationId,
		custom_report_result_id: customReportResultId,
		custom_report_step_key: customReportStepKeyId,
		custom_report_sub_step_key: customReportSubStepKeyId
	};

	if (actionPlan) {
		return (
			<S.ModalWrapper width={MODAL_WIDTH} open={isOpen} onCancel={onClose} footer={null}>
				<UpdateActionPlan actionPlan={actionPlan} activityId={activityId} result={result} score={score} onClose={onClose} />
			</S.ModalWrapper>
		);
	}

	return (
		<S.ModalWrapper width={MODAL_WIDTH} open={isOpen} onCancel={onClose} footer={null}>
			<CreateActionPlan
				hierarchy={hierarchy}
				result={result}
				score={score}
				onClose={onClose}
				relatedReports={relatedReports}
			/>
		</S.ModalWrapper>
	);
}
