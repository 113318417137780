import { Mapper } from '../base-mapper';
import { CreateActionPlanParams, CreateActionPlanRequestDTO } from '../../dto';

export class ActionPlanCreateMapper implements Mapper<CreateActionPlanRequestDTO> {
	toDomain(): any {}

	static toDTO(payload: CreateActionPlanParams): CreateActionPlanRequestDTO {
		return {
			organization_id: payload.organization_id,
			company_id: payload.company_id,
			activity_id: payload.activity_id,
			cellule_id: payload.cellule_id,
			title: payload.action_plan_name,
			due_date: payload.due_date,
			responsible_user_id: payload.responsible_id,
			investment_range: payload.investment_range,
			priority: payload.priority,
			status: payload.status,
			origin: payload?.origin ? JSON.parse(payload?.origin) : undefined
		};
	}
}
