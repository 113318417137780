import React from 'react';
import { Row, Col, Skeleton } from 'antd';
import { TaskItem } from './TaskItem';
import { Creation } from './Creation';
import { ProgressBar } from './ProgressBar';
import { Text } from '@/components/Typography';
import { useGetActionPlanTasks } from '@/hooks/v2/useGetActionPlanTasks';
import { GetResponsibleOptionsResponse } from '@/hooks/v2/useGetResponsibleOptions';
import { GetActionPlanFromReportResponse } from '@/hooks/v2/useGetActionPlanFromReport';
import { TasksContainer } from './styles';

interface TasksProps {
	users?: GetResponsibleOptionsResponse[];
	actionPlan: GetActionPlanFromReportResponse;
}

export function Tasks({ actionPlan, users }: Readonly<TasksProps>) {
	const {
		data: tasks,
		isError: hasErrorTask,
		isLoading: isGettingTasks
	} = useGetActionPlanTasks({
		action_plan_id: actionPlan.id,
		company_id: actionPlan.company.id,
		organization_id: actionPlan.organization.id
	});

	if (isGettingTasks) {
		return (
			<Row gutter={[0, 30]}>
				<Col span={24}>
					<Row gutter={[0, 12]}>
						<Col span={24}>
							<Text style={{ fontSize: '1.1rem' }}>Tasks</Text>
						</Col>
						<Col span={24}>
							<ProgressBar completed={0} total={0} />
						</Col>
					</Row>
				</Col>
				<Col span={24}>
					<Skeleton active />
				</Col>
			</Row>
		);
	}

	if (hasErrorTask) {
		return (
			<Row gutter={[0, 30]}>
				<Col span={24}>
					<Row gutter={[0, 12]}>
						<Col span={24}>
							<Text style={{ fontSize: '1.1rem' }}>Tasks</Text>
						</Col>
						<Col span={24}>
							<ProgressBar completed={0} total={0} />
						</Col>
					</Row>
				</Col>
				<Col span={24}>
					<span>error</span>
				</Col>
			</Row>
		);
	}

	return (
		<Row gutter={[0, 30]}>
			<Col span={24}>
				<Row gutter={[0, 12]}>
					<Col span={24}>
						<Text style={{ fontSize: '1.1rem' }}>Tasks</Text>
					</Col>
					<Col span={24}>
						<ProgressBar completed={tasks?.total_completed} total={tasks?.data.length} />
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<Creation actionPlan={actionPlan} users={users} />
			</Col>
			<Col span={24}>
				<TasksContainer gutter={[0, 20]}>
					{tasks?.data?.map((task) => {
						return (
							<Col key={task.id} span={24}>
								<TaskItem actionPlan={actionPlan} data={task} users={users} />
							</Col>
						);
					})}
				</TasksContainer>
			</Col>
		</Row>
	);
}
