import { RangeRiskScoreEnum } from './enum';

export function getRangeRiskScore(score: number): number {
	if (score >= 20 && score <= 69) {
		return RangeRiskScoreEnum.MODERATE;
	}

	if (score >= 70 && score <= 199) {
		return RangeRiskScoreEnum.HIGH;
	}

	if (score >= 200 && score <= 400) {
		return RangeRiskScoreEnum.VERY_HIGH;
	}

	if (score >= 401 && score <= 10000) {
		return RangeRiskScoreEnum.EXTREME;
	}

	return RangeRiskScoreEnum.LOW;
}
