import React from 'react';
import { Col, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import * as S from './styles';

export const NotAnalyzedDescription: React.FC = () => {
	return (
		<Row align={'middle'} style={{ marginBottom: '1rem' }}>
			<Col span={5}>
				<S.Description>{I18n.get('Not applicable')}</S.Description>
			</Col>
		</Row>
	);
};
