const dictionary: Record<string, string> = {
	'Customer information not found': 'Benutzerinformation nicht gefunden',
	'Account subscription expired, renew to continue.':
		'Das Abonnement des Kontos ist abgelaufen. Verlängern Sie es, um fortzufahren.',
	'Account subscription cancelled, renew to continue.':
		'Das Abonnement des Kontos wurde storniert. Verlängern Sie es, um fortzufahren.',
	Warning: 'Warnung',
	Yes: 'Ja',
	No: 'Nein',
	Companies: 'Unternehmen',
	'Corrupted file': 'Beschädigte Datei',
	'Extracted data': 'Extrahierte Daten',
	'Not processing': 'Nicht in Bearbeitung',
	Processing: 'In Bearbeitung',
	Processed: 'Verarbeitet',
	'In queue': 'In Warteschlange',
	'Under maintenance': 'Unter Wartung',
	'Send video': 'Video senden',
	Error: 'Fehler',
	'Add files or drag': 'Dateien hinzufügen oder ziehen',
	'Unsupported file': 'Nicht unterstützte Datei',
	'Drop files here': 'Dateien hier ablegen',
	'Unsupported video format': 'Nicht unterstütztes Videoformat',
	and: 'und',
	'is larger than': 'ist größer als',
	'Max size': 'Maximale Größe',
	Blur: 'Verwischen',
	'Are you sure you want to exit? You have uploads in process.':
		'Sind Sie sicher, dass Sie beenden möchten? Sie haben noch Uploads im Gange.',
	' or too many files': 'oder viele Dateien',
	'Files number exceeded': 'Maximale Anzahl von Dateien überschritten',
	'Max files': 'Maximale Dateien',
	'User created successfully': 'Benutzer erfolgreich erstellt',
	'User management': 'Benutzerverwaltung',
	'File duplicated': 'Datei dupliziert',
	'Select the desired company below': 'Wählen Sie das gewünschte Unternehmen aus',
	'Select the desired industrial site below': 'Wählen Sie den gewünschten Industriestandort aus',
	'Create a new company': 'Ein neues Unternehmen erstellen',
	'Create a new industrial site': 'Einen neuen Industriestandort erstellen',
	'Enter a name for the company': 'Geben Sie einen Namen für das Unternehmen ein',
	'Enter a name for the industrial site': 'Geben Sie einen Namen für den Industriestandort ein',
	'Company created successfully': 'Das Unternehmen wurde erfolgreich erstellt',
	'Industrial site created successfully': 'Der Industriestandort wurde erfolgreich erstellt',
	'There are no registered companies': 'Es sind keine registrierten Unternehmen vorhanden',
	'There are no registered industrial sites': 'Es sind keine registrierten Industriestandorte vorhanden',
	'Congratulations! Now create an industrial site that belongs to this company.':
		'Herzlichen Glückwunsch! Erstellen Sie nun einen Industriestandort, der zu diesem Unternehmen gehört.',
	'Create industrial site': 'Industriestandort erstellen',
	'Welcome to Kinebot, create your first company': 'Willkommen bei Kinebot, erstellen Sie Ihr erstes Unternehmen',
	'Create company': 'Unternehmen erstellen',
	'Go back': 'Zurück',
	Back: 'Zurück',
	Create: 'Erstellen',
	'Ergonomic tools': 'Ergonomische Werkzeuge',
	'Failed to get file custom report result': 'Fehler beim Abrufen des benutzerdefinierten Berichts für die Datei',
	'Select the most suitable tool for your analysis': 'Wählen Sie das geeignetste Werkzeug für Ihre Analyse aus',
	'Biomechanical analysis': 'Biomechanische Analyse',
	'Load analysis': 'Lastanalyse',
	'Repeatability analysis': 'Wiederholbarkeitsanalyse',
	Workstation: 'Arbeitsstation',
	Description: 'Beschreibung',
	Size: 'Größe',
	'Upload date': 'Upload-Datum',
	Sector: 'Branche',
	Rating: 'Bewertung',
	Actions: 'Aktionen',
	Action: 'Aktion',
	'Not assigned': 'Nicht zugewiesen',
	'Heads up!': 'Achtung!',
	'Heads up! Want to delete this file?': 'Achtung! Möchten Sie diese Datei löschen?',
	'Force/Load movement data': 'Bewegungsdaten zwingen/laden',
	Repetitions: 'Wiederholungen',
	'Repetitions per minute': 'Wiederholungen pro Minute',
	Legs: 'Beine',
	Diagnostic: 'Diagnose',
	'Analysis results': 'Analyseergebnisse',
	'Not found': 'Nicht gefunden',
	Light: 'Licht',
	'Failed to fetch data': 'Abrufen von Daten fehlgeschlagen',
	"//'Risk level of the job": 'Risikostufe des Arbeitsplatzes',
	'Average exposure of the workplace': 'Durchschnittliche Exposition am Arbeitsplatz',
	'Unable to show results.': 'Ergebnisse können nicht angezeigt werden.',
	'Load less than 2 kg (intermittent)': 'Last von weniger als 2 kg (intermittierend)',
	'Load from 2 kg to 10 kg (static or repetitive)': 'Last von 2 kg bis 10 kg (statisch oder wiederholend)',
	'If more than 10 kg or repeated or rapid build up of force': 'Größere Last 10 kg wiederkehrende Last oder Schläge',
	'Repetitive load or rapid build up of force greater than 10 kg':
		'Größere Last als 10 kg wiederholter Last oder Schlägen',
	'Legs and feet supported and with equal load distribution':
		'Beine und Füße gestützt und mit gleichmäßiger Lastverteilung',
	'Legs without support': 'Beine ohne Unterstützung',
	Reports: 'Berichte',
	'Select the data visualization model': 'Wählen Sie das Datenvisualisierungsmodell aus',
	Angle: 'Winkel',
	'Angle in degrees (°)': 'Winkel in Grad (°)',
	'Time in seconds (s)': 'Zeit in Sekunden (s)',
	Parameters: 'Parameter',
	'Manual lifting': 'Manuelles Heben',
	'NIOSH / ISO 11228-1 - Manual lifting analysis': 'NIOSH/ISO 11228-1 - Analyse des manuellen Hebels',
	'Push and pull': 'Schieben und Ziehen',
	'KIM - Push and pull': 'KIM - Schieben und Ziehen',
	'KIM - PP Report Results': 'KIM - PP-Berichtsergebnisse',
	meters: 'Meter',
	'Temporal distribution': 'Zeitliche Verteilung',
	Distance: 'Entfernung',
	'Work condition': 'Arbeitsbedingung',
	'Work conditions': 'Arbeitsbedingungen',
	'Working conditions': 'Arbeitsbedingungen',
	'Vehicle properties': 'Fahrzeugeigenschaften',
	Vehicle: 'Fahrzeug',
	'Vehicle: ': 'Fahrzeug: ',
	'Distance or duration': 'Entfernung oder Dauer',
	'Select one of the options below': 'Wählen Sie eine der folgenden Optionen aus',
	'Short distances': 'Kurze Strecken',
	'Long distances': 'Lange Strecken',
	Time: 'Zeit',
	Mass: 'Masse',
	'Mass: ': 'Masse: ',
	Precision: 'Präzision',
	'Worker gender': 'Geschlecht des Arbeitnehmers',
	'Pushing / Pulling in pairs': 'Schieben/Ziehen zu zweit',
	'By distance': 'Nach Entfernung',
	'By duration': 'Nach Dauer',
	'Distance per work day (in meters)': 'Entfernung pro Arbeitstag (in Metern)',
	'Duration per work day (in minutes)': 'Dauer pro Arbeitstag (in Minuten)',
	'Select one of the vehicles': 'Wählen Sie eines der Fahrzeuge aus',
	Barrows: 'Karren',
	Carriages: 'Wagen',
	'Overhead conveyors': 'Überkopf-Förderer',
	'Overhead cranes': 'Kräne',
	'Choose an option': 'Wählen Sie eine Option aus',
	'Reference image': 'Referenzbild',
	'Driveway conditions:': 'Fahrwegbedingungen:',
	'Inclination or stairs:': 'Neigung oder Treppen:',
	'Select the mass to be transported (in kg):': 'Wählen Sie die zu transportierende Masse aus (in kg):',
	'Driveway completely level, smooth, solid, dry, without inclinations':
		'Fahrweg komplett eben, glatt, fest, trocken, ohne Neigungen',
	'Driveway mostly smooth and level, with small damaged spots/faults, without inclinations':
		'Fahrweg größtenteils glatt und eben, mit kleinen beschädigten Stellen/Fehlern, ohne Neigungen',
	'Mixture of cobbles, concrete, asphalt, slight inclinations, dropped kerb':
		'Mischung aus Pflastersteinen, Beton, Asphalt, leichte Neigungen, abgesenkte Bordsteine',
	'Mixture of roughly cobbled, hard sand, slight inclinations, small edges/sills':
		'Mischung aus grobem Pflasterstein, hartem Sand, leichte Neigungen, kleine Kanten/Schwellen',
	'Earth or roughly cobbled driveway, potholes, heavy soiling, slight inclinations, landings, sills':
		'Erd- oder grob gepflasterte Einfahrt, Schlaglöcher, starke Verschmutzung, leichte Neigungen, Landungen, Schwellen',
	'Inclinations of 2 ≤ 4° (4 ≤ 8%)': 'Neigungen von 2 ≤ 4° (4 ≤ 8%)',
	'Inclinations of 5 ≤ 10° (9 ≤ 18%)': 'Neigungen von 5 ≤ 10° (9 ≤ 18%)',
	'Stairs, inclinations > 10° (18%)': 'Treppen, Neigungen > 10° (18%)',
	'No significant inclination': 'Keine signifikante Neigung',
	'Select the appropriate options': 'Wählen Sie die entsprechenden Optionen aus',
	'Unfavorable working conditions - more than one can be selected':
		'Ungünstige Arbeitsbedingungen - es können mehrere ausgewählt werden',
	'Unfavorable vehicle properties - more than one can be selected':
		'Ungünstige Fahrzeugeigenschaften - es können mehrere ausgewählt werden',
	'Unfavorable working conditions': 'Ungünstige Arbeitsbedingungen',
	'Unfavorable vehicle properties': 'Ungünstige Fahrzeugeigenschaften',
	'Regularly significantly increased starting forces, because transport devices sink into the ground or get wedged':
		'Regelmäßig deutlich erhöhte Anfangskräfte, da Transportgeräte im Boden versinken oder blockieren',
	'Frequent stops with braking': 'Häufige Stopps mit Bremsungen',
	'Frequent stops without braking': 'Häufige Stopps ohne Bremsen',
	'Many changes of direction or curves, frequent maneuvering':
		'Viele Richtungsänderungen oder Kurven, häufiges Manövrieren',
	'Load must be positioned precisely and stopped, driveway must be adhered to precisely':
		'Last muss präzise positioniert und gestoppt werden, Einfahrt muss genau eingehalten werden',
	'Increased movement speed (approx. 1.0 â‰¤ 1.3 m/s)': 'Erhöhte Bewegungsgeschwindigkeit (ca. 1,0 ≤ 1,3 m/s)',
	nan: 'Keine',
	'No suitable handles or construction parts for applying force':
		'Keine geeigneten Griffe oder Bauteile zum Aufbringen von Kraft',
	'No brake when driving on inclinations > 2° (> 3%)': 'Keine Bremse beim Fahren auf Neigungen > 2° (> 3%)',
	'Unadjusted castors (e.g. too small on soft or uneven floor)':
		'Nicht angepasste Rollen (z. B. zu klein auf weichem oder unebenem Boden)',
	'Defective castors (worn-out, rubbing, stiff, air pressure too low)':
		'Defekte Rollen (abgenutzt, reibend, steif, Luftdruck zu niedrig)',
	Nenhuma: 'Keine',
	'Trunk upright or slightly inclined forward, no twisting / Force application height can be selected freely / No hindrance for the legs':
		'Rumpf aufrecht oder leicht nach vorne geneigt, ohne Verdrehen / Anwendungshöhe der Kraft kann frei gewählt werden / Keine Behinderung der Beine',
	'Body inclined towards the direction of movement or slight twisting when pulling the load on one side / Fixed force application height ranging from 0.9 – 1.2 m / None or only slight hindrance for the legs / Predominantly pulling':
		'Körper geneigt in Richtung der Bewegung oder leichtes Verdrehen beim Ziehen der Last auf einer Seite / Feste Anwendungshöhe der Kraft von 0,9 bis 1,2 m / Keine oder nur leichte Behinderung der Beine / Überwiegendes Ziehen',
	'Awkward body postures caused by fixed force application height < 0.9 or > 1.2 m , lateral force application on one side or significantly obstructed view / Significant hindrance for the legs / Constant twisting and/or lateral inclination of the trunk':
		'Ungünstige Körperhaltungen aufgrund einer festen Anwendungshöhe der Kraft < 0,9 oder > 1,2 m, seitliche Kraftanwendung auf einer Seite oder deutlich eingeschränkte Sicht / Signifikante Behinderung der Beine / Ständiges Verdrehen und/oder seitliche Neigung des Rumpfes',
	'Trunk upright or slightly inclined forward, no twisting':
		'Rumpf aufrecht oder leicht nach vorne geneigt, ohne Verdrehen',
	'Body inclined towards the direction of movement or slight twisting when pulling the load on one side':
		'Körper geneigt in Richtung der Bewegung oder leichtes Verdrehen beim Ziehen der Last auf einer Seite.',
	'Awkward body postures caused by fixed force application height < 0.9 or > 1.2 m , lateral force application on one side or significantly obstructed view':
		'Ungünstige Körperhaltungen aufgrund einer festen Anwendungshöhe der Kraft < 0,9 oder > 1,2 m, seitliche Kraftanwendung auf einer Seite oder deutlich eingeschränkte Sicht',
	'Force application height can be selected freely': 'Anwendungshöhe der Kraft kann frei gewählt werden',
	'Fixed force application height ranging from 0.9 – 1.2 m': 'Feste Anwendungshöhe der Kraft von 0,9 bis 1,2 m',
	'Significant hindrance for the legs': 'Signifikante Behinderung der Beine',
	'No hindrance for the legs': 'Keine Behinderung der Beine',
	'None or only slight hindrance for the legs': 'Keine oder nur leichte Behinderung der Beine',
	'Constant twisting and/or lateral inclination of the trunk':
		'Ständiges Verdrehen und/oder seitliche Neigung des Rumpfes',
	'Predominantly pulling': 'Überwiegendes Ziehen',
	Postures: 'Körperhaltungen',
	'Direction of force': 'Richtung der Kraft',
	Unfavorable: 'Ungünstig',
	'Score and risk assessment': 'Punktzahl und Risikobewertung',
	'Intensity of load': 'Belastungsintensität',
	'Probability of physical overload / Possible health consequences':
		'Wahrscheinlichkeit einer körperlichen Überlastung / Mögliche gesundheitliche Folgen',
	'Physical overload is unlikely / No health risk is to be expected.':
		'Eine körperliche Überlastung ist unwahrscheinlich / Es ist kein Gesundheitsrisiko zu erwarten.',
	'Physical overload is unlikely': 'Eine körperliche Überlastung ist unwahrscheinlich',
	'No health risk is to be expected': 'Es ist kein Gesundheitsrisiko zu erwarten',
	'Physical overload is possible for less resilient persons /  Fatigue, low-grade adaptation problems which can be compensated for during leisure time.':
		'Eine körperliche Überlastung ist bei weniger belastbaren Personen möglich / Müdigkeit, geringfügige Anpassungsprobleme, die in der Freizeit kompensiert werden können.',
	'Physical overload is possible for less resilient persons':
		'Eine körperliche Überlastung ist bei weniger belastbaren Personen möglich',
	'Fatigue, low-grade adaptation problems which can be compensated for during leisure time':
		'Müdigkeit, geringfügige Anpassungsprobleme, die in der Freizeit kompensiert werden können',
	'Physical overload is also possible for normally resilient persons / Disorders (pain), possibly including dysfunctions, reversible in most cases, without morphological manifestation':
		'Eine körperliche Überlastung ist auch bei normalerweise belastbaren Personen möglich / Schmerzen, möglicherweise mit Dysfunktionen, die in den meisten Fällen reversibel sind und keine morphologischen Manifestationen aufweisen.',
	'Physical overload is also possible for normally resilient persons':
		'Eine körperliche Überlastung ist auch bei normalerweise belastbaren Personen möglich',
	'Disorders (pain), possibly including dysfunctions, reversible in most cases, without morphological manifestation':
		'Schmerzen, möglicherweise mit Dysfunktionen, die in den meisten Fällen reversibel sind und keine morphologischen Manifestationen aufweisen',
	'Physical overload is likely / More pronounced disorders and/or dysfunctions, structural damage with pathological significance':
		'Eine körperliche Überlastung ist wahrscheinlich / Ausgeprägtere Störungen und/oder Dysfunktionen, strukturelle Schäden mit pathologischer Bedeutung',
	'Physical overload is likely': 'Eine körperliche Überlastung ist wahrscheinlich',
	'More pronounced disorders and/or dysfunctions, structural damage with pathological significance':
		'Ausgeprägtere Schmerzen und/oder Dysfunktionen, strukturelle Schäden mit pathologischer Bedeutung',
	Measures: 'Maßnahmen',
	' For less resilient persons, workplace redesign and other prevention measures may be helpful.':
		' Für weniger belastbare Personen können eine Umgestaltung des Arbeitsplatzes und andere präventive Maßnahmen hilfreich sein.',
	' Workplace redesign and other prevention measures should be considered.':
		' Eine Umgestaltung des Arbeitsplatzes und andere präventive Maßnahmen sollten in Betracht gezogen werden.',
	' Workplace redesign measures are necessary. Other prevention measures should be considered.':
		' Eine Umgestaltung des Arbeitsplatzes ist erforderlich. Es sollten weitere präventive Maßnahmen in Betracht gezogen werden.',
	Results: 'Ergebnisse',
	Information: 'Informationen',
	'Body parts': 'Körperteile',
	Charts: 'Diagramme',
	'Industrial Site': 'Industriestandort',
	'Collection date': 'Erfassungsdatum',
	'Risk range': 'Risikobereich',
	'Risk Range': 'Risikobereich',
	'Failed to fetch sectors': 'Fehler beim Abrufen von Sektoren',
	'Failed to create a new sector': 'Fehler beim Erstellen eines Sektors',
	'Failed to fetch risk bands': 'Fehler beim Abrufen von Risikobändern',
	'Sector created successfully': 'Bereich erfolgreich erstellt',
	'Select a sector': 'Wählen Sie einen Bereich aus',
	'Insert a workstation': 'Arbeitsstation einfügen',
	'Insert the date of collection': 'Erfassungsdatum einfügen',
	'Select a risk band': 'Wählen Sie ein Risikoband aus',
	'Graphs of selected body parts': 'Diagramme ausgewählter Körperteile',
	Summary: 'Zusammenfassung',
	'Range of Risk': 'Risikobereich',
	'Job information': 'Jobinformationen',
	'File data': 'Dateiinformationen',
	File: 'Datei',
	Duration: 'Dauer',
	'Relevant points': 'Relevante Punkte',
	Frequency: 'Häufigkeit',
	'Most critical move': 'Kritischste Bewegung',
	'Include conclusion': 'Schlussfolgerung einschließen',
	'Include signature': 'Unterschrift einschließen',
	'Insert conclusion': 'Schlussfolgerung einfügen',
	'Risk track created successfully!': 'Risikoverfolgung erfolgreich erstellt!',
	'Create custom risk bands based on your analysis':
		'Erstellen Sie benutzerdefinierte Risikobänder basierend auf Ihrer Analyse',
	'Industrial site status': 'Status des Industriestandorts',
	'Harmful postures': 'Schädliche Körperhaltungen',
	'Inadequate postures': 'Ungeeignete Körperhaltungen',
	'Workstation with good postures': 'Arbeitsstation mit guten Körperhaltungen',
	'Workers in real danger': 'Arbeiter in echter Gefahr',
	'Total of videos': 'Gesamtzahl der Videos',
	'Processed minutes': 'Verarbeitete Minuten',
	'Critical sector': 'Kritischer Sektor',
	'Registered sectors': 'Registrierte Sektoren',
	'Exported reports': 'Exportierte Berichte',
	'Sectors in alert': 'Sektoren in Alarmbereitschaft',
	'Main statistics': 'Hauptstatistiken',
	'No. of videos by risk level': 'Anzahl der Videos nach Risikostufe',
	Month: 'Monat',
	Year: 'Jahr',
	Day: 'Tag',
	'Assessments completed': 'Abgeschlossene Bewertungen',
	'Videos by sector': 'Videos nach Sektor',
	'Percentage of videos by risk': 'Prozentsatz der Videos nach Risiko',
	'Ergonomics Management': 'Ergonomie-Management',
	'Risk Quotation': 'Risikobewertung',
	'Total workstations': 'Gesamtzahl der Arbeitsstationen',
	'Total pending evaluations': 'Ausstehende Bewertungen insgesamt',
	'Total evaluations consolidated': 'Konsolidierte Bewertungen insgesamt',
	Evaluations: 'Bewertungen',
	'Not analyzed': 'Nicht analysiert',
	'Not analysed': 'Nicht analysiert',
	'There are no registered action plans': 'Es sind keine registrierten Aktionspläne vorhanden',
	'Acceptable Risk': 'Akzeptables Risiko',
	'Acceptable risk': 'Akzeptables Risiko',
	'Moderate Risk': 'Mäßiges Risiko',
	'Moderate risk': 'Mäßiges Risiko',
	'High Risk': 'Hohes Risiko',
	'High risk': 'Hohes Risiko',
	'Very High Risk': 'Sehr hohes Risiko',
	'Very high risk': 'Sehr hohes Risiko',
	'Extreme and Imminent Risk': 'Extremes und unmittelbares Risiko',
	'Serious and Imminent Risk': 'Ernsthaftes und unmittelbares Risiko',
	'Serious and imminent risk': 'Ernsthaftes und unmittelbares Risiko',
	'Serious risk': 'Ernsthaftes Risiko',
	'Serious and imminent': 'Ernsthaft und unmittelbar',
	Cellule: 'Zelle',
	'Select the Cellule': 'Wählen Sie die Zelle aus',
	'Select the Workstation': 'Wählen Sie die Arbeitsstation aus',
	'Working Population': 'Arbeitende Masse',
	Others: 'Andere',
	'Stress Level': 'Stresslevel',
	'Risk Level': 'Risikostufe',
	Repetitiveness: 'Wiederholung',
	'Cargo Handling': 'Frachtumschlag',
	'Work Organization': 'Arbeitsorganisation',
	'Score by workstation': 'Bewertung pro Arbeitsstation',
	'Total evaluations': 'Gesamtanzahl der Bewertungen',
	Low: 'Niedrig',
	Average: 'Durchschnittlich',
	High: 'Hoch',
	'Movement by score': 'Bewegung nach Punktzahl',
	'Risk per Workstation': 'Risiko pro Arbeitsstation',
	Workstations: 'Arbeitsstationen',
	'Higher workstation risk score': 'Höhere Risikopunktzahl der Arbeitsstation',
	'Action plans': 'Aktionspläne',
	'Planned Actions': 'Geplante Maßnahmen',
	Completed: 'Abgeschlossen',
	Concluded: 'Abgeschlossen',
	Ascending: 'Aufsteigend',
	Deadline: 'Frist',
	Next: 'Weiter',
	Previous: 'Zurück',
	delete: 'Löschen',
	Delete: 'Löschen',
	'Do you really want to delete these tracks?': 'Möchten Sie diese Tracks wirklich löschen?',
	'Heads up! the deletion is permanent': 'Achtung! Die Löschung ist dauerhaft.',
	'Risk ranges deleted successfully': 'Risikobereiche erfolgreich gelöscht',
	'Description (optional)': 'Beschreibung (optional)',
	Neck: 'Nacken',
	Trunk: 'Rumpf',
	UpperArm: 'Oberarm',
	LowerArm: 'Unterarm',
	'Customize bands of risk': 'Risikobänder anpassen',
	'Create custom risk strips according to your needs.':
		'Erstellen Sie benutzerdefinierte Risikostreifen nach Ihren Bedürfnissen.',
	Name: 'Name',
	'Industrial site name': 'Name des Industriestandorts',
	'Enter a name': 'Geben Sie einen Namen ein',
	'Please enter a valid name': 'Bitte geben Sie einen gültigen Namen ein',
	'Please enter a valid phone number': 'Bitte geben Sie eine gültige Telefonnummer ein',
	'Invalid phone number': 'Ungültige Telefonnummer',
	'Insert short description': 'Kurze Beschreibung einfügen',
	'Add new band': 'Neues Band hinzufügen',
	'Create new band': 'Neues Band erstellen',
	'Minimum angle': 'Mindestwinkel',
	'Maximum angle': 'Maximaler Winkel',
	Coloring: 'Einfärbung',
	Remove: 'Entfernen',
	Home: 'Startseite',
	'Manage users': 'Benutzer verwalten',
	Preferences: 'Einstellungen',
	'My informations': 'Meine Informationen',
	'My plan': 'Mein Plan',
	individual: 'Individuell',
	team: 'Team',
	business: 'Unternehmen',
	pilot: 'Pilot',
	yearly: 'Jährlich',
	monthly: 'Monatlich',
	Yearly: 'Jährlich',
	Monthly: 'Monatlich',
	User: 'Benutzer',
	Supervisor: 'Supervisor',
	Master: 'Master',
	Admin: 'Admin',
	'Choose a plan different from your current plan':
		'Wählen Sie einen Plan, der sich von Ihrem aktuellen Plan unterscheidet.',
	'Plan successfully canceled': 'Plan erfolgreich storniert',
	'Password changed successfully': 'Passwort erfolgreich geändert',
	'Customer plan not found': 'Benutzerplan nicht gefunden',
	'Customer data not found': 'Benutzerdaten nicht gefunden',
	'User access not found': 'Benutzerzugriff nicht gefunden',
	'File deleted successfully': 'Datei erfolgreich gelöscht',
	'Check the parameters entered': 'Überprüfen Sie die eingegebenen Parameter',
	'Number of document characters are invalid': 'Die Anzahl der Dokumentenzeichen ist ungültig',
	'Invalid inserted CPF': 'Ungültige eingegebene CPF-Nummer',
	'Invalid inserted CNPJ': 'Ungültige eingegebene CNPJ-Nummer',
	'It is necessary to spend a day after contracting the service to change the plan':
		'Es ist erforderlich, einen Tag nach Vertragsabschluss zu warten, um den Plan zu ändern',
	'Forgot password': 'Passwort vergessen',
	'Log in': 'Einloggen',
	'Please input your password!': 'Bitte geben Sie Ihr Passwort ein!',
	'Invalid email or password': 'Ungültige E-Mail oder Passwort',
	'The input is not a valid e-mail!': 'Die Eingabe ist keine gültige E-Mail!',
	'Please input your e-mail': 'Bitte geben Sie Ihre E-Mail-Adresse ein',
	'Inactive account, please activate': 'Inaktives Konto, bitte aktivieren',
	Send: 'Senden',
	'User not found': 'Benutzer nicht gefunden',
	'Success, check your email box': 'Erfolg, überprüfen Sie Ihren E-Mail-Posteingang',
	'Password recovery': 'Passwortwiederherstellung',
	'Token invalid': 'Ungültiges Token',
	'Token expired, generated a new one': 'Token abgelaufen, neues generiert',
	'Please confirm your password!': 'Bitte bestätigen Sie Ihr Passwort!',
	'Confirm Password': 'Passwort bestätigen',
	'The two passwords that you entered do not match!': 'Die beiden eingegebenen Passwörter stimmen nicht überein!',
	Confirm: 'Bestätigen',
	Generate: 'Generieren',
	Validate: 'Validieren',
	Continue: 'Weiter',
	'Reset token': 'Token zurücksetzen',
	Cancel: 'Abbrechen',
	flexion: 'Beugung',
	turned: 'Gedreht',
	extension: 'Streckung',
	sideways: 'Seitwärts',
	crouched: 'Gebückt',
	downed: 'Abgesenkt',
	arm_raised: 'Arm angehoben',
	behind: 'Hinten',
	raised: 'Angehoben',
	none: 'Keine',
	'Registered successfully!': 'Erfolgreich registriert!',
	'Just a little longer, check your e-mail inbox': 'Noch ein wenig Geduld, überprüfen Sie Ihren E-Mail-Posteingang',
	Access: 'Zugang',
	'Kinebot - Terms of use': 'Kinebot - Nutzungsbedingungen',
	'terms of use': 'Nutzungsbedingungen',
	"Welcome! Let's start": "Willkommen! Let's start",
	'Enter your phone number': 'Geben Sie Ihre Telefonnummer ein',
	'Select your contry': 'Wählen Sie Ihr Land aus',
	State: 'Bundesland',
	'Select your state': 'Wählen Sie Ihren Bundesland aus',
	'Select your city': 'Wählen Sie Ihre Stadt aus',
	'Zip code': 'Postleitzahl',
	'Enter your zipcode': 'Geben Sie Ihre Postleitzahl ein',
	'Enter your address': 'Geben Sie Ihre Adresse ein',
	'Enter your street number': 'Geben Sie Ihre Hausnummer ein',
	'Coupon (optional)': 'Gutschein (optional)',
	Coupon: 'Gutschein',
	'There are problems on your operation.': 'Es gibt Probleme bei Ihrer Operation.',
	Leave: 'Verlassen',
	Data: 'Daten',
	'Final steps': 'Endschritte',
	'Card number': 'Kartennummer',
	'Enter your credit card number': 'Geben Sie Ihre Kreditkartennummer ein',
	'Invalid credit card': 'Ungültige Kreditkarte',
	'Card holder name': 'Name des Karteninhabers',
	'Enter the card holder name': 'Geben Sie den Namen des Karteninhabers ein',
	'Due date': 'Fälligkeitsdatum',
	"Enter the card's due date": 'Geben Sie das Fälligkeitsdatum der Karte ein',
	'Invalid month': 'Ungültiger Monat',
	'Invalid card date': 'Ungültiges Kartenformat',
	'Enter the security code': 'Geben Sie den Sicherheitscode ein',
	'Invalid code': 'Ungültiger Code',
	"We are going through maintenance, we'll be back soon.":
		'Wir führen Wartungsarbeiten durch und sind bald wieder da.',
	'Personal data': 'Personenbezogene Daten',
	'Document number': 'Dokumentennummer',
	'You will only be charged after the free test period, it can be cancelled at anytime.':
		'Sie werden erst nach Ablauf der kostenlosen Testphase belastet. Es kann jederzeit gekündigt werden.',
	Discounts: 'Rabatte',
	'I have read and agree with the': 'Ich habe die gelesen und bin einverstanden mit den',
	Close: 'Schließen',
	'Validating coupon': 'Gutschein validieren',
	"Couldn't find the coupon": 'Gutschein konnte nicht gefunden werden',
	Individual: 'Individuell',
	Team: 'Team',
	Business: 'Unternehmen',
	'Enter your document no.': 'Geben Sie Ihre Dokumentennummer ein',
	'Enter a valid document': 'Geben Sie ein gültiges Dokument ein',
	'Enter a phone number': 'Geben Sie eine Telefonnummer ein',
	'Enter the zip code': 'Geben Sie die Postleitzahl ein',
	'Enter the city': 'Geben Sie die Stadt ein',
	'Enter the state': 'Geben Sie das Bundesland ein',
	'Street number': 'Hausnummer',
	'Under review': 'In Überprüfung',
	'Insufficient funds': 'Unzureichende Mittel',
	'Not accepted': 'Nicht akzeptiert',
	'Customer already registered': 'Benutzer bereits registriert',
	'Failed to perform transaction': 'Transaktion konnte nicht durchgeführt werden',
	'email must be unique': 'E-Mail muss eindeutig sein',
	'Error loading video': 'Fehler beim Laden des Videos',
	'Failed to fetch your plan information.': 'Fehler beim Abrufen Ihrer Planinformationen.',
	'Failed to fetch total customer videos.': 'Fehler beim Abrufen der Gesamtzahl der Benutzervideos.',
	'Maximum uploads reached.': 'Maximale Uploads erreicht',
	'Maximum minutes reached.': 'Maximale Minuten erreicht',
	'Corrupted video.': 'Beschädigtes Video',
	'Maximum registered users': 'Maximale Anzahl registrierter Benutzer',
	"The 'name' field is required.": "Das Feld 'Name' ist erforderlich.",
	"The 'email' field is required.": "Das Feld 'E-Mail' ist erforderlich.",
	"The 'country' field is required.": "Das Feld 'Land' ist erforderlich.",
	"The 'state' field is required.": "Das Feld 'Bundesland' ist erforderlich.",
	"The 'city' field is required.": "Das Feld 'Stadt' ist erforderlich.",
	"The 'address' field is required.": "Das Feld 'Adresse' ist erforderlich.",
	"The 'street_number' field is required.": "Das Feld 'Hausnummer' ist erforderlich.",
	"The 'zipcode' field is required.": "Das Feld 'Postleitzahl' ist erforderlich.",
	'Personal information': 'Persönliche Informationen',
	Localization: 'Lokalisierung',
	'Full name': 'Vollständiger Name',
	'Enter your full name': 'Geben Sie Ihren vollständigen Namen ein',
	'Enter your name': 'Geben Sie Ihren Namen ein',
	'Insert a document': 'Geben Sie ein Dokument ein',
	'Insert a valid document': 'Geben Sie ein gültiges Dokument ein',
	Address: 'Adresse',
	'Enter an address': 'Geben Sie eine Adresse ein',
	Number: 'Nummer',
	'Enter the number': 'Geben Sie die Nummer ein',
	Zipcode: 'Postleitzahl',
	'Enter zip code': 'Geben Sie die Postleitzahl ein',
	Country: 'Land',
	'Select your country': 'Wählen Sie Ihr Land aus',
	'Select state': 'Bundesland wählen',
	City: 'Stadt',
	'Select city': 'Wählen Sie Ihre Stadt aus',
	'Update completed': 'Update durchgeführt',
	'Customer information updated successfully': 'Benutzerdaten erfolgreich aktualisiert',
	'Fill in your personal information': 'Geben Sie Ihre persönlichen Informationen ein',
	'Forgot your password?': 'Passwort vergessen?',
	'We will send you an e-mail to regain access to your account, rest assured!':
		'Wir senden Ihnen eine E-Mail, um den Zugriff auf Ihr Konto wiederherzustellen, keine Sorge!',
	'Back to login': 'Zurück zur Anmeldung',
	'Please enter a valid e-mail address': 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
	'Enter an e-mail': 'Geben Sie eine E-Mail-Adresse ein',
	'Oops... Something went wrong!': 'Hoppla... Etwas ist schiefgelaufen!',
	'Operation performed successfully': 'Vorgang erfolgreich durchgeführt',
	'Check your email inbox': 'Überprüfen Sie Ihren E-Mail-Posteingang',
	'Create new password': 'Neues Passwort erstellen',
	'Your new password must be different from previous used passwords':
		'Ihr neues Passwort muss sich von zuvor verwendeten Passwörtern unterscheiden',
	'Enter your new password': 'Geben Sie Ihr neues Passwort ein',
	'Confirm new password': 'Neues Passwort bestätigen',
	'The two passwords that you entered do not match': 'Die beiden eingegebenen Passwörter stimmen nicht überein',
	Save: 'Speichern',
	'Click here to continue': 'Hier klicken, um fortzufahren',
	'Invalid Token': 'Ungültiges Token',
	'Expired token': 'Abgelaufenes Token',
	trunk: 'Rumpf',
	neck: 'Nacken',
	left_lower_arm: 'Linker Unterarm',
	right_lower_arm: 'Rechter Unterarm',
	left_upper_arm: 'Linker Oberarm',
	right_upper_arm: 'Rechter Oberarm',
	left_knee: 'Linkes Knie',
	right_knee: 'Rechtes Knie',
	left_ankle: 'Linker Knöchel',
	right_ankle: 'Rechter Knöchel',
	hip: 'Hüfte',
	left_hand: 'Linke Hand',
	right_hand: 'Rechte Hand',
	Knee: 'Knie',
	Ankle: 'Knöchel',
	Hip: 'Hüfte',
	Hand: 'Hand',
	Qualification: 'Qualifikation',
	'Select the body parts to display': 'Wählen Sie die anzuzeigenden Körperteile aus',
	'Light risk': 'Geringes Risiko',
	'Average risk': 'Durchschnittliches Risiko',
	Level: 'Level',
	'Limit of bands reached': 'Maximale Anzahl an Bändern erreicht',
	'Industrial site': 'Industriestandort',
	Period: 'Zeitraum',
	Search: 'Suche',
	Clear: 'Löschen',
	'Select company': 'Unternehmen auswählen',
	'Create new user': 'Neuen Benutzer erstellen',
	Email: 'E-Mail',
	Permissions: 'Berechtigungen',
	'Companies - Industrial sites': 'Unternehmen - Industriestandorte',
	'Change password': 'Passwort ändern',
	'Current password': 'Aktuelles Passwort',
	'New password': 'Neues Passwort',
	'Confirm password': 'Passwort bestätigen',
	'Enter your current password': 'Geben Sie Ihr aktuelles Passwort ein',
	'Enter a new password': 'Geben Sie ein neues Passwort ein',
	'Password must be at least 6 characters long': 'Das Passwort muss mindestens 6 Zeichen lang sein',
	'Repeat your new password': 'Wiederholen Sie Ihr neues Passwort',
	"The two passwords don't match, check":
		'Die beiden Passwörter stimmen nicht überein. Bitte überprüfen Sie sie erneut.',
	'Sorry time exceeded, try again.': 'Entschuldigung, die Zeit ist abgelaufen. Bitte versuchen Sie es erneut.',
	'Invalid current password': 'Aktuelles Passwort ungültig',
	'Update information': 'Aktualisierungsinformationen',
	'Phone number': 'Telefonnummer',
	'Account type': 'Kontotyp',
	'Created in': 'Erstellt am',
	'Postal code': 'Postleitzahl',
	'Sorry, something went wrong': 'Entschuldigung, etwas ist schiefgegangen',
	Retry: 'Erneut versuchen',
	Settings: 'Einstellungen',
	'My account': 'Mein Konto',
	Plan: 'Plan',
	'Subscription type': 'Abonnementtyp',
	Recurrence: 'Wiederholung',
	'Expiration date': 'Ablaufdatum',
	'Plan expiration date': 'Plan-Ablaufdatum',
	'Active users': 'Aktive Benutzer',
	'Processed videos': 'Verarbeitete Videos',
	'Processing time': 'Verarbeitungszeit',
	'Additional minutes': 'Zusätzliche Minuten',
	'Add credits': 'Guthaben hinzufügen',
	'Renew subscription': 'Abonnement verlängern',
	'Change subscription': 'Abonnement ändern',
	'Cancel subscription': 'Abonnement kündigen',
	of: 'von',
	minutes: 'Minuten',
	Test: 'Test',
	Minutes: 'Minuten',
	'Consolidate data': 'Daten konsolidieren',
	'Export report': 'Bericht exportieren',
	'There is no post with screwdriver / screwdriver': 'Es gibt keinen Beitrag mit Schraubenzieher / Schraubenzieher',
	'Data not found': 'Daten nicht gefunden',
	'Requires actions': 'Erfordert Maßnahmen',
	'No actions required': 'Keine Maßnahmen erforderlich',
	'High Risk and Requires Action': 'Hohe Risiken und erfordert Maßnahmen',
	'Moderate risk and no action required': 'Mittleres Risiko und keine Maßnahmen erforderlich',
	'Low Risk and No Action Required': 'Niedriges Risiko und keine Maßnahmen erforderlich',
	'Intensity of work': 'Arbeitsintensität',
	'Work organization': 'Arbeitsorganisation',
	'Is spontaneous breaks allowed during the workday?': 'Sind spontane Pausen während des Arbeitstages erlaubt?',
	'Is there a diversification of tasks in the performance of your functions?':
		'Gibt es eine Aufgabenvielfalt bei der Ausführung Ihrer Funktionen?',
	'Materials are always available at the workstation, avoiding rush, delay or overload?':
		'Materialien sind immer am Arbeitsplatz verfügbar, um Eile, Verzögerungen oder Überlastungen zu vermeiden?',
	Complexity: 'Komplexität',
	'Movements do not require high dexterity': 'Bewegungen erfordern keine hohe Geschicklichkeit',
	'Low level of attention requirement for long periods': 'Geringer Aufmerksamkeitsbedarf über längere Zeiträume',
	'Workstation is complex and high cognitive demand':
		'Der Arbeitsplatz ist komplex und erfordert hohe kognitive Anforderungen',
	'Workstation is simple and of low cognitive requirement':
		'Der Arbeitsplatz ist einfach und erfordert geringe kognitive Anforderungen',
	Displacement: 'Verschiebung',
	Squat: 'Hocke',
	Cervical: 'Halswirbelsäule',
	'Left Shoulder/Arm': 'Linke Schulter/Arm',
	'Right Shoulder/Arm': 'Rechte Schulter/Arm',
	'Left wrist': 'Linkes Handgelenk',
	'Right wrist': 'Rechtes Handgelenk',
	'No repeatability': 'Keine Wiederholbarkeit',
	'With repetition': 'Mit Wiederholung',
	' x per hour': ' x pro Stunde',
	'Without risk': 'Ohne Risiko',
	'Action Level': 'Aktionsstufe',
	'Complementary resources': 'Ergänzende Ressourcen',
	'Percussion tools': 'Schlagwerkzeuge',
	'Hit against': 'Schlag gegen',
	'Present Risk': 'Vorhandenes Risiko',
	'Low risk': 'Geringes Risiko',
	'Global Ranking': 'Globales Ranking',
	'Operator report': 'Bericht des Mitarbeiters',
	'Enter operator report below': 'Geben Sie den Bericht des Mitarbeiters unten ein',
	'Easy to understand information': 'Leicht verständliche Informationen',
	' 10 steps per minute': '10 Schritte pro Minute',
	'15 to 25 steps per minute': '15 bis 25 Schritte pro Minute',
	'Above 25 steps per minute': 'Über 25 Schritte pro Minute',
	'Inform below the displacement of the operator during the workday':
		'Geben Sie unten die Verschiebung des Mitarbeiters während des Arbeitstages an',
	'Regulation notes': 'Regulationsnotizen',
	'Spontaneous breaks are allowed during the workday (e.g. drinking water, drinking coffee, going to the bathroom)':
		'Spontane Pausen sind während des Arbeitstages erlaubt (z. B. Wasser trinken, Kaffee trinken, auf die Toilette gehen)',
	'In the performance of their functions, there is diversification of tasks':
		'Bei der Ausübung ihrer Funktionen gibt es eine Aufgabenvielfalt',
	'Materials are always available at the workstation, avoiding rush, delay or overload':
		'Materialien sind immer am Arbeitsplatz verfügbar, um Eile, Verzögerungen oder Überlastungen zu vermeiden',
	'Work intensity': 'Arbeitsintensität',
	Wrist: 'Handgelenk',
	'Enter job information below': 'Geben Sie unten die Jobinformationen ein',
	'During the operation, the operator taps his hands to adjust parts or components.':
		'Während der Operation muss der Mitarbeiter seine Hände benutzen, um Teile oder Komponenten anzupassen.',
	'Add a complementary video for wrist analysis': 'Fügen Sie ein Begleitvideo zur Handgelenkanalyse hinzu',
	'Do not include in the analysis': 'Nicht in der Analyse einbeziehen',
	'Include external video': 'Externes Video einbeziehen',
	'Enter video name': 'Geben Sie den Videonamen ein',
	'Sledgehammer and/or taps are hits from hard hits (except hit adjustments)':
		'Vorschlaghammer und/oder Schläge sind harte Schläge (außer Schlaganpassungen)',
	'Light - Quiet': 'Leicht - Leise',
	'Medium - Some effort is perceived (defined)': 'Mittel - Einige Anstrengung wird wahrgenommen (definiert)',
	'Heavy - Clear effort': 'Schwer - Deutliche Anstrengung',
	'Very heavy - Sharp effort': 'Sehr schwer - Hohe Anstrengung',
	'Next. maximum - Use of torso and limbs to generate greater force':
		'Maximal - Einsatz von Rumpf und Gliedmaßen, um mehr Kraft zu erzeugen',
	'Workstation Information': 'Arbeitsplatzinformationen',
	'Enter operator difficulty control below': 'Geben Sie unten die Schwierigkeitsregelung des Mitarbeiters ein',
	'Control Difficulty': 'Schwierigkeit der Steuerung',
	'Select a cost center': 'Wählen Sie eine Kostenstelle aus',
	'Enter data about vibration in employee activity below':
		'Geben Sie die Daten zur Vibration in der Mitarbeiteraktivität unten ein',
	"Enter below the data on the employee's reaction in the activity":
		'Geben Sie unten die Reaktionsdaten des Mitarbeiters in der Aktivität',
	'Insert a new reaction': 'Neue Reaktion einfügen',
	'Insert a new vibration': 'Neue Vibration einfügen',
	"Enter below the data of the employee's effort in the activity":
		'Geben Sie unten die Daten zur Anstrengung des Mitarbeiters in der Aktivität ein',
	'Insert a new effort': 'Eine neue Anstrengung einfügen',
	'Cost center': 'Kostenstelle',
	'Enter a workstation': 'Geben Sie einen Arbeitsplatz ein',
	'Enter the data related to the selected video below': 'Geben Sie die Daten zum ausgewählten Video unten ein',
	Staff: 'Personal',
	'Enter the Company Activities': 'Geben Sie die Unternehmensaktivitäten ein',
	'Insert a production line': 'Produktionslinie einfügen',
	'Add new range': 'Neuen Bereich hinzufügen',
	'industrial site': 'Industriestandort',
	'Production line': 'Produktionslinie',
	'Select a production line': 'Wählen Sie eine Produktionslinie aus',
	Role: 'Rolle',
	Code: 'Code',
	'Tax Code': 'Steuercode',
	'Enter a Tax Number': 'Geben Sie eine Steuernummer ein',
	'Insert a valid Tax Number': 'Gültige Steuernummer eingeben',
	'Select a role': 'Wählen Sie eine Rolle aus',
	'Insert the role': 'Rolle einfügen',
	'User type': 'Benutzertyp',
	Document: 'Dokument',
	'Current plan': 'Aktueller Plan',
	'Not provided': 'Nicht angegeben',
	'Risk ranges': 'Risikobereiche',
	'Local application': 'Lokale Anwendung',
	'Units for analysis': 'Zu analysierende Einheiten',
	'Select the units for review': 'Wählen Sie die Einheiten aus, die in der Überprüfung enthalten sein sollen',
	Factory: 'Fabrik',
	'Select the industrial sites for analysis': 'Wählen Sie die zu analysierenden Industriestandorte',
	'Production Volume': 'Produktionsvolumen',
	Investments: 'Investitionen',
	'Enter production volumes below': 'Geben Sie unten die Produktionsvolumina ein',
	'Inform below the investments in ergonomics': 'Informieren Sie unten über die Investitionen in Ergonomie',
	Date: 'Datum',
	'Date of reference': 'Referenzdatum',
	'Date of the report': 'Berichtsdatum',
	'Ergonomic analysis of work': 'Ergonomische Arbeitsanalyse',
	Presentation: 'Präsentation',
	'Plant Information': 'Werksinformationen',
	'Insert a company name': 'Geben Sie einen Firmennamen ein',
	'Risk degree': 'Risikograd',
	Activity: 'Aktivität',
	District: 'Bezirk',
	Administrative: 'Verwaltung',
	'Work Shift': 'Arbeitsschicht',
	Operational: 'Betrieblich',
	'Direct hourly worker': 'Direkter Stundenarbeiter',
	'Indirect hourly worker': 'Indirekter Stundenarbeiter',
	'Monthly worker': 'Monatlicher Arbeiter',
	'Normal working hours': 'Normale Arbeitszeit',
	'Include checklists related to this analysis below': 'Fügen Sie unten Checklisten zu dieser Analyse ein',
	'Non-standard scale': 'Nicht standardmäßige Skala',
	'Lower members': 'Untere Gliedmaßen',
	'Left elbow': 'Linker Ellenbogen',
	'Right elbow': 'Rechter Ellenbogen',
	'Left shoulder': 'Linke Schulter',
	'Right shoulder': 'Rechte Schulter',
	'Left knee': 'Linkes Knie',
	'Right knee': 'Rechtes Knie',
	'Left ankle': 'Linker Knöchel',
	'Right ankle': 'Rechter Knöchel',
	'Left hand': 'Linke Hand',
	'Right hand': 'Rechte Hand',
	'Video description': 'Video-Beschreibung',
	Below: 'Unten',
	Above: 'Oben',
	Checklist: 'Checkliste',
	'Frequency and duration': 'Häufigkeit und Dauer',
	'Date of collection': 'Erhebungsdatum',
	'Load from 2 kg to 10 kg (intermittent)': 'Last von 2 kg bis 10 kg (intermittierend)',
	'Load more than 10 kg of repetitive load or rapid build up of force':
		'Last von mehr als 10 kg wiederholter Last oder Schläge',
	Medium: 'Mittel',
	Invalid: 'Ungültig',
	Safe: 'Sicher',
	Info: 'Info',
	Danger: 'Gefahr',
	safe: 'sicher',
	info: 'Info',
	warning: 'Warnung',
	danger: 'Gefahr',
	'No corrective action needed': 'Keine Korrekturmaßnahme erforderlich',
	'Corrective action as soon as possible': 'Sofortige Korrekturmaßnahme',
	'High-priority corrective action': 'Hochpriorisierte Korrekturmaßnahme',
	'Immediate corrective action': 'Unmittelbare Korrekturmaßnahme',
	'Unable to show results': 'Ergebnisse können nicht angezeigt werden',
	Line: 'Linie',
	Effort: 'Anstrengung',
	Reaction: 'Reaktion',
	Vibration: 'Vibration',
	'Beats against': 'Schläge gegen',
	Percussion: 'Percussion',
	Hands: 'Hände',
	'Shoulders/Arms': 'Schultern/Arme',
	Left: 'Links',
	Right: 'Rechts',
	Exit: 'Beenden',
	Filters: 'Filter',
	'E-mail': 'E-Mail',
	'Enter a valid e-mail': 'Geben Sie eine gültige E-Mail-Adresse ein',
	'Enter your password': 'Geben Sie Ihr Passwort ein',
	Login: 'Anmelden',
	Dashboard: 'Dashboard',
	Upload: 'Hochladen',
	EWA: 'EWA',
	EAW: 'EAW',
	'Comparison over the last 6 months': 'Vergleich der letzten 6 Monate',
	'Are you sure?': 'Sind Sie sicher?',
	'Are you sure you want to quit? You have an upload in process.':
		'Sind Sie sicher, dass Sie aufhören möchten? Es gibt einen laufenden Upload.',
	'Select a company': 'Wählen Sie ein Unternehmen aus',
	'Select an industrial site': 'Wählen Sie einen Industriestandort aus',
	'Select a line': 'Wählen Sie eine Linie aus',
	'Select the workstation': 'Wählen Sie den Arbeitsplatz aus',
	'Select the activity': 'Wählen Sie die Aktivität aus',
	'No data available': 'Keine Daten verfügbar',
	'There is no registered data.': 'Es sind keine Daten registriert.',
	Logout: 'Abmelden',
	"Approximated values, depends mainly on the filming methods. In case there is no data, the algorithm didn't find the points to determine the angle, please review the filming method used.":
		'Annäherungswerte, abhängig von den Aufnahmemethoden. Wenn keine Daten vorliegen, hat der Algorithmus keine Punkte zur Bestimmung des Winkels gefunden. Bitte überprüfen Sie die verwendete Aufnahmemethode.',
	'The results obtained from each body part can be affected by the method and position of filming, the identification of the person in the analysis can suffer interference from objects present in the room. The angle data shown are approximate values.':
		'Die Ergebnisse, die für jede Körperpartie erzielt werden, können von der Aufnahmemethode und -position beeinflusst werden. Die Identifizierung der Person in der Analyse kann durch im Raum vorhandene Objekte beeinträchtigt werden. Die angezeigten Winkeldaten sind ungefähre Werte.',
	Reference: 'Referenz',
	'Investigate and change immediately': 'Sofort untersuchen und ändern',
	'Analysis of the movement by angle': 'Analyse der Bewegung nach Winkel',
	'Analysis of the movement by score': 'Analyse der Bewegung nach Bewertung',
	'Analysis of the movement & Body parts': 'Analyse der Bewegung und Körperteile',
	'Report types': 'Berichtstypen',
	'Generate reports': 'Berichte generieren',
	Finish: 'Beenden',
	'Description already exists!': 'Beschreibung existiert bereits!',
	'Enter a new sector': 'Geben Sie einen neuen Sektor ein',
	'Do you wish to delete this sector?': 'Möchten Sie diesen Sektor löschen?',
	'This sector is used in other reports, do you really wish to delete it?':
		'Dieser Sektor wird in anderen Berichten verwendet. Möchten Sie ihn wirklich löschen?',
	'Sector created successfully!': 'Sektor erfolgreich erstellt!',
	"This operation couldn't be completed": 'Diese Operation konnte nicht abgeschlossen werden.',
	Sectors: 'Sektoren',
	'Fill in the essential information below.': 'Füllen Sie die wesentlichen Informationen unten aus.',
	'Select the sector from the above analysis': 'Wählen Sie den Sektor aus der obigen Analyse aus.',
	'Select the sector for the analysis': 'Wählen Sie den Sektor für die Analyse aus.',
	Add: 'Hinzufügen',
	'Enter the workstation': 'Geben Sie den Arbeitsplatz ein.',
	'Enter the date the collection was made': 'Geben Sie das Datum der Datensammlung ein.',
	'Select the tool for the analysis and the type of report you wish to generate.':
		'Wählen Sie das Werkzeug für die Analyse und den gewünschten Berichtstyp aus.',
	Tool: 'Werkzeug',
	'Pick the tool above': 'Wählen Sie das oben genannte Werkzeug aus.',
	'Select the type of tool': 'Wählen Sie den Typ des Werkzeugs aus.',
	Report: 'Bericht',
	'Select the type of report above': 'Wählen Sie den oben genannten Berichtstyp aus.',
	'Select the type of report': 'Wählen Sie den Typ des Berichts aus.',
	Squatting: 'Hocken',
	Video: 'Video',
	'Very high': 'Sehr hoch',
	'Not identified': 'Nicht identifiziert',
	Comments: 'Kommentare',
	Filter: 'Filter',
	Company: 'Unternehmen',
	'Select the company': 'Wählen Sie das Unternehmen aus.',
	'Select the industrial site': 'Wählen Sie den Industriestandort aus.',
	Function: 'Funktion',
	'Enter the function': 'Geben Sie die Funktion ein.',
	'Customized reports': 'Benutzerdefinierte Berichte',
	"We weren't able to change your password": 'Wir konnten Ihr Passwort nicht ändern.',
	'Warning!': 'Achtung!',
	'Failed to fetch requested information': 'Fehler beim Abrufen der angeforderten Informationen.',
	'Do you really wish to cancel your plan?': 'Möchten Sie Ihren Plan wirklich stornieren?',
	'After cancelling your plan, when the expiration date comes, you will have no more access to the platform.':
		'Nach der Stornierung Ihres Plans haben Sie keinen Zugriff mehr auf die Plattform, wenn das Ablaufdatum erreicht ist.',
	"We weren't able to cancel your plan": 'Wir konnten Ihren Plan nicht stornieren.',
	"Select a plan that's different from your current one":
		'Wählen Sie einen Plan, der sich von Ihrem aktuellen unterscheidet.',
	"We couldn't process the operation": 'Wir konnten die Operation nicht durchführen.',
	'Plan cancelled on': 'Plan storniert am',
	'Plan switch': 'Planwechsel',
	'You have recently changed your plan': 'Sie haben kürzlich Ihren Plan geändert.',
	'Plan type': 'Planart',
	'New plan start date': 'Neues Startdatum des Plans',
	'User limit': 'Benutzerlimit',
	'Upload limit': 'Upload-Limit',
	'Processing time limit': 'Verarbeitungszeitlimit',
	'Cancel plan': 'Plan stornieren',
	'Error when trying to verify industrial sites from company.':
		'Fehler beim Versuch, die Industriestandorte des Unternehmens zu verifizieren.',
	'Error when trying to delete the company': 'Fehler beim Versuch, das Unternehmen zu löschen.',
	'Enter a permission': 'Geben Sie eine Berechtigung ein.',
	'Select a permission': 'Wählen Sie eine Berechtigung aus.',
	'Edit user': 'Benutzer bearbeiten',
	'User changed successfully': 'Benutzer erfolgreich geändert.',
	Permission: 'Berechtigung',
	'Do you wish to delete this user?': 'Möchten Sie diesen Benutzer löschen?',
	Active: 'Aktiv',
	Disabled: 'Deaktiviert',
	'Manage companies': 'Unternehmen verwalten',
	'Manage industrial sites': 'Industriestandorte verwalten',
	'Heads up! This company is not empty': 'Achtung! Dieses Unternehmen ist nicht leer.',
	'To continue, first remove industrial sites belonging to this company.':
		'Um fortzufahren, entfernen Sie zuerst die Industriestandorte, die zu diesem Unternehmen gehören.',
	'All industrial sites in this company will be deleted, along with their respective uploads and reports. Do you wish to proceed?':
		'Alle Industriestandorte in diesem Unternehmen werden zusammen mit ihren entsprechenden Uploads und Berichten gelöscht. Möchten Sie fortfahren?',
	'All uploads and reports from this industrial site will be deleted. This action cannot be undone. Do you wish to continue?':
		'Alle Uploads und Berichte von diesem Industriestandort werden gelöscht. Diese Aktion kann nicht rückgängig gemacht werden. Möchten Sie fortfahren?',
	'Failed to update information': 'Aktualisierung der Informationen fehlgeschlagen.',
	'Edit company': 'Unternehmen bearbeiten',
	Loading: 'Laden',
	'Loading...': 'Laden...',
	'Company name': 'Firmenname',
	'Corporate name': 'Firmenbezeichnung',
	'Industrial sites': 'Industriestandorte',
	'Updated information': 'Aktualisierte Informationen',
	'Error while changing the name of the industrial site': 'Fehler beim Ändern des Namens des Industriestandorts',
	Inactive: 'Inaktiv',
	All: 'Alle',
	'Organization created successfully': 'Organisation erfolgreich erstellt',
	'Organization updated successfully': 'Organisation erfolgreich aktualisiert',
	'Organization deleted successfully': 'Organisation erfolgreich gelöscht',
	'Data created successfully': 'Daten erfolgreich erstellt',
	'Data updated successfully': 'Daten erfolgreich aktualisiert',
	'Data deleted successfully': 'Daten erfolgreich gelöscht',
	'Please contact support to reactivate it': 'Bitte wenden Sie sich an den Support, um es zu reaktivieren.',
	'Create a new workstation': 'Neuen Arbeitsplatz erstellen',
	'Create a new sector': 'Neuen Sektor erstellen',
	'1 - Acceptable posture if not repeated or kept for long periods':
		'1 - Akzeptable Haltung, wenn nicht wiederholt oder für längere Zeiträume beibehalten',
	'2 - Investigate; possibility to request for change; it is convenient to introduce alterations':
		'2 - Untersuchen; Möglichkeit, Änderungen anzufordern; es ist ratsam, Änderungen einzuführen',
	'3 - Investigate; make changes quickly': '3 - Untersuchen; Änderungen schnell vornehmen',
	'4 - Immediate change': '4 - Sofortige Änderung',
	'Acceptable posture if not repeated or kept for long periods':
		'Akzeptable Haltung, wenn nicht wiederholt oder für längere Zeiträume beibehalten',
	'Investigate; possibility to request for change; it is convenient to introduce alterations':
		'Untersuchen; Möglichkeit, Änderungen anzufordern; es ist ratsam, Änderungen einzuführen',
	'Investigate; make changes quickly': 'Untersuchen; Änderungen schnell vornehmen',
	'Immediate change': 'Sofortige Änderung',
	Ergonomist: 'Ergonom',
	'Left lower arm': 'Linker Unterarm',
	'Right lower arm': 'Rechter Unterarm',
	'Left upper arm': 'Linker Oberarm',
	'Right upper arm': 'Rechter Oberarm',
	Type: 'Typ',
	'Manual Lifting Report': 'Manueller Heberbericht',
	'Enter the job characteristics below.': 'Geben Sie die Aufgabeneigenschaften unten ein.',
	'Gender of the worker': 'Geschlecht des Arbeitnehmers',
	'Select the gender': 'Wählen Sie das Geschlecht aus.',
	Age: 'Alter',
	'Select the age': 'Wählen Sie das Alter aus.',
	'How many workers are lifting the object?': 'Wie viele Arbeiter heben den Gegenstand?',
	'Which hands are used?': 'Welche Hände werden verwendet?',
	One: 'Eine',
	Two: 'Zwei',
	Three: 'Drei',
	'Both hands': 'Beide Hände',
	'One hand': 'Eine Hand',
	'Select the coupling quality': 'Wählen Sie die Koppelqualität aus.',
	'Select the lifting frequency': 'Wählen Sie die Hebehäufigkeit aus.',
	'Select the lifting duration': 'Wählen Sie die Hebedauer aus.',
	'Lifting frequency (lifts per minute)': 'Hebehäufigkeit (Hebungen pro Minute)',
	Poor: 'Schlecht',
	Fair: 'Angemessen',
	Good: 'Gut',
	'1 hour': '1 Stunde',
	'1 - 2 hours': '1 - 2 Stunden',
	'2 - 8 hours': '2 - 8 Stunden',
	'Enter the task variables below.': 'Bitte geben Sie die Aufgabenvariablen unten ein.',
	'Please enter a value': 'Bitte geben Sie einen Wert ein.',
	'Weight of the object': 'Gewicht des Objekts',
	'Distance the object is moved vertically': 'Vertikale Strecke, auf die das Objekt bewegt wird',
	'Vertical distance of the object at the beginning of handling, relative to the ground':
		'Vertikale Entfernung des Objekts zu Beginn der Handhabung relativ zum Boden',
	'Horizontal distance of the object relative to the body': 'Horizontale Entfernung des Objekts relativ zum Körper',
	'Rotation angle': 'Drehwinkel',
	'Recommended Weight Limit (RWL)': 'Empfohlene Gewichtsgrenze (RWL)',
	'Lifting Index (LI)': 'Hebeindex (LI)',
	'Coupling factor': 'Kopplungsfaktor',
	'Frequency factor': 'Frequenzfaktor',
	'lifts / min': 'Hebungen pro Minute',
	'Report date': 'Berichtsdatum',
	Comment: 'Kommentar',
	'Comment:': 'Kommentar:',
	Details: 'Details',
	Risk: 'Risiko',
	'Very low risk': 'Sehr geringes Risiko',
	Measurements: 'Messungen',
	'Lifted by': 'Gehoben von',
	'1 worker': '1 Arbeiter',
	'2 workers': '2 Arbeiter',
	'3 workers': '3 Arbeiter',
	Using: 'Verwendung von',
	'only one hand': 'nur einer Hand',
	'both hands': 'beiden Händen',
	'Reference weight': 'Referenzgewicht',
	'Enter the required values': 'Bitte geben Sie die erforderlichen Werte ein.',
	'Report created successfully': 'Bericht erfolgreich erstellt',
	'Report updated successfully': 'Bericht erfolgreich aktualisiert',
	'Want to delete this report?': 'Möchten Sie diesen Bericht löschen?',
	'Report deleted successfully': 'Bericht erfolgreich gelöscht',
	'Comment updated successfully': 'Kommentar erfolgreich aktualisiert',
	'Document created successfully': 'Dokument erfolgreich erstellt',
	Conclusions: 'Schlussfolgerungen',
	Conclusion: 'Schlussfolgerung',
	'The weight to be lifted': 'Das zu hebende Gewicht',
	'is greater than the recommended weight limit (RWL)': 'ist größer als die empfohlene Gewichtsgrenze (RWL)',
	'is less than the recommended weight limit (RWL)': 'ist kleiner als die empfohlene Gewichtsgrenze (RWL)',
	' The lifting index (LI)': 'Der Hebeindex (LI)',
	'is more than 3.': 'ist größer als 3.',
	'is more than 2 and less than 3.': 'ist größer als 2 und kleiner als 3.',
	'is more than 1,5 and less than 2.': 'ist größer als 1,5 und kleiner als 2.',
	'is more than 1 and less than 1,5.': 'ist größer als 1 und kleiner als 1,5.',
	'is less than 1.': 'ist kleiner als 1.',
	' There is a very high risk. These values indicate that this lift would be hazardous for a majority of healthy industrial workers.':
		' Es besteht ein sehr hohes Risiko. Diese Werte deuten darauf hin, dass dieser Hebevorgang für die Mehrheit der gesunden Industriearbeiter gefährlich wäre.',
	' There is a high risk. These values indicate that this lift would be hazardous for a majority of healthy industrial workers.':
		' Es besteht ein hohes Risiko. Diese Werte deuten darauf hin, dass dieser Hebevorgang für die Mehrheit der gesunden Industriearbeiter gefährlich wäre.',
	' There is a moderate risk. These values indicate that this job is somewhat stressful.':
		' Es besteht ein moderates Risiko. Diese Werte deuten darauf hin, dass diese Aufgabe etwas stressig ist.',
	' There is a low risk. These values indicate that this job is adequate for the majority of industrial workers, but some may have trouble.':
		' Es besteht ein geringes Risiko. Diese Werte deuten darauf hin, dass diese Aufgabe für die Mehrheit der Industriearbeiter angemessen ist, aber einige Schwierigkeiten haben könnten.',
	' There is a very low risk. These values indicate that this job is adequate for the majority of industrial workers.':
		' Es besteht ein sehr geringes Risiko. Diese Werte deuten darauf hin, dass diese Aufgabe für die Mehrheit der Industriearbeiter angemessen ist.',
	Male: 'Männlich',
	Female: 'Weiblich',
	Man: 'Mann',
	Woman: 'Frau',
	'20 to 45 years old': '20 bis 45 Jahre alt',
	'20 to 45': '20 bis 45',
	'< 20 years old': 'Unter 20 Jahre alt',
	'> 45 years old': 'Über 45 Jahre alt',
	'Download PDF': 'PDF herunterladen',
	'The most critical variables are listed below with suggestions':
		'Die kritischsten Variablen sind unten aufgeführt mit Vorschlägen',
	'Bring the load closer to the worker by removing any horizontal barriers or reducing the size of the object. Lifts near the floor should be avoided.':
		'Bringen Sie die Last näher an den Arbeiter heran, indem Sie horizontale Hindernisse entfernen oder die Größe des Objekts reduzieren. Heben in Bodennähe sollten vermieden werden.',
	'Raise/lower the origin/destination of the lift. Avoid lifting near the floor or above the shoulders.':
		'Heben/Senken Sie den Ursprung/Ziel des Hebels. Vermeiden Sie das Heben in Bodennähe oder über die Schultern.',
	'Reduce the vertical distance between the origin and the destination of the lift.':
		'Verringern Sie die vertikale Entfernung zwischen Ursprung und Ziel des Hebels.',
	'Move the origin and destination closer together to reduce the angle of twist, or move them further apart to force the worker to turn the feet and step, rather than twist the body.':
		'Bewegen Sie Ursprung und Ziel näher zusammen, um den Verdrehungswinkel zu verringern, oder bewegen Sie sie weiter auseinander, um den Arbeiter dazu zu zwingen, die Füße zu drehen und zu treten, anstatt den Körper zu verdrehen.',
	'Reduce the lifting frequency rate, reduce the lifting duration, or provide longer recovery periods (i.e., light work period).':
		'Verringern Sie die Hebefrequenz, verkürzen Sie die Hebelaufzeit oder bieten Sie längere Erholungszeiten (z.B. leichte Arbeitsperiode).',
	'Improve the hand-to-object coupling by providing optimal containers with handles or handhold cutouts, or improve the handholds for irregular objects.':
		'Verbessern Sie die Kopplung von Hand zu Objekt, indem Sie optimale Behälter mit Griffen oder Handhalterungen mit Ausschnitten verwenden oder die Handhalterungen für unregelmäßige Objekte verbessern.',
	'Consider using both hands when lifting the weight.': 'Erwägen Sie das Heben des Gewichts mit beiden Händen.',
	'One handed': 'Einhändig',
	'Distance H': 'Entfernung H',
	'Distance Vc': 'Entfernung Vc',
	'Distance Dc': 'Entfernung Dc',
	'Angle A': 'Winkel A',
	'Frequency/Duration': 'Frequenz/Dauer',
	Coupling: 'Kopplung',
	Characteristics: 'Charakteristiken',
	Variables: 'Variablen',
	Result: 'Ergebnis',
	'Choose a gender': 'Wählen Sie ein Geschlecht',
	Gender: 'Geschlecht',
	Pairs: 'Paare',
	'Report Results - Revised Strain Index': 'Berichtsergebnisse - Überarbeiteter Belastungsindex',
	'Intensity of exertion': 'Intensität der Anstrengung',
	'Efforts per minute': 'Anstrengungen pro Minute',
	'Number of exertions': 'Anzahl der Anstrengungen',
	'Observation time': 'Beobachtungszeit',
	'Duration per exertion': 'Dauer pro Anstrengung',
	'Hand/wrist posture': 'Hand-/Handgelenkshaltung',
	'Type of wrist posture': 'Art der Handgelenkshaltung',
	'Hand/wrist angle': 'Hand-/Handgelenkwinkel',
	'Duration of task per day': 'Dauer der Aufgabe pro Tag',
	'Revised Strain Index (RSI)': 'Überarbeiteter Belastungsindex (RSI)',
	'Risk factor': 'Risikofaktor',
	Input: 'Eingabe',
	'Job is probably safe': 'Die Arbeit ist wahrscheinlich sicher',
	'Job is probably hazardous': 'Die Arbeit ist wahrscheinlich gefährlich',
	Degrees: 'Grad',
	'Duration/day': 'Dauer/Tag',
	'Intensity of exertion (Borg Scale - BS)': 'Intensität der Anstrengung (Borg-Skala - BS)',
	'Somewhat Hard': 'Etwas anstrengend',
	Hard: 'Anstrengend',
	'Very Hard': 'Sehr anstrengend',
	'Near Maximal': 'Nahezu maximal',
	'Risk factors 1': 'Risikofaktoren 1',
	'Risk factors 2': 'Risikofaktoren 2',
	'Barely noticeable or relaxed effort (BS: 0-2)': 'Kaum wahrnehmbare oder entspannte Anstrengung (BS: 0-2)',
	'Noticeable or definite effort (BS: 3)': 'Spürbare oder deutliche Anstrengung (BS: 3)',
	'Obvious effort; Unchanged facial expression (BS: 4-5)':
		'Offensichtliche Anstrengung; unveränderter Gesichtsausdruck (BS: 4-5)',
	'Substantial effort; Changes expression (BS: 6-7)':
		'Spürbare Anstrengung; Veränderung des Gesichtsausdrucks (BS: 6-7)',
	'Uses shoulder or trunk for force (BS: 8-10)': 'Verwendung von Schulter oder Rumpf für Kraft (BS: 8-10)',
	'Number of exertions observed': 'Anzahl beobachteter Anstrengungen',
	'Total observation time': 'Gesamte Beobachtungszeit',
	'Average single exertion time': 'Durchschnittliche Einzelanstrengungszeit',
	'(total exertions * single exertion time) must be less than or equal to the total observation time':
		'(Gesamtanstrengungen * Einzelanstrengungszeit) darf nicht größer als die Gesamtbeobachtungszeit sein',
	seconds: 'Sekunden',
	hours: 'Stunden',
	Extension: 'Extension',
	Flexion: 'Flexion',
	'Revised Strain Index (Moore and Garg)': 'Überarbeiteter Belastungsindex (Moore und Garg)',
	'Report Results': 'Berichtsergebnisse',
	'Revised Strain Index': 'Überarbeiteter Belastungsindex',
	'(Moore and Garg)': '(Moore und Garg)',
	'REBA Report Results': 'REBA Berichtsergebnisse',
	'REBA score of the workplace': 'REBA-Score des Arbeitsplatzes',
	'Left leg': 'Linkes Bein',
	'Right leg': 'Rechtes Bein',
	'Load less than 5kg (intermittent)': 'Last weniger als 5 kg (intermittierend)',
	'Load from 5kg to 20kg (intermittent)': 'Last von 5 kg bis 20 kg (intermittierend)',
	'Load from 5kg to 20kg (static or repetitive)': 'Last von 5 kg bis 20 kg (statisch oder wiederkehrend)',
	'Greater than 20 kg of repetitive load or rapid build up of force':
		'Größere Last von 20 kg wiederholter Last oder Schläge',
	Unacceptable: 'Inakzeptabel',
	Force: 'Kraft',
	Load: 'Last',
	'Select the load': 'Wählen Sie die Last aus',
	'Select the number of repetitions': 'Wählen Sie die Anzahl der Wiederholungen aus',
	'Score left': 'Score links',
	'Score right': 'Score rechts',
	Acceptable: 'Akzeptabel',
	Investigate: 'Untersuchen',
	'Investigate and change soon': 'Untersuchen und bald ändern',
	'Investigate and change now': 'Untersuchen und jetzt ändern',
	'Investigate and Implement change': 'Untersuchen und Änderung umsetzen',
	'Implement change': 'Änderung umsetzen',
	'REBA Score': 'REBA-Score',
	'RULA Score': 'RULA-Score',
	'The 4 most critical body parts': 'Die 4 kritischsten Körperteile',
	'Viewing the selected body part': 'Anzeige des ausgewählten Körperteils',
	minute: 'Minute',
	'Requested video data is old, please upload to continue':
		'Die angeforderten Videodaten sind veraltet, bitte laden Sie sie hoch, um fortzufahren',
	'View score': 'Score anzeigen',
	'Unable to view': 'Nicht möglich anzuzeigen',
	'Worst grade found in time': 'Schlechteste Note in der Zeit gefunden',
	'Worst score': 'Schlechtester Score',
	'The results obtained in each body part may be impacted by the shape and position of filming, and the identification of the person being analyzed may be interfered by objects present in the operating location. The angle data shown are approximate values, dependent on the adopted filming technique. If there is no data, the algorithm did not identify the points to determine the angle, so it is important to observe filming techniques.':
		'Die Ergebnisse, die in jedem Körperteil erzielt werden, können durch die Form und Position der Aufnahmen beeinflusst werden, und die Identifizierung der analysierten Person kann durch vorhandene Objekte am Arbeitsort beeinträchtigt werden. Die angezeigten Winkeldaten sind ungefähre Werte, abhängig von der verwendeten Aufnahmetechnik. Wenn keine Daten vorliegen, hat der Algorithmus die Punkte zur Bestimmung des Winkels nicht identifiziert. Daher ist es wichtig, die Aufnahmetechniken zu beachten.',
	'Angle by time': 'Winkel über Zeit',
	'Create risk range': 'Risikobereich erstellen',
	'Report Results - Angle by Time': 'Berichtsergebnisse - Winkel über Zeit',
	'Please fill all required fields': 'Bitte füllen Sie alle erforderlichen Felder aus.',
	'Do you want to delete this action plan?': 'Möchten Sie diesen Aktionsplan löschen?',
	'Action plan created successfully': 'Aktionsplan erfolgreich erstellt',
	'Action plan updated successfully': 'Aktionsplan erfolgreich aktualisiert',
	'Create action plan': 'Aktionsplan erstellen',
	'Update action plan': 'Aktionsplan aktualisieren',
	'Please enter a valid description': 'Bitte geben Sie eine gültige Beschreibung ein.',
	'Action plan description': 'Beschreibung des Aktionsplans',
	'Please enter a valid action plan description': 'Bitte geben Sie eine gültige Beschreibung des Aktionsplans ein.',
	'Person responsible': 'Verantwortliche Person',
	Responsible: 'Verantwortlich',
	'Who is responsible for this action plan?': 'Wer ist für diesen Aktionsplan verantwortlich?',
	'Describe the situation found and the preventive measures already implemented (if exist)':
		'Beschreiben Sie die festgestellte Situation und die bereits umgesetzten Präventivmaßnahmen (falls vorhanden).',
	'Finish editing your other task first': 'Beenden Sie zuerst die Bearbeitung Ihrer anderen Aufgabe.',
	'Add a new task': 'Neue Aufgabe hinzufügen',
	History: 'Verlauf',
	'Finish by': 'Fertigstellung bis',
	'On time': 'Pünktlich',
	Overdue: 'Überfällig',
	'Finishing in a week': 'Fertigstellung in einer Woche',
	'Person responsible updated': 'Verantwortliche Person aktualisiert',
	'Deadline updated': 'Frist aktualisiert',
	Tasks: 'Aufgaben',
	'Task created': 'Aufgabe erstellt',
	'Task deleted': 'Aufgabe gelöscht',
	'Task renamed': 'Aufgabe umbenannt',
	'Task type changed': 'Aufgabentyp geändert',
	'Task and type changed': 'Aufgabe und Typ geändert',
	'Task checked': 'Aufgabe abgehakt',
	'Task unchecked': 'Aufgabe nicht abgehakt',
	'Action plan created': 'Aktionsplan erstellt',
	'Action plan renamed': 'Aktionsplan umbenannt',
	'Status changed': 'Status geändert',
	'Deadline changed': 'Frist geändert',
	by: 'von',
	'Successfully updated action plan data': 'Aktionsplandaten erfolgreich aktualisiert',
	'Responsible user changed': 'Verantwortliche Person geändert',
	'Action plan title': 'Titel des Aktionsplans',
	'Enter action plan title': 'Geben Sie den Titel des Aktionsplans ein',
	Title: 'Titel',
	'Select a file': 'Datei auswählen',
	'Select an option': 'Option auswählen',
	'Please enter a valid title': 'Bitte geben Sie einen gültigen Titel ein',
	'Please enter a responsible': 'Bitte geben Sie einen Verantwortlichen ein',
	'Please enter a valid date': 'Bitte geben Sie ein gültiges Datum ein',
	'Please select a condition': 'Bitte wählen Sie eine Bedingung aus',
	'Search company': 'Unternehmen suchen',
	'Search industrial site': 'Industriestandort suchen',
	'Enter a description for your action plan.': 'Geben Sie eine Beschreibung für Ihren Aktionsplan ein.',
	'Select a condition': 'Wählen Sie eine Bedingung aus',
	Eliminate: 'Beseitigen',
	Control: 'Kontrollieren',
	Compensate: 'Kompensieren',
	ELIMINATE: 'Beseitigen',
	CONTROL: 'Kontrollieren',
	COMPENSATE: 'Kompensieren',
	'Error, cannot get': 'Fehler, kann nicht erhalten',
	from: 'von',
	'Total duration of this sub-activity per working day': 'Gesamtdauer dieser Teilaufgabe pro Arbeitstag',
	'Total duration per day worked': 'Gesamtdauer pro Arbeitstag',
	'Force exerted on hand/fingers (L|R)': 'Kraft auf Hand/Finger (L|R) ausgeübt',
	'Power transfer/gripping conditions': 'Kraftübertragung/Griffformen',
	'gripping conditions': 'Griffformen',
	'Power transfer': 'Kraftübertragung',
	'Type of force exertion in the finger/hand area within a "standard minute"':
		'Art der Kraft, die innerhalb einer Standardminute im Finger-/Handbereich ausgeübt wird',
	'Force transfer / gripping conditions': 'Kraftübertragung / Griffformen',
	'Hand/arm position and movement': 'Hand-/Armposition und -bewegung',
	'Unfavourable working conditions': 'Ungünstige Arbeitsbedingungen',
	'Work organization / temporal distribution': 'Arbeitsorganisation / zeitliche Verteilung',
	'Body posture/movement': 'Körperhaltung / Bewegung',
	'Very low': 'Sehr gering',
	Moderate: 'Mäßig',
	'Peak forces': 'Spitzenkräfte',
	'Powerful hitting': 'Kraftvolles Schlagen',
	'Up to 15% Fmax. Button actuation, shifting, ordering, material guidance, insertion of small parts.':
		'Bis zu 15% Fmax. Tastenbetätigung, Schalten, Bestellen, Materialführung, Einsetzen von kleinen Teilen.',
	'Up to 30% Fmax. Gripping, joining small work pieces by hand or with small tools.':
		'Bis zu 30% Fmax. Greifen, Verbinden kleiner Werkstücke von Hand oder mit kleinen Werkzeugen.',
	'Up to 50% Fmax. Turning, winding, packaging, grasping, holding or joining parts, pressing in, cutting, working with small powered hand tools.':
		'Bis zu 50% Fmax. Drehen, Wickeln, Verpacken, Greifen, Halten oder Verbinden von Teilen, Einpressen, Schneiden, Arbeiten mit kleinen elektrischen Handwerkzeugen.',
	'Up to 80% Fmax. Cutting involving major element of force, working with small staple guns, moving or holding parts or tools.':
		'Bis zu 80% Fmax. Schneiden mit hoher Kraft, Arbeiten mit kleinen Tackerpistolen, Bewegen oder Halten von Teilen oder Werkzeugen.',
	'More than 80% Fmax. Tightening, loosening bolts, separating, pressing in.':
		'Mehr als 80% Fmax. Anziehen, Lösen von Schrauben, Trennen, Einpressen.',
	'Hitting with the ball of the thumb, palm of the hand or fist.':
		'Schlagen mit der Daumenkuppe, Handfläche oder Faust.',
	'Optimum force transfer/application': 'Optimale Kraftübertragung/-anwendung',
	'Restricted force transfer/application': 'Eingeschränkte Kraftübertragung/-anwendung',
	'Force transfer/application considerably hindered': 'Kraftübertragung/-anwendung erheblich behindert',
	'Optimum force application': 'Optimale Kraftanwendung',
	'Restricted force application': 'Eingeschränkte Kraftanwendung',
	'Force application considerably hindered': 'Kraftanwendung erheblich behindert',
	'Working objects are easy to grip (e.g. bar-shaped, gripping grooves) / good ergonomic gripping design (grips, buttons, tools).':
		'Arbeitsobjekte sind leicht zu greifen (z.B. stangenförmig, mit Griffmulden) / gute ergonomische Griffform (Griffe, Tasten, Werkzeuge).',
	'Greater holding forces required / no shaped grips.': 'Größere Haltekräfte erforderlich / keine geformten Griffe.',
	'Working objects hardly possible to grip (slippery, soft, sharp edges) / no or only unsuitable grips.':
		'Arbeitsobjekte sind kaum zu greifen (rutschig, weich, scharfe Kanten) / keine oder nur ungeeignete Griffe.',
	Restricted: 'Eingeschränkt',
	Unfavourable: 'Ungünstig',
	'Position or movements of joints in the middle (relaxed) range, only rare deviations, no continuous static arm posture, hand-arm rest possible as required.':
		'Position oder Bewegungen der Gelenke im mittleren (entspannten) Bereich, nur seltene Abweichungen, keine kontinuierliche statische Armhaltung, Hand-Arm-Ruhe nach Bedarf möglich.',
	'Occasional positions or movements of the joints at the limit of the movement ranges, occasional long continuous static arm posture.':
		'Gelegentliche Positionen oder Bewegungen der Gelenke am Bewegungslimit, gelegentliche langanhaltende statische Armhaltung.',
	'Frequent positions or movements of the joints at the limit of the movement ranges, frequent long continuous static arm posture.':
		'Häufige Positionen oder Bewegungen der Gelenke am Bewegungslimit, häufige langanhaltende statische Armhaltung.',
	'Constant positions or movements of the joints at the limit of the movement ranges, constant long continuous static arm posture.':
		'Konstante Positionen oder Bewegungen der Gelenke am Bewegungslimit, konstante langanhaltende statische Armhaltung.',
	'Frequent variation of the physical workload situation due to other activities (including other types of physical workload) / without a tight sequence of higher physical workloads within one type of physical workload during a single working day.':
		'Häufige Variation der physischen Arbeitsbelastung aufgrund anderer Aktivitäten (einschließlich anderer Arten von physischer Belastung) / ohne engen zeitlichen Ablauf höherer physischer Belastungen innerhalb einer Art von physischer Belastung an einem Arbeitstag.',
	'Rare variation of the physical workload situation due to other activities (including other types of physical workload) / occasional tight sequence of higher physical workloads within one type of physical workload during a single working day.':
		'Selten Variation der physischen Arbeitsbelastung aufgrund anderer Aktivitäten (einschließlich anderer Arten von physischer Belastung) / gelegentlich enger zeitlicher Ablauf höherer physischer Belastungen innerhalb einer Art von physischer Belastung an einem Arbeitstag.',
	'No/hardly any variation of the physical workload situation due to other activities (including other types of physical workload) / frequent tight sequence of higher physical workloads within one type of physical workload during a single working day with concurrent high load peaks.':
		'Keine/kaum Variation der physischen Arbeitsbelastung aufgrund anderer Aktivitäten (einschließlich anderer Arten von physischer Belastung) / häufig enger zeitlicher Ablauf höherer physischer Belastungen innerhalb einer Art von physischer Belastung an einem Arbeitstag mit gleichzeitig hohen Belastungsspitzen.',
	'There are no unfavourable working conditions, i.e. reliable recognition of detail / no dazzle / good climatic conditions.':
		'Es gibt keine ungünstigen Arbeitsbedingungen, d.h. zuverlässige Erkennung von Details / kein Blendungseffekt / gute klimatische Bedingungen.',
	'Occasionally impaired detail recognition due to dazzle or excessively small details difficult conditions such as draught, cold, moisture and/or disturbed concentration due to noise.':
		'Gelegentlich beeinträchtigte Detailerkennung aufgrund von Blendung oder zu kleinen Details, schwierige Bedingungen wie Zugluft, Kälte, Feuchtigkeit und/oder gestörte Konzentration aufgrund von Lärm.',
	'Frequently impaired detail recognition due to dazzle or excessively small details frequently difficult conditions such as draught, cold, moisture and/or disturbed concentration due to noise.':
		'Häufig beeinträchtigte Detailerkennung aufgrund von Blendung oder zu kleinen Details, häufig schwierige Bedingungen wie Zugluft, Kälte, Feuchtigkeit und/oder gestörte Konzentration aufgrund von Lärm.',
	'Alternation between sitting and standing, alternation between standing and walking, dynamic sitting possible':
		'Wechsel zwischen Sitzen und Stehen, Wechsel zwischen Stehen und Gehen, dynamisches Sitzen möglich.',
	'Trunk inclined forward only very slightly': 'Rumpf nur sehr leicht nach vorne geneigt.',
	'No twisting and/or lateral inclination of the trunk identifiable':
		'Keine Verdrehung und/oder seitliche Neigung des Rumpfes erkennbar.',
	'Head posture': 'Kopfhaltung',
	'No gripping above shoulder height / no gripping at a distance from the body':
		'Kein Greifen über Schulterhöhe / kein Greifen in Entfernung vom Körper.',
	'Predominantly sitting or standing with occasional walking':
		'Überwiegend Sitzen oder Stehen mit gelegentlichem Gehen.',
	'Trunk with slight inclination of the body towards the work area':
		'Rumpf leicht nach vorne geneigt und/oder gelegentliche Verdrehung und/oder seitliche Neigung des Rumpfes erkennbar.',
	'Occasional twisting and/or lateral inclination of the trunk identifiable':
		'Gelegentliche Verdrehung und/oder laterale Neigung des Rumpfes erkennbar.',
	'Occasional gripping above shoulder height / occasional gripping at a distance from the body':
		'Gelegentliches Greifen über Schulterhöhe / gelegentliches Greifen in Entfernung vom Körper.',
	'Exclusively standing or sitting without walking': 'Ausschließlich Stehen oder Sitzen ohne Gehen.',
	'Trunk clearly inclined forward and/or frequent twisting and/or lateral inclination of the trunk identifiable':
		'Rumpf deutlich nach vorne geneigt und/oder häufige Verdrehung und/oder seitliche Neigung des Rumpfes erkennbar.',
	'Frequent deviations from good “neutral” head posture/movement':
		"Häufige Abweichungen von guter 'neutraler' Kopfhaltung/Bewegung.",
	'Head posture hunched forward for detail recognition / restricted freedom of movement':
		'Kopfhaltung nach vorne gebeugt zur Detailerkennung / eingeschränkte Bewegungsfreiheit.',
	'Frequent gripping above shoulder height / frequent gripping at a distance from the body':
		'Häufiges Greifen über Schulterhöhe / häufiges Greifen in Entfernung vom Körper.',
	'Trunk severely inclined forward / frequent or long-lasting bending':
		'Rumpf stark nach vorne geneigt / häufiges oder langanhaltendes Bücken.',
	'Work being carried out in a kneeling, squatting, lying position':
		'Arbeit wird in kniender, hockender oder liegender Position durchgeführt.',
	'Constant twisting and/or lateral inclination of the trunk identifiable':
		'Konstante Verdrehung und/oder seitliche Neigung des Rumpfes erkennbar.',
	'Body posture strictly fixed / visual check of action through magnifying glasses or microscopes':
		'Körperhaltung strikt fixiert / visuelle Überprüfung der Handlung durch Lupen oder Mikroskope.',
	'Constant gripping above shoulder height / constant gripping at a distance from the body':
		'Konstantes Greifen über Schulterhöhe / konstantes Greifen in Entfernung vom Körper.',
	'Manual Handling': 'Manuelles Handling',
	'Manual Handling Operations': 'Manuelle Handhabungstätigkeiten',
	'KIM - Manual Handling Operations': 'KIM - Manuelle Handhabungstätigkeiten',
	'Total duration per working day': 'Gesamtdauer pro Arbeitstag',
	'Probability of physical overload': 'Wahrscheinlichkeit einer physischen Überlastung',
	'Possible health consequences': 'Mögliche gesundheitliche Folgen',
	'Physical overload is unlikely.': 'Physische Überlastung ist unwahrscheinlich.',
	'No health risk is to be expected.': 'Es ist kein Gesundheitsrisiko zu erwarten.',
	'None.': 'Keine.',
	'Physical overload is possible for less resilient persons.':
		'Physische Überlastung ist möglich für weniger belastbare Personen.',
	'Fatigue, low-grade adaptation problems which can be compensated for during leisure time.':
		'Ermüdung, leichte Anpassungsprobleme, die in der Freizeit ausgeglichen werden können.',
	'For less resilient persons, workplace redesign and other prevention measures may be helpful.':
		'Für weniger belastbare Personen können Arbeitsplatzumgestaltung und andere Präventionsmaßnahmen hilfreich sein.',
	'Physical overload is possible for normally resilient persons.':
		'Physische Überlastung ist möglich für normalerweise belastbare Personen.',
	'Disorders (pain), possibly including dysfunctions, reversible in most cases, without morphological manifestation.':
		'Störungen (Schmerzen), möglicherweise auch Dysfunktionen, in den meisten Fällen reversibel sind, ohne morphologische Manifestation.',
	'Workplace redesign and other prevention measures should be considered.':
		'Arbeitsplatzumgestaltung und andere präventive Maßnahmen sollten in Betracht gezogen werden.',
	'Physical overload is likely.': 'Physische Überlastung ist wahrscheinlich.',
	'More pronounced disorders and/or dysfunctions, structural damage with pathological significance.':
		'Ausgeprägtere Störungen und/oder Dysfunktionen, strukturelle Schäden mit pathologischer Bedeutung.',
	'Workplace redesign measures are necessary. Other prevention measures should be considered.':
		'Arbeitsplatzumgestaltung ist erforderlich. Es sollten auch andere Präventionsmaßnahmen in Betracht gezogen werden.',
	'Slightly increased': 'Leicht erhöht',
	'Substantially increased': 'Deutlich erhöht',
	'Type of force exertion in the finger/hand area': 'Art der Kraftausübung im Finger-/Handbereich',
	'Intensity of force exertion in the finger/hand area within a “standard minute”':
		'Intensität der Kraftausübung im Finger-/Handbereich innerhalb einer "standardminute"',
	'No measures need to be taken.': 'Es sind keine Maßnahmen erforderlich.',
	Holding: 'Halten',
	Moving: 'Bewegen',
	'Left arm': 'Linke Arm',
	'Right arm': 'Rechte Arm',
	'Time and force': 'Zeit und Kraft',
	Conditions: 'Bedingungen',
	Posture: 'Haltung',
	'Select the body parts you are interested in': 'Wählen Sie die Körperteile aus, die Sie interessieren',
	'Select all': 'Alle auswählen',
	'Shoulder L': 'Schulter L',
	'Shoulder R': 'Schulter R',
	'Elbow L': 'Ellbogen L',
	'Elbow R': 'Ellbogen R',
	'Leg L': 'Bein L',
	'Leg R': 'Bein R',
	Cycle: 'Zyklus',
	'Select one of the vehicles or a wheel type:': 'Wählen Sie einen der Fahrzeuge oder einen Radtyp aus:',
	Conveyors: 'Förderbänder',
	Cranes: 'Krane',
	'Increased movement speed (approx. 1.0 up to 1.3 m/s)': 'Erhöhte Bewegungsgeschwindigkeit (ca. 1,0 bis 1,3 m/s)',
	'length must be less than or equal to 50 characters long':
		'Die Länge muss weniger oder gleich 50 Zeichen lang sein.',
	'Ergonomic Workplace Analysis': 'Vorläufige ergonomische Analyse',
	'Ergonomic Analysis': 'Ergonomische Analyse',
	'Ergonomic analysis': 'Ergonomische Analyse',
	'Basic information': 'Grundlegende Informationen',
	'Basic Information': 'Grundlegende Informationen',
	"To generate this document, it's necessary that the EWA's must be consolidated, so the list below only shows the consolidated EWA's.":
		"Um dieses Dokument zu generieren, müssen die EWA's konsolidiert sein, daher zeigt die unten stehende Liste nur die konsolidierten EWA's an.",
	'Report Information': 'Berichtsinformationen',
	'Report name': 'Berichtsname',
	'Enter report name': 'Geben Sie den Berichtsnamen ein',
	'Select a Company': 'Wählen Sie ein Unternehmen aus',
	'Select the reference date': 'Wählen Sie das Bezugsdatum aus',
	'Select the report date': 'Wählen Sie das Berichtsdatum aus',
	'File Name': 'Dateiname',
	'Created At': 'Erstellt am',
	'Date of creation': 'Erstellungsdatum',
	'Analyst Name': 'Name des Analysten',
	Submit: 'Absenden',
	Signatures: 'Unterschriften',
	'Enter with a role': 'Mit einer Rolle eingeben',
	'Enter with a name of responsible': 'Mit einem verantwortlichen Namen eingeben',
	'Add a new signature': 'Neue Unterschrift hinzufügen',
	'Responsible name': 'Verantwortlicher Name',
	'Check selected Files': 'Ausgewählte Dateien überprüfen',
	'Files List': 'Dateiliste',
	'Selected EWA': 'Ausgewählte EWA',
	'EWA created successfully': 'EWA erfolgreich erstellt',
	'Failed to upload EWA report.': 'Fehler beim Hochladen des EWA-Berichts.',
	'Preliminary Ergonomic Analyses': 'Vorläufige ergonomische Analysen',
	'Selected Files': 'Ausgewählte Dateien',
	'Worst Score': 'Schlechteste Bewertung',
	'Open File List': 'Offene Dateiliste',
	'EWA deleted successfully': 'EWA erfolgreich gelöscht',
	Men: 'Männer',
	Women: 'Frauen',
	Likelihood: 'Wahrscheinlichkeit',
	Exposure: 'Exposition',
	'Possible Injuries/Harms': 'Mögliche Verletzungen/Schäden',
	Job: 'Aufgabe',
	'Assignment note': 'Hinweis zur Zuweisung',
	'Weight Limit recommended (WLR)': 'Empfohlenes Gewichtslimit (WLR)',
	'Lifting index (LI)': 'Hebeindex (LI)',
	Particularities: 'Besonderheiten',
	Production: 'Produktion',
	'Working regime': 'Arbeitsregime',
	'New document': 'Neues Dokument',
	'File history': 'Dateiverlauf',
	'Fill in the details above': 'Füllen Sie die oben stehenden Angaben aus',
	'Risk Acceptable': 'Akzeptables Risiko',
	'Immediate action for improvement and reassess': 'Unverzügliche Maßnahmen zur Verbesserung und Neubewertung',
	'Improvement action': 'Maßnahmen zur Verbesserung',
	'Risk Very High': 'Sehr hohes Risiko',
	'Perform Ergonomic Work Analysis': 'Ergonomische Arbeitsanalyse durchführen',
	'Risk Serious and Imminent': 'Schwerwiegendes und unmittelbares Risiko',
	'Stop the activity and carry out an Ergonomic Work Analysis':
		'Aktivität stoppen und eine ergonomische Arbeitsanalyse durchführen',
	'Possible injuries': 'Mögliche Verletzungen',
	'Fatigue complaints': 'Beschwerden von Müdigkeit',
	Absenteeism: 'Fehlzeiten',
	'Musculoskeletal complaints': 'Beschwerden des Muskel-Skelett-Systems',
	'Complaints of mental overload': 'Beschwerden von mentaler Überlastung',
	'Complaints of irritability': 'Beschwerden von Reizbarkeit',
	'Nonconforming products': 'Nicht konforme Produkte',
	'Low back pain complaints': 'Beschwerden von unteren Rückenschmerzen',
	'Elbow pain': 'Ellbogenschmerzen',
	'Shoulder pain': 'Schulterschmerzen',
	Headache: 'Kopfschmerzen',
	'Unexcused absences': 'Unentschuldigte Fehlzeiten',
	'Neck pain': 'Nackenschmerzen',
	'Wrist pain': 'Handgelenksschmerzen',
	Consequence: 'Konsequenz',
	'Severe with fatalities and serious injuries': 'Schwerwiegend mit Todesfällen und schweren Verletzungen',
	'Temporary disability': 'Temporäre Arbeitsunfähigkeit',
	'Injuries with leave': 'Verletzungen mit Arbeitsausfall',
	'Severe with possibility of death or disability': 'Schwerwiegend mit Möglichkeit von Tod oder Behinderung',
	'Superficial injuries': 'Oberflächliche Verletzungen',
	'Catastrophic with numerous deaths': 'Katastrophal mit zahlreichen Todesfällen',
	Probability: 'Wahrscheinlichkeit',
	'Most likely and expected outcome': 'Am wahrscheinlichsten und erwarteten Ergebnis',
	'Extremely remote possibility': 'Äußerst unwahrscheinliche Möglichkeit',
	'Practically impossible, never happened': 'Praktisch unmöglich, ist noch nie passiert',
	'Unlikely, but it has happened': 'Unwahrscheinlich, aber es ist schon vorgekommen',
	'Possible outcome': 'Mögliches Ergebnis',
	'Rare but possible': 'Selten, aber möglich',
	'Remotely (never occurred)': 'Fern (noch nie aufgetreten)',
	'Rarely (occurs in short bursts without definition)': 'Selten (tritt in kurzen Schüben ohne Definition auf)',
	'Irregular (once a month to once a year)': 'Unregelmäßig (einmal im Monat bis einmal im Jahr)',
	'Occasionally (once a week to once a month)': 'Gelegentlich (einmal pro Woche bis einmal pro Monat)',
	'Frequent (at least once a day)': 'Häufig (mindestens einmal pro Tag)',
	'Continues (several times a day)': 'Fortlaufend (mehrmals täglich)',
	'Remotely (>90% green)': 'Selten (über 90 % grün)',
	'Rarely (80 to 89% green)': 'Gelegentlich (80 bis 89 % grün)',
	'Irregular (50 to 79% green)': 'Unregelmäßig (50 bis 79 % grün)',
	'Occasionally (30 to 49% green)': 'Gelegentlich (30 bis 49 % grün)',
	'Frequent (10 to 29% green)': 'Häufig (10 bis 29 % grün)',
	'Continues (<9% green)': 'Dauerhaft (< 9 % grün)',
	'Select the injury': 'Wählen Sie die Verletzung aus',
	'Select the injuries': 'Wählen Sie die Verletzungen aus',
	'Select the consequence': 'Wählen Sie die Konsequenz aus',
	'Select the probability': 'Wählen Sie die Wahrscheinlichkeit aus',
	'Select the exposure': 'Wählen Sie die Exposition aus',
	"Some required step wasn't filled": 'Ein erforderlicher Schritt wurde nicht ausgefüllt',
	Score: 'Score',
	Informations: 'Informationen',
	'Select the sector': 'Wählen Sie den Sektor aus',
	Analyst: 'Analyst',
	'Insert the analyst': 'Analyst einfügen',
	'Insert the responsibility': 'Verantwortung einfügen',
	'Insert the cell': 'Zelle einfügen',
	'Insert the activity': 'Aktivität einfügen',
	'Insert the workstation': 'Arbeitsplatz einfügen',
	'Enter the collection date': 'Erfassungsdatum eingeben',
	'Work Conditions': 'Arbeitsbedingungen',
	'Select the constraints perceived in the evaluated work condition':
		'Einschränkungen in der bewerteten Arbeitsbedingung auswählen',
	'Work schedule': 'Arbeitszeitplan',
	'Summary description of the place': 'Zusammenfassende Beschreibung des Ortes',
	'Simplified description of the expected task': 'Vereinfachte Beschreibung der erwarteten Aufgabe',
	'Simplified description of the task performed': 'Vereinfachte Beschreibung der ausgeführten Aufgabe',
	'Working population': 'Mitarbeiter',
	'Working population percentages must sum up to 100%.':
		'Prozentsätze der Mitarbeiter müssen sich auf 100 % summieren.',
	"Worker's verbalization": 'Äußerungen des Arbeiters',
	'Self-evaluation': 'Selbstbewertung',
	'What is the level of stress perceived by the worker when performing the task?':
		'Wie hoch ist das Stressniveau, das der Arbeiter bei der Durchführung der Aufgabe wahrnimmt?',
	'Nothing stressful': 'Nicht stressig',
	'Little stressful': 'Wenig stressig',
	'Working population must be greater than 0': 'Die Anzahl der Mitarbeiter muss größer als 0 sein',
	'Total working population must be greater than 0': 'Die Gesamtanzahl der Mitarbeiter muss größer als 0 sein',
	workers: 'Arbeiter',
	Stressful: 'Stressig',
	'Update the tool': 'Werkzeug aktualisieren',
	'Tool already set': 'Werkzeug bereits eingestellt',
	'Tool not set yet': 'Werkzeug noch nicht eingestellt',
	'Very stressful': 'Sehr stressig',
	'Extremely stressful': 'Äußerst stressig',
	'Workday and concentration at work': 'Arbeitstag und Konzentration bei der Arbeit',
	'Working hours of more than 8 hours a day': 'Arbeitszeiten von mehr als 8 Stunden pro Tag',
	'Existence of long and frequent overtime hours of work': 'Vorhandensein von langen und häufigen Überstunden',
	'Stays long time continuous operation': 'Lange kontinuierliche Arbeitseinsätze',
	'Insufficient rest interval': 'Unzureichende Ruheintervalle',
	'Insufficient rest break': 'Unzureichende Pausen',
	'Insufficient rest days': 'Unzureichende Ruhetage',
	'Unbalanced concentrations of work in a day, week, month or year':
		'Ungleichmäßige Arbeitskonzentration an einem Tag, in einer Woche, einem Monat oder einem Jahr',
	'Unbalanced concentrations of work among workers': 'Ungleichmäßige Arbeitskonzentration unter den Mitarbeitern',
	'Insufficient rest between shifts (less than 11 hours)':
		'Unzureichende Ruhe zwischen Schichten (weniger als 11 Stunden)',
	'Remains long time continuous operation': 'Lange kontinuierliche Arbeitseinsätze',
	'Kind of work': 'Art der Arbeit',
	'Lift and/or carry heavy objects': 'Heben und/oder Tragen schwerer Gegenstände',
	'Apply the tool': 'Anwendung von Werkzeugen',
	'Load surveys': 'Lastenhebungen',
	'Lifts and/or carries objects farther than 60 cm': 'Heben und/oder Tragen von Gegenständen über 60 cm',
	'Performs tasks that require great strength': 'Ausführung von Aufgaben, die große Kraft erfordern',
	'Performs tasks that require pronounced pushing and pulling forces':
		'Ausführung von Aufgaben, die ausgeprägte Schub- und Zugkräfte erfordern',
	'Performs repetitive work': 'Wiederholende Arbeit verrichten',
	'Performs work that requires frequent finger, hand, or arm movements':
		'Arbeiten, die häufige Finger-, Hand- oder Arm-Bewegungen erfordern',
	'Do intensive work with a keyboard or other input devices':
		'Intensive Arbeit mit Tastatur oder anderen Eingabegeräten durchführen',
	'Perform precision work': 'Präzisionsarbeit verrichten',
	'Performs activities with high visual requirements': 'Aktivitäten mit hohen visuellen Anforderungen durchführen',
	'Postures and movements': 'Haltungen und Bewegungen',
	'Stays in uncomfortable postures and movements': 'In unbequemen Haltungen und Bewegungen verharren',
	'Performs continuous and/or highly frequent change in joint positions':
		'Kontinuierliche und/oder sehr häufige Veränderung der Gelenkpositionen durchführen',
	'Stays in constrained posture for long duration': 'In eingeschränkter Haltung lange verharren',
	'Walk for long duration and/or long distance (on a horizontal surface as well as an inclined surface)':
		'Lange und/oder weite Strecken gehen (sowohl auf horizontalen als auch auf geneigten Flächen)',
	'Performs frequent stair climbing': 'Häufiges Treppensteigen',
	'Remains in a sedentary/standing position for a long time': 'Lange Zeit in sitzender/stehender Position verbringen',
	'Postures requirements (Kinebot)': 'Anforderungen an Haltungen (Kinebot)',
	'Influence of workspace and task factors': 'Einfluss von Arbeitsplatz- und Aufgabenfaktoren',
	'Workspace is inadequate and forces an uncomfortable posture or limited movement':
		'Arbeitsplatz ist unzureichend und zwingt zu unbequemen Haltungen oder eingeschränkter Bewegung',
	'Workstation layout requires excessive movement or uncomfortable postures':
		'Die Anordnung des Arbeitsplatzes erfordert übermäßige Bewegung oder unbequeme Haltungen',
	'The heights, distances and dimensions of controls or work surfaces are inadequate':
		'Die Höhen, Abstände und Abmessungen von Bedienelementen oder Arbeitsflächen sind unzureichend',
	'Anthropometry Table x Furniture (cm)': 'Anthropometrische Tabelle x Möbel (cm)',
	'Add information': 'Informationen hinzufügen',
	'Found (cm)': 'Gefunden (cm)',
	'Foreseen (cm)': 'Voraussichtlich (cm)',
	'Enter a location': 'Ort eingeben',
	'Enter what was found': 'Erfasstes Ergebnis',
	'Insert predicted': 'Prognostiziertes Ergebnis',
	'Performs handling of work objects above the shoulder or below the knee':
		'Handhabung von Arbeitsgegenständen über der Schulter oder unter dem Knie',
	'The workspace forces the worker to maintain the same working posture':
		'Der Arbeitsplatz zwingt den Mitarbeiter dazu, die gleiche Arbeitshaltung beizubehalten',
	'Handles work objects that are difficult to handle and slippery':
		'Handhabung von schwer zu handhabenden und rutschigen Arbeitsgegenständen',
	'Activity requires high-voltage contact or local pressure acting on the body':
		'Tätigkeit erfordert Hochspannungskontakt oder lokalen Druck auf den Körper',
	'Influence of psychosocial factors': 'Einfluss von psychosozialen Faktoren',
	'Activity with mental overload or underload': 'Tätigkeit mit mentaler Überlastung oder Unterforderung',
	'Work pace with time pressure and high demands': 'Arbeitstempo mit Zeitdruck und hohen Anforderungen',
	'Presence of work-related stress': 'Vorhandensein von arbeitsbedingtem Stress',
	'Reports of low job satisfaction': 'Berichte über geringe Arbeitszufriedenheit',
	'Activity with lack of autonomy (low influence, low control)':
		'Tätigkeit mit geringer Autonomie (geringer Einfluss, geringe Kontrolle)',
	'Low social support': 'Geringe soziale Unterstützung',
	'Influence of environmental factors': 'Einfluss von Umweltfaktoren',
	'Place with slippery and/or uneven floor': 'Ort mit rutschigem und/oder unebenem Boden',
	'There is exposure to whole body vibration (see ISO 2631)':
		'Exposition gegenüber Ganzkörpervibrationen (siehe ISO 2631)',
	'Makes use of hand-arm vibration tools during work (see ISO 5349)':
		'Verwendung von Hand-Arm-Vibrationswerkzeugen während der Arbeit (siehe ISO 5349)',
	'There is exposure to whole body vibration (check ISO 2631)':
		'Exposition gegenüber Ganzkörpervibrationen (siehe ISO 2631)',
	'Makes use of hand-arm vibration tools during work (check ISO 5349)':
		'Verwendung von Hand-Arm-Vibrationswerkzeugen während der Arbeit (siehe ISO 5349)',
	'Performs work using vehicles that transmit vibration throughout the body':
		'Ausführung von Arbeiten mit Fahrzeugen, die Vibrationen im ganzen Körper übertragen',
	'Hot or cold working environment': 'Warmer oder kalter Arbeitsbereich',
	'Location with background noise': 'Ort mit Hintergrundgeräuschen',
	'Influence of organizational factors': 'Einfluss von organisatorischen Faktoren',
	'Production standards are not clear or understood by workers':
		'Produktionsstandards sind nicht klar definiert oder von den Mitarbeitern verstanden',
	'The operative mode descriptions are insufficient or wrong':
		'Die Beschreibung der Arbeitsweise ist unzureichend oder falsch',
	'The time to carry out the activities is inadequate':
		'Die Zeit für die Durchführung der Aktivitäten ist unzureichend',
	'The pace of work is accelerated': 'Das Arbeitstempo ist beschleunigt',
	'The content of the tasks is different from the technical means available':
		'Der Inhalt der Aufgaben weicht von den vorhandenen technischen Möglichkeiten ab',
	'Task with high cognitive demand': 'Aufgabe mit hoher kognitiver Anforderung',
	'Anthropometry Table': 'Anthropometrische Tabelle',
	'Consolidate analysis': 'Analyse konsolidieren',
	Deconsolidate: 'Analyse dekonsolidieren',
	'Successfully consolidated analysis': 'Analyse erfolgreich konsolidiert',
	'Successfully deconsolidated analysis': 'Analyse erfolgreich dekonsolidiert',
	'Failed to generate the report': 'Fehler beim Generieren des Berichts',
	'Failed to generate PDF': 'Fehler beim Generieren des PDFs',
	'Cannot change a consolidated analysis': 'Eine konsolidierte Analyse kann nicht geändert werden',
	'Comments successfully saved': 'Kommentare erfolgreich gespeichert',
	'Action plan deleted successfully': 'Maßnahmenplan erfolgreich gelöscht',
	'Task created successfully': 'Aufgabe erfolgreich erstellt',
	'Task deleted successfully': 'Aufgabe erfolgreich gelöscht',
	'Task updated successfully': 'Aufgabe erfolgreich aktualisiert',
	'Ops... something happened!': 'Ups... etwas ist schiefgelaufen!',
	'It was not possible to save your information': 'Die Informationen konnten nicht gespeichert werden',
	Hazardous: 'Gefährlich',
	'Movement by score (Kinebot/REBA)': 'Bewegung nach Bewertung (Kinebot/REBA)',
	'Cargo Handling (NIOSH)': 'Lastenhandhabung (NIOSH)',
	'Manual Handling (KIM)': 'Manuelles Handling (KIM)',
	'Push and Pull (KIM)': 'Drücken und Ziehen (KIM)',
	'No more action plans available': 'Keine weiteren Maßnahmenpläne verfügbar',
	'No more workstations available': 'Keine weiteren Arbeitsplätze verfügbar',
	'No task available': 'Keine Aufgabe verfügbar',
	day: 'Tag',
	days: 'Tage',
	Location: 'Ort',
	Found: 'Gefunden',
	Foreseen: 'Voraussichtlich',
	Condition: 'Zustand',
	'NOT OK': 'NICHT OK',
	"Score's sum": 'Summe der Bewertungen',
	'Trunk posture from Kinebot': 'Haltung des Rumpfes laut Kinebot',
	'Trunk posture from kinebot': 'Haltung des Rumpfes laut Kinebot',
	'Neck posture from Kinebot': 'Haltung des Nackens laut Kinebot',
	'Neck posture from kinebot': 'Haltung des Nackens laut Kinebot',
	'Left elbow posture from Kinebot': 'Haltung des linken Ellbogens laut Kinebot',
	'Left elbow posture from kinebot': 'Haltung des linken Ellbogens laut Kinebot',
	'Right elbow posture from Kinebot': 'Haltung des rechten Ellbogens laut Kinebot',
	'Right elbow posture from kinebot': 'Haltung des rechten Ellbogens laut Kinebot',
	'Left shoulder posture from Kinebot': 'Haltung der linken Schulter laut Kinebot',
	'Left shoulder posture from kinebot': 'Haltung der linken Schulter laut Kinebot',
	'Right shoulder posture from Kinebot': 'Haltung der rechten Schulter laut Kinebot',
	'Right shoulder posture from kinebot': 'Haltung der rechten Schulter laut Kinebot',
	'Failed to upload the file': 'Fehler beim Hochladen der Datei',
	'Two-factor authentication not found': 'Zwei-Faktor-Authentifizierung nicht gefunden',
	'Already created two-factor authentication': 'Zwei-Faktor-Authentifizierung bereits erstellt',
	'Invalid token': 'Ungültiger Token',
	'Token already validated': 'Token bereits validiert',
	'Token not yet validated': 'Token noch nicht validiert',
	'Token expired': 'Token abgelaufen',
	'Too early to resend email': 'Zu früh, um E-Mail erneut zu senden',
	'Something went wrong checking token': 'Etwas ist schiefgegangen beim Überprüfen des Tokens',
	'Authentication type same as default': 'Authentifizierungstyp entspricht dem Standard',
	'Authentication type through app already enabled': 'Authentifizierungstyp über App bereits aktiviert',
	'Two-factor authentication not enabled': 'Zwei-Faktor-Authentifizierung nicht aktiviert',
	'Too many tries!': 'Zu viele Versuche!',
	'Too many calls! Wait a moment to try again.':
		'Zu viele Anfragen! Bitte warten Sie einen Moment, um es erneut zu versuchen.',
	'Two-factor authentication generated successfully': 'Zwei-Faktor-Authentifizierung erfolgreich generiert',
	'Two-factor authentication validated successfully': 'Zwei-Faktor-Authentifizierung erfolgreich validiert',
	'Two-factor authentication method changed successfully':
		'Zwei-Faktor-Authentifizierungsmethode erfolgreich geändert',
	'Token sent successfully': 'Token erfolgreich gesendet',
	'Token resent successfully': 'Token erfolgreich erneut gesendet',
	'Authenticator app enabled successfully': 'Authenticator-App erfolgreich aktiviert',
	'Two-factor authentication deleted successfully': 'Zwei-Faktor-Authentifizierung erfolgreich gelöscht',
	'Two-factor authentication reset successfully': 'Zwei-Faktor-Authentifizierung erfolgreich zurückgesetzt',
	'Forced two-factor authentication enabled successfully':
		'Erzwungene Zwei-Faktor-Authentifizierung erfolgreich aktiviert',
	'Forced two-factor authentication disabled successfully':
		'Erzwungene Zwei-Faktor-Authentifizierung erfolgreich deaktiviert',
	'Account overview': 'Kontenübersicht',
	Profile: 'Profil',
	'Street name': 'Straßenname',
	'Available time': 'Verfügbare Zeit',
	'Time used': 'Verwendete Zeit',

	'Force two-factor authentication to all users': 'Zwei-Faktor-Authentifizierung für alle Benutzer erzwingen',
	'When activated, all users will be forced to activate two-factor authentication on login.':
		'Wenn aktiviert, werden alle Benutzer gezwungen, bei der Anmeldung die Zwei-Faktor-Authentifizierung zu aktivieren.',
	'Two-factor authentication': 'Zwei-Faktor-Authentifizierung',
	'When activated, you will have to type a validation code every time you login.':
		'Wenn aktiviert, müssen Sie bei jeder Anmeldung einen Bestätigungscode eingeben.',
	'You can choose to receive your code by email or connect your authentication app with a QRCode.':
		'Sie können wählen, ob Sie Ihren Code per E-Mail erhalten oder Ihre Authentifizierungs-App mit einem QR-Code verbinden möchten.',
	'Reset 2FA': '2FA zurücksetzen',
	'Deactivate 2FA': '2FA deaktivieren',
	'Confirm your password to proceed with the desired action':
		'Bestätigen Sie Ihr Passwort, um mit der gewünschten Aktion fortzufahren',
	'Deactivate two-factor authentication': 'Zwei-Faktor-Authentifizierung deaktivieren',
	'You are about to reset your authentication method.':
		'Sie sind dabei, Ihre Authentifizierungsmethode zurückzusetzen.',
	'Select a new desired authentication type and insert one of your recovery token to perform this operation.':
		'Wählen Sie einen neuen gewünschten Authentifizierungstyp aus und geben Sie einen Ihrer Wiederherstellungstoken ein, um diese Operation durchzuführen.',
	'Authentication method': 'Authentifizierungsmethode',
	'Recovery token': 'Wiederherstellungstoken',
	'Authenticator app': 'Authenticator-App',
	'You are about to deactivate your 2FA.': 'Sie sind dabei, Ihre 2FA zu deaktivieren.',
	'Confirming this action will delete your two-factor authentication. Are you sure?':
		'Die Bestätigung dieser Aktion löscht Ihre Zwei-Faktor-Authentifizierung. Sind Sie sicher?',
	'Heads up! Closing now will delete the token you generated. Want to continue?':
		'Achtung! Beim Schließen wird der von Ihnen generierte Token gelöscht. Möchten Sie fortfahren?',
	'Enable two-factor authentication': 'Aktivieren Sie die Zwei-Faktor-Authentifizierung',
	'Select a method to generate your two-factor authentication':
		'Wählen Sie eine Methode, um Ihre Zwei-Faktor-Authentifizierung zu generieren',
	'With this method, you will scan a QRCode so you can use your favorite authenticator app (such as Google Authenticator or Microsoft Authenticator). Then, all you have to do is type the code shown in your app when you login.':
		'Bei dieser Methode scannen Sie einen QR-Code, um Ihre bevorzugte Authentifizierungs-App (wie Google Authenticator oder Microsoft Authenticator) zu verwenden. Geben Sie dann den Code ein, der in Ihrer App angezeigt wird, wenn Sie sich anmelden.',
	'With this method, you will receive the login code in your registered email. At the time of login, you must fill in the indicated field with the code sent to your email.':
		'Bei dieser Methode erhalten Sie den Anmeldecode in Ihrer registrierten E-Mail. Bei der Anmeldung müssen Sie das angegebene Feld mit dem Code aus Ihrer E-Mail ausfüllen.',
	'You are about to generate your 2FA with your authenticator app.':
		'Sie sind dabei, Ihre 2FA mit Ihrer Authenticator-App zu generieren.',
	'Be sure to have your mobile phone with you ready to scan your code.':
		'Stellen Sie sicher, dass Sie Ihr Mobiltelefon bereithalten, um Ihren Code zu scannen.',
	'Validation code': 'Bestätigungscode',
	'Validation token': 'Bestätigungstoken',
	'Enter the 6 digit code.': 'Geben Sie den 6-stelligen Code ein.',
	'Recovery tokens': 'Wiederherstellungstoken',
	'For security reasons, we need you to do your two-factor validation':
		'Aus Sicherheitsgründen müssen Sie Ihre Zwei-Faktor-Authentifizierung durchführen.',
	'You are about to generate your two-factor authentication via the app method. Make sure you are close to your cell phone, with the authentication app open.':
		'Sie sind dabei, Ihre Zwei-Faktor-Authentifizierung über die App-Methode zu generieren. Stellen Sie sicher, dass Sie sich in der Nähe Ihres Mobiltelefons befinden und die Authentifizierungs-App geöffnet ist.',
	'You are about to generate your two-factor authentication via email method. Make sure you have your inbox open.':
		'Sie sind dabei, Ihre Zwei-Faktor-Authentifizierung über die E-Mail-Methode zu generieren. Stellen Sie sicher, dass Sie Ihren Posteingang geöffnet haben.',
	'If you have not received your code via email, you can try again by clicking':
		'Wenn Sie Ihren Code per E-Mail nicht erhalten haben, können Sie hier erneut versuchen zu klicken.',
	here: 'hier',
	'Unable to generate token': 'Token konnte nicht generiert werden.',
	"Here are your recovery codes, which will be requested should you need to recover your account. Keep in mind that they will not be accessible again after this moment, so it's crucial that you save them securely.":
		'Hier sind Ihre Wiederherstellungscodes, die angefordert werden, wenn Sie Ihr Konto wiederherstellen müssen. Beachten Sie, dass sie nach diesem Moment nicht mehr zugänglich sein werden, daher ist es wichtig, dass Sie sie sicher speichern.',
	"Can't close yet. Read the instructions.": 'Noch nicht schließen. Lesen Sie die Anweisungen.',
	"Your company has asked for all users to keep two-factor authentication enabled for security reasons. You'll be asked to activate it again on your next login. Want to deactivate it anyway?":
		'Ihr Unternehmen hat aus Sicherheitsgründen darum gebeten, dass alle Benutzer die Zwei-Faktor-Authentifizierung aktiviert lassen. Sie werden bei Ihrem nächsten Login erneut aufgefordert, sie zu aktivieren. Möchten Sie sie trotzdem deaktivieren?',
	Change: 'Ändern',
	Security: 'Sicherheit',
	'Account security settings': 'Kontosicherheitseinstellungen',
	'Account information summary': 'Zusammenfassung der Kontoinformationen',
	'Password must be at least 8 characters long': 'Das Passwort muss mindestens 8 Zeichen lang sein.',
	'The password must have a maximum of 20 characters': 'Das Passwort darf maximal 20 Zeichen haben.',
	'The password field is required': 'Das Passwortfeld ist erforderlich.',
	'Password field cannot be empty': 'Das Passwortfeld darf nicht leer sein.',
	'The password must contain capital letters': 'Das Passwort muss Großbuchstaben enthalten.',
	'The password must contain lowercase letters': 'Das Passwort muss Kleinbuchstaben enthalten.',
	'Password must contain numbers': 'Das Passwort muss Zahlen enthalten.',
	'The password must contain at least one special character':
		'Das Passwort muss mindestens ein Sonderzeichen enthalten.',
	'Manage industrial site': 'Industriestandort verwalten',
	'Update company name': 'Firmennamen aktualisieren',
	'Delete company': 'Firma löschen',
	'Total Industrial Sites': 'Gesamtzahl der Industriestandorte',
	'Create workstation': 'Arbeitsstation erstellen',
	'Workstation name': 'Arbeitsstationsname',
	'Create sector': 'Sektor erstellen',
	'Sector name': 'Sektorenname',
	'Edit industrial site': 'Industriestandort bearbeiten',
	'All data will be deleted, along with their respective uploads, reports and contents. Do you wish to proceed?':
		'Alle Daten werden zusammen mit ihren jeweiligen Uploads, Berichten und Inhalten gelöscht. Möchten Sie fortfahren?',
	'All companies in this organization will be deleted, along with their respective uploads and reports. Do you wish to proceed?':
		'Alle Unternehmen in dieser Organisation werden zusammen mit ihren jeweiligen Uploads und Berichten gelöscht. Möchten Sie fortfahren?',
	'Edit sector': 'Sektor bearbeiten',
	'Select a workstation': 'Wählen Sie eine Arbeitsstation aus.',
	'Please, select an industrial site': 'Bitte wählen Sie einen Industriestandort aus.',
	'Please, select a sector': 'Bitte wählen Sie einen Sektor aus.',
	'Please, select a workstation': 'Bitte wählen Sie eine Arbeitsstation aus.',
	'Edit workstation': 'Arbeitsstation bearbeiten',
	'You will create a new workstation with same name. Do you wish to proceed ?':
		'Sie werden eine neue Arbeitsstation mit demselben Namen erstellen. Möchten Sie fortfahren?',
	'You will create a new company with same name. Do you wish to proceed ?':
		'Sie werden eine neue Firma mit demselben Namen erstellen. Möchten Sie fortfahren?',
	'You will create a new sector with same name. Do you wish to proceed ?':
		'Sie werden einen neuen Sektor mit demselben Namen erstellen. Möchten Sie fortfahren?',
	'This name already exists. Do you wish to proceed ?': 'Dieser Name existiert bereits. Möchten Sie fortfahren?',
	'Create new company': 'Neue Firma erstellen',
	'You will create a new organization with same name. Do you wish to proceed ?':
		'Sie werden eine neue Organisation mit demselben Namen erstellen. Möchten Sie fortfahren?',
	'Failed to update the report': 'Fehler beim Aktualisieren des Berichts.',
	'Failed to create the report': 'Fehler beim Erstellen des Berichts.',
	'Analyzed person': 'Analyseperson',
	Person: 'Person',
	Persons: 'Personen',
	'Identify people to be analyzed': 'Identifizieren Sie Personen, die analysiert werden sollen.',
	'No person has been identified with more than 50% of accuracy.':
		'Es wurde keine Person mit einer Genauigkeit von mehr als 50% identifiziert.',
	'Please, record the video following the guidelines': 'Bitte nehmen Sie das Video gemäß den Richtlinien auf.',
	Files: 'Dateien',
	'Select a desired new method and enter one of the recovery tokens to perform the recovery':
		'Wählen Sie eine gewünschte neue Methode aus und geben Sie einen der Wiederherstellungscodes ein, um die Wiederherstellung durchzuführen.',
	'For security reasons, we need you to enable your two-factor validation':
		'Aus Sicherheitsgründen müssen Sie Ihre Zwei-Faktor-Validierung aktivieren.',
	'Your company is requesting that all users have two-factor authentication enabled. Proceed to activate yours and login.':
		'Ihr Unternehmen fordert Sie auf, die Zwei-Faktor-Authentifizierung zu aktivieren. Gehen Sie weiter, um Ihre zu aktivieren und sich anzumelden.',
	Return: 'Zurück',
	'Continue with Microsoft SSO': 'Weiter mit Microsoft SSO',
	'General informations': 'Allgemeine Informationen',
	'Material Handling': 'Materialhandling',
	'Material handling': 'Materialhandling',
	'Invalid request data': 'Ungültige Anforderungsdaten',
	'Liberty Mutual report created successfully!': 'Liberty Mutual-Bericht erfolgreich erstellt!',
	'Liberty Mutual report updated successfully!': 'Liberty Mutual-Bericht erfolgreich aktualisiert!',
	'Liberty Mutual task not found': 'Liberty Mutual-Aufgabe nicht gefunden',
	'System of units not found': 'Einheitensystem nicht gefunden',
	Lift: 'Heben',
	Lifts: 'Hebebühnen',
	lift: 'heben',
	lifts: 'Hebebühnen',
	Lower: 'Senken',
	Lowers: 'Senkbühnen',
	lowers: 'senken',
	lower: 'Senkbühnen',
	Push: 'Schieben',
	Pushes: 'Schiebebühnen',
	pushes: 'schieben',
	push: 'Schiebebühnen',
	Pull: 'Ziehen',
	Pulls: 'Ziehbühnen',
	pulls: 'ziehen',
	pull: 'Ziehbühnen',
	Carry: 'Tragen',
	Carries: 'Tragebühnen',
	carries: 'tragen',
	carry: 'Tragebühnen',
	'System of unit': 'Einheitensystem',
	'Select the system of unit': 'Wählen Sie das Einheitensystem aus.',
	'Select the movement of the task being evaluated': 'Wählen Sie die Bewegung der zu bewertenden Aufgabe aus.',
	'Insert the fields data for the selected task': 'Geben Sie die Felder für die ausgewählte Aufgabe ein.',
	'Start hand height': 'Anfangshöhe der Hand',
	'End hand height': 'Endhöhe der Hand',
	'Start hand distance': 'Anfangsentfernung der Hand',
	'End hand distance': 'Endentfernung der Hand',
	'Initial force': 'Anfangskraft',
	'Sustained force': 'Dauerhafte Kraft',
	'Horizontal distance': 'Horizontale Entfernung',
	'Vertical hand height': 'Vertikale Handhöhe',
	'Hand coupling': 'Handkupplung',
	'Object weight': 'Objektgewicht',
	Weight: 'Gewicht',
	'Select a hand coupling': 'Wählen Sie eine Handkupplung aus.',
	'Insert a start height': 'Geben Sie eine Starthöhe ein.',
	'Insert an end height': 'Geben Sie eine Endhöhe ein.',
	'Insert a start distance': 'Geben Sie eine Startentfernung ein.',
	'Insert an end distance': 'Geben Sie eine Endentfernung ein.',
	'Insert an object weight': 'Geben Sie das Gewicht des Objekts ein.',
	'Insert a frequency value': 'Geben Sie einen Frequenzwert ein.',
	'Select a frequency interval': 'Wählen Sie ein Frequenzintervall aus.',
	'Insert an initial force': 'Geben Sie eine Anfangskraft ein.',
	'Insert a sustained force': 'Geben Sie eine dauerhafte Kraft ein.',
	Initial: 'Anfang',
	Sustain: 'Dauerhaft',
	'Insert a horizontal distance': 'Geben Sie eine horizontale Entfernung ein.',
	'Insert a vertical hand height': 'Geben Sie eine vertikale Handhöhe ein.',
	'Select a task': 'Wählen Sie eine Aufgabe aus.',
	'per hour': 'pro Stunde',
	hour: 'Stunde',
	'per minute': 'pro Minute',
	per: 'pro',
	'Video duration': 'Videodauer',
	'Assessment date': 'Bewertungsdatum',
	'Population percentile': 'Bevölkerungs-Perzentil',
	'Activity is safe for': 'Die Aktivität ist sicher für',
	'Initial state': 'Anfangszustand',
	'End state': 'Endzustand',
	Task: 'Aufgabe',
	Metric: 'Metrisch',
	Imperial: 'Imperial',
	'Data entered for Start hand height and End hand height indicates that the task type is Lift, not Lower':
		'Die eingegebenen Daten für Starthöhe und Endhöhe geben an, dass es sich um eine Hebeaufgabe handelt, nicht um eine Senkaufgabe.',
	'Data entered for Start hand height and End hand height indicates that the task type is Lower, not Lift':
		'Die eingegebenen Daten für Starthöhe und Endhöhe geben an, dass es sich um eine Senkaufgabe handelt, nicht um eine Hebeaufgabe.',
	'Built-in user groups': 'Eingebaute Benutzergruppen',
	'Connect your Microsoft Azure AD users': 'Verbinden Sie Ihre Microsoft Azure AD-Benutzer',
	'Invitation sent': 'Einladung gesendet',
	'Microsoft Azure Active Directory - Users': 'Microsoft Azure Active Directory - Benutzer',
	'Failed to load user groups': 'Fehler beim Laden der Benutzergruppen',
	'User removed successfully!': 'Benutzer erfolgreich entfernt!',
	'Failed to remove user': 'Fehler beim Entfernen des Benutzers',
	'Do you want to remove the user': 'Möchten Sie den Benutzer entfernen?',
	'Failed to update user': 'Fehler beim Aktualisieren des Benutzers',
	'Failed to load information': 'Fehler beim Laden der Informationen',
	'Investigate and implement change': 'Untersuchen und Änderungen umsetzen',
	'Implement changes': 'Änderungen umsetzen',
	Attention: 'Achtung',
	'Upload logos': 'Logos hochladen',
	'Use the same of the company': 'Verwenden Sie das Logo des Unternehmens',
	'.jpeg or .png up to 5MB': '.jpeg oder .png bis zu 5 MB',
	'Upload files': 'Dateien hochladen',
	'Upload your company logo': 'Laden Sie Ihr Firmenlogo hoch',
	'Upload your industrial site logo': 'Laden Sie Ihr Industriestandortlogo hoch',
	'Add a image': 'Ein Bild hinzufügen',
	'Image successfully added': 'Bild erfolgreich hinzugefügt',
	'Image successfully changed': 'Bild erfolgreich geändert',
	'Uploaded picture': 'Hochgeladenes Bild',
	'Changed picture': 'Geändertes Bild',
	'Edit picture': 'Bild bearbeiten',
	Reset: 'Zurücksetzen',
	'Track the minutes used on the platform during the desired period':
		'Verfolgen Sie die während des gewünschten Zeitraums auf der Plattform verbrauchten Minuten.',
	'See more details': 'Weitere Details anzeigen',
	'Please select one or more': 'Bitte wählen Sie eine oder mehrere Optionen aus.',
	'The end date must be later than the start date': 'Das Enddatum muss größer als das Startdatum sein.',
	Granularity: 'Granularität',
	'Select a granularity': 'Wählen Sie eine Granularität aus.',
	'Select a date': 'Wählen Sie ein Datum aus.',
	'Minutes used': 'Verbrauchte Minuten',
	Start: 'Start',
	End: 'Ende',
	Users: 'Benutzer',
	'Failed to search for companies': 'Fehler beim Suchen nach Unternehmen',
	'Error in searching for user information': 'Fehler bei der Suche nach Benutzerinformationen',
	'To Do': 'Zu erledigen',
	Doing: 'In Bearbeitung',
	Done: 'Erledigt',
	Status: 'Status',
	'Remember me': 'Erinnere dich an mich',
	'Add item': 'Element hinzufügen',
	'Type of force exertion in the finger/hand (left | right)': 'Art der Kraftausübung im Finger/Hand (links | rechts)',
	Password: 'Passwort',
	Descending: 'Absteigend',
	Integrations: 'Integrationen',
	'Order by worst score': 'Nach schlechtester Bewertung sortieren',
	'Order by deadline': 'Nach Frist sortieren',
	'Select EWA': 'Wählen Sie EWA aus',
	'Workstation score': 'Bewertung der Arbeitsstation',
	Responsibility: 'Verantwortung',
	'average (seconds holding / minute)': 'Durchschnitt (Sekunden halten / Minute)',
	'average (movements / minute)': 'Durchschnitt (Bewegungen / Minute)',
	'Personalised reports': 'Personalisierte Berichte',
	'Personalised report': 'Personalisierter Bericht',
	'Created by': 'Erstellt von',
	'Edit report': 'Bericht bearbeiten',
	'Created date': 'Erstellungsdatum',
	'New report': 'Neuer Bericht',
	'Manage report': 'Bericht verwalten',
	Forward: 'Weiterleiten',
	'Require video': 'Video erforderlich',
	Evaluator: 'Bewerter',
	'Require evaluator': 'Bewerter erforderlich',
	'Evaluator created successfully': 'Bewerter erfolgreich erstellt',
	'Select an evaluator': 'Wählen Sie einen Bewerter aus',
	'Require work condition': 'Arbeitsbedingungen erforderlich',
	'Require characteristics': 'Eigenschaften erforderlich',
	Acronym: 'Akronym',
	'Insert an acronym': 'Geben Sie ein Akronym ein',
	'Risk scale': 'Risikoskala',
	'Insert a risk scale': 'Geben Sie eine Risikoskala ein',
	'Insert a report name': 'Geben Sie einen Berichtsnamen ein',
	'Recommended sections': 'Empfohlene Abschnitte',
	'Create section': 'Abschnitt erstellen',
	'Section title': 'Abschnittstitel',
	'Search by section name...': 'Nach Abschnittsnamen suchen...',
	'No sections selected': 'Keine Abschnitte ausgewählt',
	'No more sections available': 'Keine weiteren Abschnitte verfügbar',
	'No sections available': 'Keine Abschnitte verfügbar',
	'Section created successfully': 'Abschnitt erfolgreich erstellt',
	'Section has already been added': 'Abschnitt wurde bereits hinzugefügt',
	'Already created section': 'Bereits erstellter Abschnitt',
	'Selected sections': 'Ausgewählte Abschnitte',
	'Recommended criteria': 'Empfohlene Kriterien',
	'Create criterion': 'Kriterium erstellen',
	'Criterion title': 'Kriterientitel',
	'Search by criterion name...': 'Nach Kriteriennamen suchen...',
	'No criteria selected': 'Keine Kriterien ausgewählt',
	'No more criteria available': 'Keine weiteren Kriterien verfügbar',
	'No criteria available': 'Keine Kriterien verfügbar',
	'Criterion created successfully': 'Kriterium erfolgreich erstellt',
	'Criterion has already been added': 'Das Kriterium wurde bereits hinzugefügt',
	'Already created criterion': 'Bereits erstelltes Kriterium',
	'Selected criteria': 'Ausgewählte Kriterien',
	'Section not found': 'Abschnitt nicht gefunden',
	'Failed to create section': 'Fehler beim Erstellen des Abschnitts',
	'Failed to update section': 'Fehler beim Aktualisieren des Abschnitts',
	'Criterion not found': 'Kriterium nicht gefunden',
	'Failed to create criterion': 'Fehler beim Erstellen des Kriteriums',
	'Failed to update criterion': 'Fehler beim Aktualisieren des Kriteriums',
	'Indicate possible injuries/aggravations': 'Mögliche Verletzungen/Verschlimmerungen angeben',
	'Link tool to analysis': 'Werkzeug mit Analyse verknüpfen',
	'Sections and criteria': 'Abschnitte und Kriterien',
	'No tool selected': 'Kein Werkzeug ausgewählt',
	'Selected tools': 'Ausgewählte Werkzeuge',
	'Do not indicate possible injuries/aggravations': 'Keine möglichen Verletzungen/Verschlimmerungen angeben',
	'Incomplete section': 'Unvollständiger Abschnitt',
	'Custom report created successfully': 'Benutzerdefinierter Bericht erfolgreich erstellt',
	'Custom report updated successfully': 'Benutzerdefinierter Bericht erfolgreich aktualisiert',
	'Report downloaded': 'Bericht heruntergeladen',
	'Analysis downloaded successfully': 'Analyse erfolgreich heruntergeladen',
	'Want to delete this criterion?': 'Möchten Sie dieses Kriterium löschen?',
	'Want to delete this criterion? Deleting it will remove the section as well!':
		'Möchten Sie dieses Kriterium löschen? Das Löschen entfernt auch den Abschnitt!',
	"Kinebot's standard prioritisation matrix": 'Kinebot-Standard priorisierungsmatrix',
	'3 level prioritisation matrix': 'Priorisierungsmatrix mit 3 Ebenen',
	'6 level prioritisation matrix': 'Priorisierungsmatrix mit 6 Ebenen',
	'Select the restrictions perceived in the evaluated characteristics':
		'Wählen Sie die wahrgenommenen Einschränkungen bei den bewerteten Merkmalen aus',
	'Particularities / Production / Working regime': 'Besonderheiten / Produktion / Arbeitszeitregelung',
	'Select the restrictions perceived in the evaluated working condition':
		'Wählen Sie die wahrgenommenen Einschränkungen bei den bewerteten Arbeitsbedingungen aus',
	'Insert work schedule': 'Arbeitsplan einfügen',
	'Work hours must be greater than 1': 'Die Arbeitsstunden müssen größer als 1 sein.',
	'Unauthorized requisition': 'Nicht autorisierte Anforderung',
	'Authorization header is missing': 'Authentifizierungsheader fehlt',
	'Failed to query the database': 'Fehler bei der Datenbankabfrage',
	'Calculation per second not found': 'Berechnung pro Sekunde nicht gefunden',
	'Business information not found': 'Unternehmensinformationen nicht gefunden',
	'Custom report not found': 'Benutzerdefinierter Bericht nicht gefunden',
	'Already created custom report': 'Bereits erstellter benutzerdefinierter Bericht',
	'Failed to create custom report': 'Fehler beim Erstellen des benutzerdefinierten Berichts',
	'Custom report result not found': 'Ergebnis des benutzerdefinierten Berichts nicht gefunden',
	'Already created custom report result': 'Bereits erstelltes Ergebnis des benutzerdefinierten Berichts',
	'Failed to create custom report result': 'Fehler beim Erstellen des Ergebnisses des benutzerdefinierten Berichts',
	'Work condition not found': 'Arbeitsbedingung nicht gefunden.',
	'Already created work condition': 'Bereits erstellte Arbeitsbedingung.',
	'Failed to create work condition': 'Fehler beim Erstellen der Arbeitsbedingung.',
	'Characteristic not found': 'Merkmal nicht gefunden.',
	'Already created characteristic': 'Bereits erstelltes Merkmal.',
	'Failed to create characteristic': 'Fehler beim Erstellen des Merkmals.',
	'Criteria result not found': 'Kriterienergebnis nicht gefunden.',
	'Already created criteria result': 'Bereits erstelltes Kriterienergebnis.',
	'Failed to create criteria result': 'Fehler beim Erstellen des Kriterienergebnisses.',
	'Failed to update criteria result': 'Fehler beim Aktualisieren des Kriterienergebnisses.',
	'Criteria injury not found': 'Kriterienverletzung nicht gefunden.',
	'Already created criteria injury': 'Bereits erstellte Kriterienverletzung.',
	'Failed to create criteria injury': 'Fehler beim Erstellen der Kriterienverletzung.',
	'Custom report score scale not found': 'Skala für benutzerdefinierten Bericht nicht gefunden.',
	'Worker self evaluation not found': 'Selbstbewertung des Arbeitnehmers nicht gefunden.',
	'Custom report setting not found': 'Einstellungen für benutzerdefinierten Bericht nicht gefunden.',
	'Custom report step setting not found':
		'Einstellungen für den Schritt des benutzerdefinierten Berichts nicht gefunden.',
	'Criteria settings not found': 'Kriterieneinstellungen nicht gefunden',
	'Step key ergonomic tool not found': 'Schlüsselergonomisches Werkzeug für den Schritt nicht gefunden',
	'Failed to create step key ergonomic tool':
		'Fehler beim Erstellen des Schlüsselergonomischen Werkzeugs für den Schritt',
	'Failed to update step key ergonomic tool':
		'Fehler beim Aktualisieren des Schlüsselergonomischen Werkzeugs für den Schritt',
	'Evaluator not found': 'Bewerter nicht gefunden',
	'Already created evaluator': 'Bereits erstellter Bewerter',
	'Ergonomic tool not found': 'Ergonomisches Werkzeug nicht gefunden',
	'Ergonomic tool category not found': 'Kategorie des ergonomischen Werkzeugs nicht gefunden',
	'Action plan not found': 'Aktionsplan nicht gefunden',
	'Failed to create an history': 'Fehler beim Erstellen der Historie',
	'Already created action plan': 'Aktionsplan bereits erstellt',
	'Action plan task not found': 'Aktionsplanaufgabe nicht gefunden',
	'Action plan history not found': 'Historie des Aktionsplans nicht gefunden',
	'Preliminary analysis not found': 'Vorläufige Analyse nicht gefunden',
	'Analysis step not found': 'Analyse-Schritt nicht gefunden.',
	'Default step not found': 'Standard-Schritt nicht gefunden.',
	'Probabilty not found': 'Wahrscheinlichkeit nicht gefunden.',
	'Injury not found': 'Verletzung nicht gefunden.',
	'Consequence not found': 'Konsequenz nicht gefunden.',
	'Exposure not found': 'Exposition nicht gefunden.',
	'Range risk not found': 'Bereichsrisiko nicht gefunden.',
	'Failed to fetch risk tracks': 'Fehler beim Abrufen der Risikospuren.',
	'Organization not found': 'Organisation nicht gefunden.',
	'Organization still has registered companies': 'Die Organisation hat noch registrierte Unternehmen.',
	'Failed to create an organization': 'Fehler beim Erstellen einer Organisation.',
	'Company not found': 'Unternehmen nicht gefunden.',
	'Failed to create company': 'Fehler beim Erstellen des Unternehmens.',
	'Sector not found': 'Sektor nicht gefunden.',
	'Workstation not found': 'Arbeitsstation nicht gefunden.',
	'Corrupted video': 'Beschädigtes Video.',
	'File not found': 'Datei nicht gefunden.',
	'Parameters not found': 'Parameter nicht gefunden.',
	'Failed to read sent file': 'Fehler beim Lesen der gesendeten Datei.',
	'Failed to save file': 'Fehler beim Speichern der Datei.',
	'Failed to create download url': 'Fehler beim Erstellen der Download-URL.',
	'File already queued': 'Datei bereits in der Warteschlange.',
	'File already processed': 'Datei bereits verarbeitet.',
	'Failed to save file information': 'Fehler beim Speichern der Dateiinformationen.',
	'Already registered user': 'Bereits registrierter Benutzer.',
	'Failed to create customer plan': 'Fehler beim Erstellen des Kundenplans.',
	'Failed to create customer transaction': 'Fehler beim Erstellen der Kunden-Transaktion.',
	'Customer transaction not found': 'Kunden-Transaktion nicht gefunden.',
	'Customer transaction already completed': 'Kunden-Transaktion bereits abgeschlossen.',
	'Product not found': 'Produkt nicht gefunden.',
	'Failed to create customer credits': 'Fehler beim Erstellen von Kunden-Credits.',
	'Plan not found': 'Plan nicht gefunden.',
	'Processed minutes limit reached': 'Das Limit für verarbeitete Minuten wurde erreicht.',
	'Invalid event': 'Ungültiges Ereignis.',
	'Not authorized': 'Nicht autorisiert.',
	'Failed to create a user permission': 'Fehler beim Erstellen einer Benutzerberechtigung.',
	'Customer not found': 'Kunde nicht gefunden.',
	'Unregistered customer': 'Nicht registrierter Kunde.',
	'Already registered customer': 'Bereits registrierter Kunde.',
	'Failed to create customer': 'Fehler beim Erstellen des Kunden.',
	'Failed to update customer': 'Fehler beim Aktualisieren des Kunden.',
	'Report not found': 'Bericht nicht gefunden.',
	'Already created report': 'Bericht bereits erstellt.',
	'Failed to create report': 'Fehler beim Erstellen des Berichts.',
	'Failed to create report pdf': 'Fehler beim Erstellen des Berichts-PDFs.',
	'Failed to update report': 'Fehler beim Aktualisieren des Berichts.',
	'Group not found': 'Gruppe nicht gefunden.',
	'User group not found': 'Benutzergruppe nicht gefunden.',
	'Failed to upload file to storage': 'Fehler beim Hochladen der Datei in den Speicher.',
	'Failed to create file signature': 'Fehler beim Erstellen der Dateisignatur.',
	'Failed to download data': 'Fehler beim Herunterladen der Daten.',
	'Reply queue communication failure': 'Kommunikationsfehler in der Antwortwarteschlange.',
	'1% to 10% of the cycle': '1% bis 10% des Zyklus.',
	'11% to 20% of the cycle': '11% bis 20% des Zyklus.',
	'21% to 30% of the cycle': '21% bis 30% des Zyklus.',
	'31% to 50% of the cycle': '31% bis 50% des Zyklus.',
	'51% to 75% of the cycle': '51% bis 75% des Zyklus.',
	'76% to 100% of the cycle': '76% bis 100% des Zyklus.',
	'Physical or cognitive discomfort': 'Körperliche oder kognitive Beschwerden',
	'Muscle fatigue or mental overload': 'Muskelermüdung oder geistige Überlastung',
	'Acute pain': 'Akuter Schmerz',
	'Illness with partial loss of working capacity - without time off work':
		'Krankheit mit teilweisem Verlust des Arbeitsvermögens - ohne Arbeitsausfall',
	'Illness with total loss of working capacity - with time off work':
		'Krankheit mit vollständigem Verlust des Arbeitsvermögens - mit Arbeitsausfall',
	'Disability retirement': 'Erwerbsunfähigkeitsrente',
	'Illness - with leave': 'Krankheit - mit Arbeitsausfall',
	'Up to 25% of the cycle': 'Bis zu 25% des Zyklus',
	'26% to 75% of the cycle': '26% bis 75% des Zyklus',
	Unlikely: 'Unwahrscheinlich',
	Remote: 'Entfernt',
	Occasional: 'Gelegentlich',
	Likely: 'Wahrscheinlich',
	Frequent: 'Häufig',
	Certainly: 'Sicher',
	Total: 'Gesamtzahl',
	'Create/update tool': 'Werkzeug erstellen/aktualisieren',
	'Driveway conditions are not applied for the selected vehicle.':
		'Die Fahrwegsbedingungen gelten nicht für das ausgewählte Fahrzeug.',
	'Your organization uses single sign-on (SSO). Use your corporate email to access Kinebot.':
		'Ihre Organisation verwendet Single Sign-On (SSO). Verwenden Sie Ihre Firmen-E-Mail, um auf Kinebot zuzugreifen.',
	'Sign in without SSO': 'Anmeldung ohne SSO',
	'Enter a valid email': 'Geben Sie eine gültige E-Mail-Adresse ein.',
	'Enter your email': 'Geben Sie Ihre E-Mail-Adresse ein.',
	'Work email': 'Geschäftliche E-Mail',
	'Oops! Something happened.': 'Hoppla! Etwas ist schiefgelaufen.',
	'Failed to fetch information': 'Fehler beim Abrufen von Informationen',
	'Continue with SSO': 'Mit SSO fortfahren',
	'Return to login without SSO': 'Zurück zur Anmeldung ohne SSO',
	'You are being redirected': 'Sie werden weitergeleitet.',
	'User not associated with an SSO account': 'Benutzer nicht mit einem SSO-Konto verknüpft',
	'Fail get user info': 'Fehler beim Abrufen von Benutzerinformationen',
	'The server encountered an unexpected situation.': 'Der Server hat eine unerwartete Situation festgestellt.',
	'Please enter a valid email address.': 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
	'Occasional deviations from good “neutral” head posture/movement':
		'Gelegentliche Abweichungen von guter "neutraler" Kopfhaltung/Bewegung',
	'Constant deviations from good “neutral” head posture/movement':
		'Konstante Abweichungen von guter "neutraler" Kopfhaltung/Bewegung',
	'Start date': 'Startdatum',
	'End date': 'Endtermin',
	Download: 'Herunterladen',
	'Strain index': 'Belastungsindex',
	'None: there are no unfavorable properties of the transport devices':
		'Keine: Es liegen keine ungünstigen Eigenschaften der Transportgeräte vor',
	'None: there are no unfavorable working conditions': 'Keine: Es liegen keine ungünstigen Arbeitsbedingungen vor',
	Carriage: 'Wagen',
	Barrow: 'Schubkarre',
	Conveyor: 'Förderband',
	Crane: 'Kran',

	Activate: 'Aktivieren',
	Deactivate: 'Deaktivieren',
	Edit: 'Bearbeiten',
	'User status updated successfully': 'Benutzerstatus erfolgreich aktualisiert',
	'Failed to update user status': 'Aktualisierung des Benutzerstatus fehlgeschlagen',
	'Heads up! Want to disable this user?': 'Aufmerksamkeit! Möchten Sie diesen Benutzer deaktivieren?',
	'Heads up! Want to enable this user?': 'Aufmerksamkeit! Möchten Sie diesen Benutzer aktivieren?',
	'in the year': 'im Jahr',
	'in the month': 'im Monat',
	'Minutes used (Limit)': 'Verwendete Minuten (Limit)',
	'Uploaded videos (Limit)': 'Hochgeladene Videos (Limit)',
	'Processing limit reached': 'Verarbeitungslimit erreicht',

	'Failed to remove file': 'Datei konnte nicht entfernt werden',
	'Activity created successfully': 'Aktivität erfolgreich erstellt',
	Activities: 'Aktivitäten',
	Reporting: 'Berichterstattung',
	List: 'Aufführen',
	to: 'bis',
	until: 'bis',
	'Activity name': 'Aktivitätsname',
	'Download report': 'Bericht herunterladen',
	'Download video': 'Video herunterladen',
	'File name': 'Dateiname',
	'Select an activity': 'Wählen Sie eine Aktivität',
	'Workstation created successfully': 'Arbeitsstation erfolgreich erstellt',
	'Preliminary Ergonomic Analysis': 'Vorläufige Ergonomische Analyse',
	'Select the risk range': 'Wählen Sie den Risikobereich',
	'Enter the activity': 'Geben Sie die Aktivität ein',
	'Select the evaluator': 'Wählen Sie den Bewerter',
	Filename: 'Dateiname',
	'Task already exist': 'Aufgabe bereits vorhanden',
	'Task not created': 'Aufgabe nicht erstellt',

	// OWAS
	'Highest score': 'Höchste Punktzahl',
	'Analysis date': 'Datum der Analyse',
	'Activity description': 'Beschreibung der Tätigkeit',
	productivity: 'produktivität',
	'Number of exposed': 'Anzahl der exponierten',
	'Capture interval': 'Erfassungsintervall',
	'in seconds': 'in sekunden',
	'Enter the report name': 'Geben Sie den Berichtsnamen ein',
	'Enter the sector': 'Betreten Sie den Sektor',
	'Enter the evaluator': 'Geben Sie den Bewerter ein',
	'Enter the analysis date': 'Geben Sie das Analysedatum ein',
	'Enter the activity description': 'Geben Sie die Beschreibung der Aktivität ein',
	'Number of exposed must be greater than 0': 'Anzahl der Exponierten muss größer als 0 sein',
	'Enter the capture interval': 'Geben Sie das Erfassungsintervall ein',

	'Set the time interval, in seconds, for capturing video frames.':
		'Legen Sie das Zeitintervall in Sekunden für die Aufnahme von Videobildern fest.',
	'Report consolidated successfully': 'Report konsolidiert erfolgreich',
	'Report deconsolidated successfully': 'Report dekonsolidiert erfolgreich',
	'Report consolidated but PDF not generated': 'Report konsolidiert, aber PDF nicht generiert',

	Evaluation: 'Bewertung',
	'Computer vision identified the positions of each task, review and add the required information':
		'Computer Vision identifiziert die Positionen der einzelnen Aufgaben, überprüft und ergänzt die erforderlichen Informationen',
	'Search for a task': 'Suche nach einer Aufgabe',
	'All tasks': 'Alle Aufgaben',
	'Tasks not analyzed': 'Nicht analysierte Aufgaben',
	'Task name': 'Name der Aufgabe',
	'Manual tools': 'Manuelle Werkzeuge',
	'Upper limbs': 'Obere Gliedmaßen',
	'Lower limbs': 'Untere Gliedmaßen',
	'Overall risk of the task': 'Gesamtrisiko der Aufgabe',
	'The colors shown do not refer to the OWAS tool.':
		'Die dargestellten Farben beziehen sich nicht auf das OWAS-Tool.',
	'Computer vision does not detect back twisting.': 'Computervision erkennt keine Rückgewinnung.',
	Straight: 'Geradlinig',
	Bent: 'Gebogen',
	'Straight and rotated': 'Gerade und gedreht',
	'Bent and rotated': 'Gebogen und gedreht',
	'Two arms down': 'Zwei Arme senken',
	'One arm up': 'Eine Arme hoch',
	'Two arms up': 'Zwei Arme hoch',
	Sitting: 'Sitzen',
	'Standing on two straight legs': 'Stehen auf zwei geraden Beinen',
	'Standing on one straight legs': 'Stehen auf einem geraden Bein',
	'Standing or squatting on two flexed legs': 'Stehen oder Hocken auf zwei gebeugten Beinen',
	'Standing or squatting on one bent leg': 'Stehen oder Hocken auf einem gebeugten Bein',
	Kneeling: 'Knieben',
	Walking: 'Gehen',
	'Less than 10kg': 'Weniger als 10 kg',
	'Between 10kg and 20kg': 'Zwischen 10 kg und 20 kg',
	'More than 20kg': 'Mehr als 20 kg',
	'Select the task name': 'Wählen Sie den Aufgabennamen',
	'Enter the manual tools': 'Geben Sie die manuellen Werkzeuge ein',

	'Category value not found': 'Kategoriewert nicht gefunden',
	'Failed to update frame': 'Fehler beim Aktualisieren des Frames',
	'Frames not created': 'Fehler beim Erstellen von Frames',
	'Frame not found': 'Frame nicht gefunden',
	'Frame not updated': 'Frame nicht aktualisiert',
	'Frame updated successfully': 'Frame erfolgreich aktualisiert',
	'There is frames not evaluated': 'Es gibt Frames, die nicht ausgewertet wurden',
	'Perform the evaluation of the tables': 'Die Auswertung der Tabellen durchführen',
	'Failed to create a new task': 'Fehler beim Erstellen einer neuen Aufgabe',
	'Capture interval should be less than the file duration':
		'Das Erfassungsintervall muss kleiner sein als die Dauer des Videos',

	// Dashboard
	Evaluated: 'Ausgewertet',
	'Not evaluated': 'Nicht ausgewertet',
	'Highest risk found': 'Höchstes Risiko gefunden',
	'Predetermined time frame': 'Vorbestimmter Zeitrahmen',
	'Select the time frame': 'Zeitrahmen auswählen',
	'Select the checklist': 'Checkliste auswählen',
	'Previous period': 'Vorheriger Zeitraum',
	'The period will only be applied to cards that include these variables.':
		'Der Zeitraum wird nur auf Karten angewendet, die diese Variablen enthalten.',
	'Reference checklist': 'Referenz-Checkliste',
	'Reference period': 'Referenzzeitraum',
	'Comparison period': 'Vergleichszeitraum',
	'Current month': 'Aktueller Monat',
	'Current quarter': 'Aktuelles Quartal',
	'Current semester': 'Aktuelles Semester',
	'Current year': 'Aktuelles Jahr',
	'Previous month': 'Vorheriger Monat',
	'Previous quarter': 'Vorheriges Quartal',
	'Previous semester': 'Vorheriges Semester',
	'Previous year': 'Vorheriges Jahr',
	'Comparison of highest risk found between': 'Vergleich des höchsten Risikos gefunden zwischen',
	'Industrial Sites': 'Industriestandorte',
	quarter: 'Quartal',
	semester: 'Semester',
	Jan: 'Jan',
	Feb: 'Feb',
	Apr: 'Apr',
	May: 'Mai',
	Jun: 'Jun',
	Jul: 'Jul',
	Aug: 'Aug',
	Sep: 'Sep',
	Oct: 'Okt',
	Nov: 'Nov',
	Dec: 'Dez',
	D: 'T',
	W: 'W',
	M: 'M',
	'3M': '3M',
	'6M': '6M',
	Y: 'J',
	'Generated reports': 'Generierte Berichte',
	'Number of reports': 'Anzahl der Berichte',
	'Left side': 'Linker Seite',
	'Right side': 'Rechter Seite',
	'Exposure by body side': 'Exposition durch Körperseite',
	'Exposure Score for all body segments': 'Score der Exposition für alle Körpersegmente',
	'Revised Strain Index ≤10': 'Überarbeiteter Belastungsindex ≤10',
	'Revised Strain Index >10': 'Überarbeiteter Belastungsindex >10',
	'Most frequent movement': 'Häufigster Bewegungstyp',
	'Movement frequency': 'Bewegungshäufigkeit',
	'Incidence of risk': 'Inzidenz des Risikos',
	'Evolution of the highest risk found': 'Entwicklung des höchsten Risikos gefunden',
	'Action plan status': 'Status der Aktionspläne',
	'No data available.': 'Keine Daten verfügbar.',
	'Number of actions': 'Anzahl der Aktionen',
	Late: 'Verspätet',
	'Not started': 'Nicht gestartet',
	'This page was not found.': 'Diese Seite wurde nicht gefunden.',
	'Try Again': 'Erneut versuchen',
	'Select the video files for the operations that make up the workstation below':
		'Wählen Sie die Videodateien für die Operationen, die das Arbeitsplatz zusammenstellen',
	Goals: 'Ziele',
	'Indicate the minimum acceptable exposure levels for the rotation':
		'Indikieren Sie die minimal akzeptable Expositionsniveaus für die Rotation',
	'Minimum acceptable exposure level per body part': 'Minimal akzeptables Expositionsniveau pro Körperteil',
	'Cervical spine': 'Hals',
	'Evaluation of the rotation': 'Bewertung der Rotation',
	'Exposure of selected activities': 'Exposition der ausgewählten Aktivitäten',
	'Result of the cycle': 'Ergebnis des Zyklus',
	Operation: 'Aufgabe',
	'Operation time': 'Aufgabenzeit',
	Sequence: 'Sequenz',
	'Overall exposure': 'Gesamtexposition',
	Rotation: 'Rotation',
	'Pause Identification': 'Pauseidentifizierung',
	'Time (minutes)': 'Zeit (Minuten)',
	Break: 'Pause',
	'Provide a description': 'Beschreibung bereitstellen',
	'Provide the time': 'Zeit bereitstellen',
	'Select the operation': 'Wählen Sie die Aufgabe',
	Required: 'Erforderlich',
	'Heads up! Want to delete this operation?': 'Aufmerksamkeit! Möchten Sie diese Aufgabe löschen?',
	'Activity updated successfully': 'Aktivität erfolgreich aktualisiert',
	'Activity deleted successfully': 'Aktivität erfolgreich gelöscht',
	'Report does not have activities': 'Bericht hat keine Aktivitäten',
	'Overall results of the rotation': 'Gesamtergebnisse der Rotation',
	'Operations and videos': 'Aufgaben und Videos',
	'Total cycle time': 'Gesamtzykluszeit',
	'Minimum acceptable exposure': 'Minimal akzeptables Expositionsniveau',
	'Exposure found': 'Exposition gefunden',

	'Manual Tools': 'Manuelle Werkzeuge',
	'List of work organization elements': 'Liste der Arbeitsorganisationselemente',
	'Work organization factor': 'Arbeitsorganisationfaktor',
	'Tasks at the pace of the machine': 'Aufgaben in der Geschwindigkeit der Maschine',
	'Incentives are charged': 'Anreize werden erhoben',
	'Exists as routine, overtime': 'Existiert als Routinearbeit, Überstunden',
	'Strict control of working times': 'Strenge Kontrolle der Arbeitszeiten',
	'Limited decision-making possibilities': 'Beschränkte Entscheidungsfähigkeit',
	'Monotonous work': 'Monotone Arbeit',
	Shoulder: 'Schulter',
	'Individually evaluate the upper extremities': 'Ergonomische Analyse der oberen Extremitäten',
	'Flexion/extension': 'Beugung/Streckung',
	'Angle in time': 'Winkel im Verlauf der Zeit',
	'Extreme posture': 'Extremstellung',
	Speed: 'Geschwindigkeit',
	'Total duration of the activity': 'Gesamtdauer der Aktivität',
	Strength: 'Kraft',
	Abduction: 'Abduktion',
	Forearm: 'Vorarm',
	'Pronation, supination and rotation': 'Pronation, Supination und Rotation',
	Slow: 'Langsam',
	Static: 'Statisch',
	Fast: 'Schnell',
	'Supports part of the weight': 'Unterstützt Teil des Gewichts',
	'Apply force': 'Kraft anwenden',
	"Don't apply force": 'Kraft nicht anwenden',
	Partial: 'Teil',
	Neutral: 'Neutral',
	Complete: 'Voll',
	'Partial + straight fingers': 'Teil + gerade Finger',
	'Partial + flexed fingers': 'Teil + gebogene Finger',
	Grips: 'Griffe',
	'Flexion/extension or radial/ulnar deviation': 'Beugung/Streckung oder Radial-/Ulnarabweichung',
	'Tool pressure': 'Werkzeugdruck',
	Circular: 'Kreisförmig',
	Pinch: 'Zangengriff',
	Dynamic: 'Dynamisch',
	Infrequent: 'Selten',
	'On palm or wrist': 'Auf Handfläche oder Handgelenk',
	Fingers: 'Finger',
	Keystrokes: 'Tastenanschläge',
	'Two hands': 'Zwei Hände',
	'Flexion, extension, rotation': 'Beugung, Streckung, Drehung',
	'Risk Condition': 'Risikokondition',
	'Risk condition': 'Risikokondition',
	'Identify problems associated with tool use and exposure to vibration.':
		'Identifizieren Sie Probleme, die mit der Verwendung von Werkzeugen und der Exposition gegenüber Vibrationen verbunden sind.',
	'Vibration (Tool is pneumatic)': 'Vibration (Werkzeug ist pneumatisch)',
	'Uses tools that produce vibration': 'Verwendet Werkzeuge, die Vibrationen produzieren',
	'Total daily exposure': 'Gesamte tägliche Exposition',
	'Exhaustion (Directly in hand)': 'Ermüdung (Direkt in der Hand)',
	'Ambient temperature': 'Umgebungstemperatur',
	'Measurement (m/s²)': 'Messung (m/s²)',
	'Vibration (Electric motor or combustion engine)': 'Vibration (Elektromotor oder Verbrennungsmotor)',
	'Action Plan': 'Aktionen',
	'Action plans created': 'Aktionen erstellt',
	'Task description': 'Aufgabenbeschreibung',
	'Number of exhibits': 'Anzahl der Exponate',
	'Classification of risks': 'Risikoklassifizierung',
	'Grip type': 'Grippsart',
	'Trigger action': 'Aktivierung',
	'If the tool is electric motor or combustion engine':
		'Wenn das Werkzeug ein Elektromotor oder Verbrennungsmotor ist',
	'If the tool is pneumatic': 'Wenn das Werkzeug pneumatisch ist',
	'Part of the body': 'Teil des Körpers',
	Movement: 'Bewegung',
	shoulder: 'Hüfte',
	wrist: 'Handgelenk',
	forearm: 'Vorarm',
	grips: 'Gripps',
	fingers: 'Finger',
	vibration: 'Vibration',
	'* ANSI Z-365 Control of Work-Related Cumulative Trauma Disorder, Part 1: Upper Extremities, Working Draft, National safety Council-Secretariat, January 1, 1996.':
		'* ANSI Z-365 Kontrolle von Arbeitsbezogenen kumulativen Schädigungen, Teil 1: obere Extremitäten, Arbeitsentwurf, National Safety Council-Sekretariat, 1. Januar 1996.',
	'Select the': 'Wählen Sie die',
	'Input the': 'Geben Sie ein',
	Board: 'Planke',
	Table: 'Tisch',
	Notifications: 'Benachrichtigungen',
	Investment: 'Investition',
	Origin: 'Herkunft',
	'Action plan name': 'Name des Aktionsplans',
	'Expected cost of the action plan': 'Erwartete Kosten des Aktionsplans',
	'Categorization of the urgency of action plan resolution':
		'Kategorisierung der Dringlichkeit der Lösung des Aktionsplans',
	'Create an action plan': 'Erstellen Sie einen Aktionsplan',
	'Are you sure you want to delete the selected task?':
		'Sind Sie sicher, dass Sie die ausgewählte Aufgabe löschen möchten?',
	'Notification Control': 'Benachrichtigungssteuerung',
	'Follow up action plans created by me': 'Von mir erstellte Folgeaktionspläne',
	'Receive an e-mail when the status of the action plan changes':
		'Erhalten Sie eine E-Mail, wenn sich der Status des Aktionsplans ändert',
	'Receive e-mail when tasks are completed': 'Erhalten Sie eine E-Mail, wenn Aufgaben erledigt sind',
	'Follow up on action plans assigned to me': 'Verfolgen Sie die mir zugewiesenen Aktionspläne',
	'Receive an e-mail when a new action plan is assigned to me':
		'Erhalten Sie eine E-Mail, wenn mir ein neuer Aktionsplan zugewiesen wird',
	'Receive an e-mail when the due date is near': 'Erhalten Sie eine E-Mail, wenn das Fälligkeitsdatum näher rückt',
	'Creation date': 'Erstellungsdatum',
	'Due Date': 'Fälligkeitsdatum',
	Evidences: 'Beweise',
	'Date of Completion': 'Datum der Fertigstellung',
	Attachments: 'Anhänge',
	Progress: 'Fortschritt',
	Author: 'Autor',
	'Manage Columns': 'Spalten verwalten',
	'Priority editing': 'Vorrangige Bearbeitung',
	'Due date editing': 'Bearbeitung des Fälligkeitsdatums',
	'Responsible editing': 'Verantwortliche Redaktion',
	'Are you sure you want to delete the selected items?':
		'Sind Sie sicher, dass Sie die ausgewählten Elemente löschen möchten?',
	'Investment value': 'Investitionswert',
	'Failed to load attachments': 'Anhänge konnten nicht geladen werden',
	'Failed to load data': 'Daten konnten nicht geladen werden',
	'New task': 'Neue Aufgabe',
	'This page was not found': 'Diese Seite wurde nicht gefunden',
	'Try again': 'Versuchen Sie es erneut',
	'Attachments - Images': 'Anhänge – Bilder',
	'Attachments - Other files': 'Anhänge – Andere Dateien',
	'Completed tasks': 'Abgeschlossene Aufgaben',
	'Tasks not completed': 'Aufgaben nicht erledigt',
	'Edit history': 'Verlauf bearbeiten',
	'New Task': 'Neue Aufgabe',
	'Select the responsible': 'Wählen Sie den Verantwortlichen aus',
	Sort: 'Sortieren',
	'Alphabetical crescent': 'Alphabetisch A-Z',
	'Alphabetical descending': 'Alphabetisch Z-A',
	'Most recent': 'Neueste',
	'Least recent': 'Am wenigsten aktuell',
	'Nearest due date': 'Nächster Fälligkeitstermin',
	'Furthest due date': 'Das am weitesten entfernte Fälligkeitsdatum',
	'Highest priority': 'Höchste Priorität',
	'Lowest priority': 'Niedrigste Priorität',
	'Highest investment': 'Höchste Investition',
	'Lowest investment': 'Niedrigste Investition',
	'Action plans updated successfully': 'Aktionspläne erfolgreich aktualisiert',
	'Selected action plans deleted successfully': 'Aktionspläne erfolgreich gelöscht',
	'File sent for download': 'Datei zum Download gesendet',
	Exporting: 'Exportieren',
	Unknown: 'Unbekannt',
	'Enter the action plan name': 'Geben Sie den Namen des Aktionsplans ein',
	'Description of the file': 'Beschreibung der Datei',
	'Failed to deleted selected action plans': 'Ausgewählte Aktionspläne konnten nicht gelöscht werden',
	'Failed to upload file': 'Datei konnte nicht hochgeladen werden',
	'File uploaded successfully': 'Datei erfolgreich hochgeladen',
	'Select the due date': 'Wählen Sie das Fälligkeitsdatum aus',
	'Delete action plan': 'Aktionsplan löschen',
	'Are you sure you want to delete this action plan?': 'Möchten Sie diesen Aktionsplan wirklich löschen?',
	'Evidence removed': 'Nachweis entfernt',
	'Attachment removed': 'Anhang entfernt',
	'Evidence updated successfully': 'Nachweis erfolgreich aktualisiert',
	'Attachment updated successfully': 'Anhang erfolgreich aktualisiert',
	'Action plan attachment not found': 'Aktionsplan-Anhang nicht gefunden',
	'Action plan evidence not found': 'Aktionsplan-Nachweis nicht gefunden',
	'Task removed successfully': 'Aufgabe erfolgreich entfernt',
	'Action plan updated': 'Aktionsplan aktualisiert',
	'Drag or select the files you want to attach to your action plan':
		'Ziehen Sie die Dateien oder wählen Sie sie aus, die Sie Ihrem Aktionsplan anhängen möchten',
	'Are you sure you want to remove the attachment': 'Sind Sie sicher, dass Sie den Anhang entfernen möchten',
	'File description': 'Dateibeschreibung',
	'Upload author': 'Upload-Autor',
	'Attach files': 'Dateien anhängen',
	'Attach evidences': 'Nachweise anhängen',
	Responsable: 'Verantwortlicher',
	'Alphabetical A-Z': 'Alphabetisch A-Z',
	'Alphabetical Z-A': 'Alphabetisch Z-A',
	'Lowest investiment': 'Niedrigste Investition',
	'Attach new file': 'Neue Datei anhängen',
	'Action plan deleted': 'Aktionsplan gelöscht',
	'Description changed': 'Beschreibung geändert',
	'Title changed': 'Titel geändert',
	'Responsible changed': 'Verantwortlicher geändert',
	'Due date changed': 'Fälligkeitsdatum geändert',
	'Priority changed': 'Priorität geändert',
	'Investment changed': 'Investition geändert',
	'Attachment added': 'Anhang hinzugefügt',
	'Evidence added': 'Nachweis hinzugefügt',
	'Task changed': 'Aufgabe geändert',
	'Task removed': 'Aufgabe entfernt',
	'Task added': 'Aufgabe hinzugefügt',
	'Task completed': 'Aufgabe abgeschlossen',
	'Task uncompleted': 'Aufgabe nicht abgeschlossen',
	'Linked tool': 'Verknüpftes Werkzeug',
	'Unlinked tool': 'Nicht verknüpftes Werkzeug',
	'Comment added': 'Kommentar hinzugefügt',
	'Comment removed': 'Kommentar entfernt',
	'Comment changed': 'Kommentar geändert',
	'Error fetching responsible options': 'Fehler beim Abrufen der Verantwortlichen-Optionen',
	'Please select a priority': 'Bitte wählen Sie eine Priorität',
	'Please select a date': 'Bitte wählen Sie ein Datum',
	'Please select a responsible': 'Bitte wählen Sie einen Verantwortlichen',
	By: 'Von',
	'EWA JDS-D86': 'AEP + JDS-D86',
	'Open action plan': 'Aktionsplan öffnen',
	'There are tasks that have not been saved.': 'Es gibt nicht gespeicherte Aufgaben',
	'Tools updated successfully': 'Werkzeuge erfolgreich aktualisiert',
	'Are you sure you want to delete this comment?': 'Sind Sie sicher, dass Sie diesen Kommentar löschen möchten?',
	'Soon, you will be able to choose your own risk range': 'Bald können Sie Ihr eigenes Risikoband auswählen',
	'File activities must have unique names in same report':
		'Datei-Aktivitäten müssen denselben Namen für dieselbe Aktivität in demselben Bericht haben',
	'Multitasks and rotation': 'Mehrere Aufgaben und Rotation',
	'Multitasking and rotation assessment tool': 'Bewertungstool für Mehrfachaufgaben und Rotation',
	'All files': 'Alle Dateien',
	'Selected files': 'Ausgewählte Dateien',
	'Files added to report successfully': 'Dateien erfolgreich zum Bericht hinzugefügt',
	'Quantity of times that the activity repeats': 'Anzahl der Male, dass die Aktivität wiederholt wird',
	Goal: 'Ziel',
	'REBA tool setup': 'REBA-Tool-Einrichtung',
	Pause: 'Pause',
	'Video files': 'Video-Dateien',
	'Do you really want to delete this action plan?': 'Möchten Sie diesen Aktionsplan wirklich löschen?',
	'Not applicable': 'Nicht anwendbar',
	'Failed to generate the PDF': 'Fehler beim Generieren des PDFs',
	'The ISO 11226 standard states that for static activities the minimum percentage should be 80% (ISO 11226:2013: Ergonomics - Assessment of static working postures)':
		'Die ISO 11226-Standard gibt an, dass für statische Aktivitäten der Mindestprozentsatz 80% betragen sollte (ISO 11226:2013: Ergonomie - Bewertung statischer Arbeitshaltungen)'
};

export default dictionary;
