import React from 'react';
import { Row, Col, Divider } from 'antd';
import { I18n } from '@aws-amplify/core';
import {
	WarningOutlined,
	CloseCircleOutlined,
	CheckCircleOutlined,
	ExclamationCircleOutlined
} from '@ant-design/icons';

import { Title } from '@/components/Typography';
import { RiskDescription } from './RiskDescription';
import { NotAnalyzedDescription } from './NotAnalyzedDescription';

interface TopicDescriptionProps {
	riskScale: number;
	topic: string;
	score: number;
	hasDivider?: boolean;
	consequence: {
		name: string;
		description: string;
	};
	probability: {
		name: string;
		description: string;
	};
	exhibition: {
		name: string;
		description: string;
	};
	injuries: Array<{ id: string; name: string; description: string }>;
}

export function TopicDescription({
	riskScale,
	topic,
	score,
	hasDivider = true,
	consequence,
	probability,
	exhibition,
	injuries
}: Readonly<TopicDescriptionProps>) {
	const style = {
		fontSize: 20,
		width: '1.75rem'
	};

	const icons = [
		<CloseCircleOutlined style={{ ...style, color: '#b3b3b3' }} />,
		<CheckCircleOutlined style={{ ...style, color: '#2cc852' }} />,
		<ExclamationCircleOutlined style={{ ...style, color: '#FFDE31' }} />,
		<ExclamationCircleOutlined style={{ ...style, color: '#F78A38' }} />,
		<WarningOutlined style={{ ...style, color: '#E74150' }} />,
		<WarningOutlined style={{ ...style, color: '#9B54E2' }} />
	];

	return (
		<Col span={24}>
			<Row align="middle">
				<Col span={1}>{icons[riskScale]}</Col>
				<Col span={23}>
					<Title style={{ margin: 0 }} level={4}>
						{I18n.get(topic)}
					</Title>
				</Col>
			</Row>
			<Row style={{ marginTop: '0.5rem' }}>
				<Col span={1}>
					{hasDivider && (
						<Divider
							type="vertical"
							style={{
								height: '100%',
								margin: '0 0 0 0.7rem',
								borderLeft: '4px solid #e6e8e8'
							}}
						/>
					)}
				</Col>
				<Col span={22} style={{ marginLeft: '0.5rem' }}>
					{riskScale === 0 ? (
						<NotAnalyzedDescription />
					) : (
						<RiskDescription
							score={score}
							injuries={injuries}
							riskScale={riskScale}
							exhibition={exhibition}
							consequence={consequence}
							probability={probability}
						/>
					)}
				</Col>
			</Row>
		</Col>
	);
}
