import React, { useContext, useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { Form, Select, message } from 'antd';
import { MergeAEPContext } from '../../context';
import hooks from '@/components/Settings/Organizations/hooks';

const { useGetWorkstations } = hooks;

export function Workstation() {
	const { organizationId, companyId, sectorId, queryParams, setWorkstation, setQueryParams } =
		useContext(MergeAEPContext);

	const { isInitialLoading, data, isError } = useGetWorkstations(organizationId, companyId, sectorId);

	useEffect(() => {
		if (isError) {
			message.error(I18n.get('Failed to fetch workstations'));
		}
	}, [isError]);

	function handleOnChange(value: string): void {
		setWorkstation(value);
		setQueryParams({ ...queryParams, workstationId: value });
	}

	return (
		<Form.Item name="workstation">
			<Select
				showSearch
				onChange={handleOnChange}
				loading={isInitialLoading}
				placeholder={I18n.get('Workstation')}
				filterOption={(input, option) => (option?.label ?? '').includes(input)}
				options={data.map(({ id, name }) => ({ label: name, value: id }))}
			/>
		</Form.Item>
	);
}
