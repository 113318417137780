import React from 'react';
import { Col, Divider, Row } from 'antd';

import { TaskContainer, Title, Topic } from './style';
import { ActionPlanTaskResponse } from '../../../../hooks';

type ActionPlanTaskProps = {
	task: ActionPlanTaskResponse;
};

export const ActionPlanTask: React.FC<ActionPlanTaskProps> = ({ task }) => (
	<TaskContainer>
		<Row justify="space-between">
			<Col
				span={14}
				style={{
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis'
				}}
			>
				<Topic>•</Topic>
				<Title>{task.title}</Title>
			</Col>
		</Row>
		<Divider style={{ margin: '0.25rem 0' }} />
	</TaskContainer>
);
