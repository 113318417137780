import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Row } from 'antd';

import {
	Container,
	TitleCard,
	ResumeCard,
	ButtonCard,
	CircleFilledIcon,
	WarningFilledIcon,
	ExclamationFilledIcon
} from '../styles';

import { RiskMap, RiskMapper } from '../types';
import { ActionPlanModal } from '../../ActionPlanModal';
import { usePersonalisedReportContext } from '../../../../context';
import { usePersonalisedReportSubStepKeyContext } from '../context';

export const RiskLevelCard: React.FC = () => {
	const [showActionPlanModal, setShowActionPlanModal] = useState<boolean>(false);
	const { organization_id, company_id, file_id, personalisedReport } = usePersonalisedReportContext();
	const { riskLevelData, actionPlanData, subStepKeyId } = usePersonalisedReportSubStepKeyContext();
	const basicInformation = personalisedReport.basic_information;

	const { actionPlan, gettingActionPlan } = actionPlanData;
	const { levelRisk, errorCalculatingRisk, gettingRisk } = riskLevelData;

	const iconList: RiskMapper = {
		[RiskMap.LOW]: <CircleFilledIcon />,
		[RiskMap.MODERATE]: <ExclamationFilledIcon $isModerate={true} />,
		[RiskMap.HIGH]: <ExclamationFilledIcon />,
		[RiskMap.VERY_HIGH]: <WarningFilledIcon />,
		[RiskMap.EXTREME]: <WarningFilledIcon />
	};
	const titleList: RiskMapper = {
		[RiskMap.LOW]: 'Acceptable',
		[RiskMap.MODERATE]: 'Moderate',
		[RiskMap.HIGH]: 'High',
		[RiskMap.VERY_HIGH]: 'Very high',
		[RiskMap.EXTREME]: 'Serious and imminent'
	};
	const descriptionList: RiskMapper = {
		[RiskMap.LOW]: 'Ok',
		[RiskMap.MODERATE]: 'Improvement action',
		[RiskMap.HIGH]: 'Immediate action for improvement and reassess',
		[RiskMap.VERY_HIGH]: 'Perform Ergonomic Work Analysis',
		[RiskMap.EXTREME]: 'Stop the activity and carry out an Ergonomic Work Analysis'
	};

	async function handleActionPlanModal(): Promise<void> {
		setShowActionPlanModal(!showActionPlanModal);
	}

	function getRelatedReports() {
		let relatedReports = [];

		if (basicInformation?.kim_mho) {
			relatedReports.push({ report: 'kim_mho', id: basicInformation.kim_mho.id });
		}

		if (basicInformation?.kim_pp) {
			relatedReports.push({ report: 'kim_pp', id: basicInformation.kim_pp.id });
		}

		if (basicInformation?.strain_index) {
			relatedReports.push({ report: 'strain_index', id: basicInformation.strain_index.id });
		}

		if (basicInformation?.angle_time) {
			relatedReports.push({ report: 'angle_time', id: basicInformation.angle_time.id });
		}

		if (basicInformation?.liberty_mutual) {
			relatedReports.push({ report: 'liberty_mutual', id: basicInformation.liberty_mutual.id });
		}

		if (basicInformation?.niosh) {
			relatedReports.push({ report: 'niosh', id: basicInformation.niosh.id });
		}

		if (basicInformation?.reba) {
			relatedReports.push({ report: 'reba', id: basicInformation.reba.id });
		}

		return relatedReports;
	}

	return (
		<Container gutter={[10, 0]} $levelRisk={levelRisk.result}>
			<Col span={3} style={{ textAlign: 'center' }}>
				{iconList[levelRisk.result] ?? <ExclamationFilledIcon />}
			</Col>
			<Col span={21}>
				<Row>
					<Col span={24}>
						<TitleCard $levelRisk={levelRisk.result} level={5}>
							{I18n.get(
								!levelRisk?.result ? 'Fill in the details above' : `${titleList[levelRisk.result]} risk`
							)}
						</TitleCard>
					</Col>
					<Col span={24}>
						<ResumeCard $levelRisk={levelRisk.result}>
							{I18n.get(descriptionList[levelRisk.result])}
						</ResumeCard>
					</Col>
					<Col
						span={24}
						style={{ paddingTop: '5%', ...{ display: `${!levelRisk.result ? 'none' : 'unset'}` } }}
					>
						<ButtonCard
							size="small"
							$levelRisk={levelRisk.result}
							disabled={errorCalculatingRisk}
							onClick={handleActionPlanModal}
							loading={gettingActionPlan || gettingRisk}
						>
							{!actionPlan?.id || actionPlanData.errorGettingActionPlan
								? I18n.get('Create action plan')
								: I18n.get('Update action plan')}
						</ButtonCard>
					</Col>
				</Row>
			</Col>
			{showActionPlanModal && (
				<ActionPlanModal
					fileId={file_id}
					activityId={personalisedReport?.basic_information?.activity_id}
					companyId={company_id}
					score={levelRisk.score}
					result={levelRisk.result}
					isOpen={showActionPlanModal}
					onClose={handleActionPlanModal}
					organizationId={organization_id}
					relatedReports={getRelatedReports()}
					actionPlan={actionPlanData.actionPlan}
					customReportSubStepKeyId={subStepKeyId}
					customReportResultId={personalisedReport.result_id}
				/>
			)}
		</Container>
	);
};
