import { Mapper } from '../base-mapper';
import { ActionPlanCreateComments, ActionPlanCreateCommentsDomain } from '../../domain';
import { ActionPlanCreateCommentsDTO, ActionPlanCreateCommentsItemDTO } from '../../dto';

export class ActionPlanCreateCommentsMapper implements Mapper<ActionPlanCreateCommentsDomain> {
	private readonly response: ActionPlanCreateCommentsDTO;

	constructor(response: ActionPlanCreateCommentsDTO) {
		this.response = {
			data: Array.isArray(response.data) ? response.data : []
		};
	}

	private mapActionPlanItem(item: ActionPlanCreateCommentsItemDTO): ActionPlanCreateComments {
		return new ActionPlanCreateComments({
			id: item.id,
			action_plan_id: item.action_plan_id,
			description: item.description,
			updated_at: item.updated_at ?? null,
			created_at: item.created_at ?? null
		});
	}

	toDomain(): ActionPlanCreateCommentsDomain {
		return {
			data: this.response.data.map((item) => this.mapActionPlanItem(item))
		};
	}
}
