import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/user';
import { ResponsibleDTO } from '@/core/dto/responsible';

export interface GetResponsableOptionsParams {
	organization_id: string;
	company_id: string;
}

export interface GetResponsibleOptionsResponse {
	value: string;
	label: string;
}

const transformResponsibleOptions = (data: ResponsibleDTO[]): GetResponsibleOptionsResponse[] => {
	return data.map((responsible) => ({ value: responsible.id, label: responsible.name }));
};

export const useGetResponsableOptions = (params: GetResponsableOptionsParams) => {
	return useQuery([QUERY_KEYS.GET_RESPONSIBLES_OPTIONS, params], () => Service.listOptions(params), {
		enabled: !!params.organization_id && !!params.company_id,
		select: transformResponsibleOptions
	});
};
