import React, { useContext } from 'react';
import { Row, Col, Table } from 'antd';
import { I18n } from '@aws-amplify/core';
import { ColumnsType } from 'antd/lib/table';

import { useGetCustomReportByName } from '@/views/Dashboard/hooks/useGetCustomReportByName';
import { useListCustomReportResultPaginated } from '@/hooks';
import { EWA_CUSTOM_REPORT } from '@/utils/constants';
import Spinner from '@/components/Layout/Spinner';
import { Title } from '@/components/Typography';
import { MergeAEPContext } from './context';
import { RiskScale } from '@/styles/enum';

type TableData = {
	score: RiskScale | undefined;
	file_name: string;
	analyst: string;
	id: string;
};

export function Conclusion() {
	const {
		organizationId,
		data: { pea_list }
	} = useContext(MergeAEPContext);

	const { isLoading: isLoadingPea, data: pea } = useGetCustomReportByName({ name: EWA_CUSTOM_REPORT });

	const { isLoading, data, isError } = useListCustomReportResultPaginated({
		organization_id: organizationId,
		custom_report_id: pea?.id,
		isSelectedReport: true,
		limit: 100,
		offset: 0
	});

	const columns: ColumnsType<TableData> = [
		{
			title: I18n.get('Name'),
			dataIndex: 'file_name',
			key: 'key',
			align: 'center',
			ellipsis: true,
			render: (text: string) => text.split('-')[0]
		},
		{
			title: I18n.get('Analyst'),
			dataIndex: 'analyst',
			key: 'analyst',
			align: 'center',
			ellipsis: true
		},
		{
			title: I18n.get('Score'),
			dataIndex: 'score',
			key: 'score',
			align: 'center',
			ellipsis: true
		}
	];

	if (isLoading || isLoadingPea) {
		return (
			<Col xs={24} sm={18} lg={12} style={{ minHeight: '20rem' }}>
				<Spinner />
			</Col>
		);
	}

	if (isError) {
		return <span>{I18n.get('Oops... Something went wrong!')}</span>;
	}

	return (
		<Col xs={24} sm={18} lg={12}>
			<Row gutter={[10, 10]}>
				<Col span={24}>
					<Title level={5}>{I18n.get('Selected EWA')}</Title>
				</Col>
				<Col span={24}>
					<Table
						rowKey="key"
						pagination={{ pageSize: 5 }}
						dataSource={data.rows
							.filter((pea) => pea_list?.some((selectedPEA) => selectedPEA === pea.id))
							.map((pea) => ({
								id: pea.id,
								file_name: pea.file_name,
								analyst: pea.evaluator_name,
								score: pea.score?.result
							}))}
						columns={columns}
					/>
				</Col>
			</Row>
		</Col>
	);
}
