import React from 'react';
import { Col, Divider, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Description } from './styles';
import { Title } from '@/components/Typography';
import { usePersonalisedReportStepKeyResultContext } from '../context';
import { PersonalisedReportResultInjuryResponse } from '../../../../hooks';

export const Injury: React.FC = () => {
	const { stepKey } = usePersonalisedReportStepKeyResultContext();
	const { injuries } = stepKey;

	function formatInjuries(
		injury: PersonalisedReportResultInjuryResponse,
		index: number,
		injuries: PersonalisedReportResultInjuryResponse[]
	): string {
		const lastElementIndex: number = injuries.length - 1;
		const punctuation: string = lastElementIndex !== index ? ',' : '.';
		return `${I18n.get(injury?.description ?? '')}${punctuation} `;
	}

	if (!injuries || injuries.length === 0) {
		return null;
	}

	return (
		<>
			<Col xs={4}>
				<Row>
					<Title style={{ margin: 0 }} level={5}>
						{I18n.get('Possible Injuries/Harms')}
					</Title>
				</Row>
				<Row>
					<Description>{injuries.map(formatInjuries)}</Description>
				</Row>
			</Col>
			<Divider type="vertical" style={{ height: '100%' }} />
		</>
	);
};
