import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import * as S from './styles';

interface InfoToolTipProps {
	title: string;
}

export function InfoTooltip({ title }: Readonly<InfoToolTipProps>) {
	return (
		<S.CustomTooltip showArrow={false} title={title} getPopupContainer={(triggerNode) => triggerNode}>
			<InfoCircleOutlined />
		</S.CustomTooltip>
	);
}
