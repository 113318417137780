import React, { useContext, useEffect, useState } from 'react';
import { Form, Select, message } from 'antd';
import { I18n } from '@aws-amplify/core';
import debounce from 'lodash/debounce';

import hooks from '../../hooks';
import { MergeAEPContext } from '../../context';

const { useGetCompanies } = hooks;
const { useWatch, useFormInstance } = Form;

export function Company() {
	const { organizationId, queryParams, setCompanyId, setQueryParams, setSectorId, setWorkstation } =
		useContext(MergeAEPContext);
	const [searchTerm, setSearchTerm] = useState('');

	const form = useFormInstance();
	const companyId = useWatch('companyId');

	const { isInitialLoading, data, isError, refetch } = useGetCompanies(organizationId, searchTerm);

	useEffect(() => {
		if (isError) {
			message.error(I18n.get('Failed to search for companies'));
		}
	}, [isError]);

	useEffect(() => {
		if (!companyId) {
			refetch();
		}
	}, [companyId, refetch]);

	function handleOnClearForm(): void {
		form.resetFields(['sectorId', 'workstation']);
	}

	function handleOnClearHierarchy(): void {
		setSectorId('');
		setWorkstation('');
	}

	function handleOnChange(value: string): void {
		setCompanyId(value);
		handleOnClearHierarchy();
		handleOnClearForm();
		setQueryParams({ ...queryParams, companyId: value });
	}

	const debouncedSearch = debounce((value: string) => {
		setSearchTerm(value);
	}, 300);

	function handleSearch(value: string) {
		debouncedSearch(value);
	}

	return (
		<Form.Item name="companyId">
			<Select
				showSearch
				loading={isInitialLoading}
				onChange={handleOnChange}
				onSearch={handleSearch}
				placeholder={I18n.get('Industrial Site')}
				options={data?.map(({ id, name }) => ({ label: name, value: id }))}
				filterOption={false}
				notFoundContent={isInitialLoading ? <span>{I18n.get('Loading')}...</span> : null}
			/>
		</Form.Item>
	);
}
