import React from 'react';
import { Row, Col } from 'antd';
import { useParams } from 'react-router-dom';

import { Report } from './Report';
import { useGetReport } from './hooks';
import { Text } from '@/components/Typography';
import { PersonalisedReportProvider } from './context';
import { LoadingSkeleton } from './context/LoadingSkeleton';
import { PersonalisedReportRouteParams, PersonalisedReportSearchParams } from './context/types';
import { useApplicationContext } from '@/context/Application';
import { useSearchParams } from '@/hooks';

export function PersonalisedReport() {
	const { organization, company } = useApplicationContext();
	const { report_id, file_id } = useParams<PersonalisedReportRouteParams>();

	const {
		organization_id = organization.id,
		company_id = company.id,
		current_sequence = '',
		id
	} = useSearchParams<PersonalisedReportSearchParams>();

	const {
		data: personalisedReport,
		isError: errorGettingReport,
		isLoading: gettingReport,
		isFetching: fetchingReport
	} = useGetReport({
		id,
		file_id,
		report_id,
		company_id,
		organization_id,
		current_sequence: current_sequence ? Number(current_sequence) : null
	});

	if (errorGettingReport) {
		return <Text>Oops... Something went wrong!</Text>;
	}

	if (gettingReport || fetchingReport) {
		return <LoadingSkeleton />;
	}

	return (
		<Row>
			<Col xs={24}>
				<PersonalisedReportProvider personalisedReport={personalisedReport}>
					<Report />
				</PersonalisedReportProvider>
			</Col>
		</Row>
	);
}
