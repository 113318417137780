import React from 'react';
import { Row, Col } from 'antd';
import { Title } from '@/components/Typography';
import { usePersonalisedReportContext } from '../context';

export function Header() {
	const { personalisedReport } = usePersonalisedReportContext();

	return (
		<Row justify="center">
			<Col>
				<Title level={2}>{personalisedReport.report_name}</Title>
			</Col>
		</Row>
	);
}
