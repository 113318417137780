import { BaseService, BasicInfo } from './base-service';
import { ActionPlanCountByStatus } from '@/types/ActionPlans';
import {
	ActionPlanCreateUserPreferenceColumnsMapper,
	ActionPlanDeleteCommentsMapper,
	ActionPlanCreateCommentsMapper,
	ActionPlanUpdateCommentsMapper,
	ActionPlanAttachmentsMapper,
	ActionPlanCommentsMapper,
	ActionPlanHistoryMapper,
	ActionPlanListMapper
} from '@/core/mappers/action-plans';
import {
	ActionPlanCreateUserPreferenceColumnsDomain,
	ActionPlanFromReportResponseDTO,
	ActionPlanFromReportRequestDTO,
	ActionPlanCreateCommentsDomain,
	ActionPlanUpdateCommentsDomain,
	ActionPlanDeleteCommentsDomain,
	ActionPlanCommentsDomain,
	ActionPlanHistoryDomain,
	ActionPlanListDomain,
	ActionPlanCardList,
	UploadFileItem
} from '@/core/domain';
import {
	ActionPlanCreateUserPreferenceColumnsRequestDTO,
	ActionPlanTaskAttachmentDownloadUrlRequestDTO,
	ActionPlanGetUserPreferenceColumnsRequestDTO,
	ActionPlanAttachmentDownloadUrlRequestDTO,
	ActionPlanCreateUserPreferenceColumnsDTO,
	ActionPlanUpdateNotificationResponseDTO,
	ActionPlanUpdateNotificationRequestDTO,
	CreateActionPlanFromReportResponseDTO,
	ActionPlanUpdateTaskStatusResponseDTO,
	CreateActionPlanFromReportRequestDTO,
	ActionPlanUpdateTaskStatusRequestDTO,
	ActionPlanCardBulkDeleteResponseDTO,
	ActionPlanCardBulkUpdateResponseDTO,
	ActionPlanUpdateCommentsRequestDTO,
	ActionPlanDeleteCommentsRequestDTO,
	ActionPlanCreateCommentsRequestDTO,
	ActionPlanCreateTasksResponseDTO,
	ActionPlanUpdateTaskResponseDTO,
	ActionPlanCreateTasksRequestDTO,
	GetCountByStatusActionPlansRequestDTO,
	ActionPlanDeleteTaskResponseDTO,
	ActionPlanUpdateTaskRequestDTO,
	ActionPlanDeleteTaskRequestDTO,
	ActionPlanCardListResponseDTO,
	ActionPlanCardListRequestDTO,
	ActionPlanCommentsRequestDTO,
	SetRelatedReportsResponseDTO,
	GetRelatedReportsResponseDTO,
	ActionPlanPdfEditRequestDTO,
	CreateActionPlanResponseDTO,
	ActionPlanOriginResponseDTO,
	ActionPlanCardBulkDeleteDTO,
	ActionPlanCardBulkUpdateDTO,
	ActionPlanUpdateCommentsDTO,
	ActionPlanHistoryRequestDTO,
	GetRelatedReportsRequestDTO,
	DeleteAttachmentResponseDTO,
	ActionPlanCreateCommentsDTO,
	ActionPlanDeleteCommentsDTO,
	UpdateAttachmentResponseDTO,
	SetRelatedReportsRequestDTO,
	UpdateActionPlanResponseDTO,
	ActionPlanCountResponseDTO,
	UpdateAttachmentRequestDTO,
	CreateActionPlanRequestDTO,
	DeleteAttachmentRequestDTO,
	ActionPlanTasksResponseDTO,
	ActionPlanOriginRequestDTO,
	UpdateActionPlanRequestDTO,
	UpdateEvidenceResponseDTO,
	ActionPlanCountRequestDTO,
	ActionPlanTasksRequestDTO,
	DeleteEvidenceResponseDTO,
	UpdateCardRankResponseDTO,
	UpdateCardRankRequestDTO,
	AddAttachmentResponseDTO,
	DeleteEvidenceRequestDTO,
	ActionPlanListRequestDTO,
	GetAttachmentsRequestDTO,
	UpdateEvidenceRequestDTO,
	OriginOptionResponseDTO,
	AddAttachmentRequestDTO,
	AddEvidenceResponseDTO,
	GetEvidencesRequestDTO,
	AddEvidenceRequestDTO,
	ActionPlanCommentsDTO,
	ActionPlanResponseDTO,
	ActionPlanHistoryDTO,
	ActionPlanListDTO,
	GetCountByStatusActionPlansResponseDTO
} from '@/core/dto';

class ActionPlan extends BaseService {
	constructor(public readonly basePath: string = '/action_plan') {
		super();
	}

	async create(params: CreateActionPlanRequestDTO): Promise<CreateActionPlanResponseDTO> {
		const url = this.basePath + '/v2';
		const { data } = await this.getInstance().post(url, params);
		return data;
	}

	async createFromReport(
		params: CreateActionPlanFromReportRequestDTO
	): Promise<CreateActionPlanFromReportResponseDTO> {
		const url = this.basePath + '/v2/report';
		const { data } = await this.getInstance().post(url, params);
		return data;
	}

	async list(params: ActionPlanListRequestDTO): Promise<ActionPlanListDomain> {
		const url = this.basePath + '/v2/';
		const { data } = await this.getInstance().get<ActionPlanListDTO>(url, { params });
		return new ActionPlanListMapper(data).toDomain();
	}

	async countAll(params: ActionPlanCountRequestDTO): Promise<ActionPlanCountResponseDTO> {
		const url = this.basePath + '/v2/count-all';
		const { data } = await this.getInstance().get<ActionPlanCountResponseDTO>(url, { params });
		return data;
	}

	async origins(params: ActionPlanOriginRequestDTO): Promise<ActionPlanOriginResponseDTO> {
		const url = this.basePath + '/v2/origin';
		const { data } = await this.getInstance().get<ActionPlanOriginResponseDTO>(url, { params });
		return data;
	}

	async getNotifications(): Promise<string[]> {
		const url = this.basePath + '/v2/user-preference/notification';
		const { data } = await this.getInstance().get<string[]>(url);
		return data;
	}

	async updateNotification(
		params: ActionPlanUpdateNotificationRequestDTO
	): Promise<ActionPlanUpdateNotificationResponseDTO> {
		const url = this.basePath + '/v2/user-preference/notification';
		const { data } = await this.getInstance().put(url, params);
		return data;
	}

	async createPreferenceColumns(
		params: ActionPlanCreateUserPreferenceColumnsRequestDTO
	): Promise<ActionPlanCreateUserPreferenceColumnsDomain> {
		const url = this.basePath + '/v2/user-preference/columns';
		const { data } = await this.getInstance().post<ActionPlanCreateUserPreferenceColumnsDTO>(url, params);
		return new ActionPlanCreateUserPreferenceColumnsMapper(data).toDomain();
	}

	async getUserPreferenceColumns(params: ActionPlanGetUserPreferenceColumnsRequestDTO): Promise<string[]> {
		const url = this.basePath + '/v2/user-preference/columns';
		const { data } = await this.getInstance().get<string[]>(url, { params });
		return data;
	}

	async bulkDelete(params: ActionPlanCardBulkDeleteDTO): Promise<ActionPlanCardBulkDeleteResponseDTO> {
		const url = this.basePath + '/v2/';
		const { data } = await this.getInstance().delete(url, { params });
		return data;
	}

	async bulkUpdate(params: ActionPlanCardBulkUpdateDTO): Promise<ActionPlanCardBulkUpdateResponseDTO> {
		const url = this.basePath + '/v2/bulk-update';
		const { data } = await this.getInstance().put(url, params);
		return data;
	}

	async findOne(params: BasicInfo): Promise<ActionPlanResponseDTO> {
		const url = this.basePath + `/v2/${params.id}`;
		const { data } = await this.getInstance().get<ActionPlanResponseDTO>(url, { params });
		return data;
	}

	async findAllForBoard(params: ActionPlanCardListRequestDTO): Promise<ActionPlanCardList> {
		const url = this.basePath + '/v2/board';
		const { data } = await this.getInstance().get<ActionPlanCardListResponseDTO>(url, { params });
		return data;
	}

	async findOneFromReport(params: ActionPlanFromReportRequestDTO): Promise<ActionPlanFromReportResponseDTO> {
		const url = this.basePath + `/v2/report`;
		const { data } = await this.getInstance().get<ActionPlanFromReportResponseDTO>(url, { params });
		return data;
	}

	async getHistory(params: ActionPlanHistoryRequestDTO): Promise<ActionPlanHistoryDomain> {
		const url = this.basePath + '/v2/history';
		const { data } = await this.getInstance().get<ActionPlanHistoryDTO>(url, { params });
		return new ActionPlanHistoryMapper(data).toDomain();
	}

	async getAttachments(params: GetAttachmentsRequestDTO): Promise<UploadFileItem[]> {
		const url = this.basePath + '/v2/attachment';
		const { data } = await this.getInstance().get(url, { params });
		return new ActionPlanAttachmentsMapper(data).toDomain();
	}

	async getRelatedReports(params: GetRelatedReportsRequestDTO): Promise<GetRelatedReportsResponseDTO[]> {
		const url = this.basePath + '/v2/related-report';
		const { data } = await this.getInstance().get(url, { params });
		return data;
	}

	async deleteAttachment(params: DeleteAttachmentRequestDTO): Promise<DeleteAttachmentResponseDTO> {
		const url = this.basePath + '/v2/attachment';
		const { data } = await this.getInstance().delete(url, { params });
		return data;
	}

	async updateAttachment(params: UpdateAttachmentRequestDTO): Promise<UpdateAttachmentResponseDTO> {
		const url = this.basePath + '/v2/attachment';
		const { data } = await this.getInstance().put(url, params);
		return data;
	}

	async getAttachmentDownloadUrl(params: ActionPlanAttachmentDownloadUrlRequestDTO): Promise<string> {
		const url = this.basePath + '/v2/attachment/download';
		const { data } = await this.getInstance().get<string>(url, { params });
		return data;
	}

	async attachment(params: AddAttachmentRequestDTO): Promise<AddAttachmentResponseDTO> {
		const url = this.basePath + '/v2/attachment';
		const { data } = await this.getInstance().post(url, params);
		return data;
	}

	async setRelatedReports(params: SetRelatedReportsRequestDTO): Promise<SetRelatedReportsResponseDTO> {
		const url = this.basePath + '/v2/related-report';
		const { data } = await this.getInstance().put(url, params);
		return data;
	}

	async createComments(params: ActionPlanCreateCommentsRequestDTO): Promise<ActionPlanCreateCommentsDomain> {
		const url = this.basePath + '/v2/comment';
		const { data } = await this.getInstance().post<ActionPlanCreateCommentsDTO>(url, params);
		return new ActionPlanCreateCommentsMapper(data).toDomain();
	}

	async updateComments(params: ActionPlanUpdateCommentsRequestDTO): Promise<ActionPlanUpdateCommentsDomain> {
		const url = this.basePath + '/v2/comment';
		const { data } = await this.getInstance().put<ActionPlanUpdateCommentsDTO>(url, params);
		return new ActionPlanUpdateCommentsMapper(data).toDomain();
	}

	async deleteComments(params: ActionPlanDeleteCommentsRequestDTO): Promise<ActionPlanDeleteCommentsDomain> {
		const url = this.basePath + '/v2/comment';
		const { data } = await this.getInstance().delete<ActionPlanDeleteCommentsDTO>(url, { params });
		return new ActionPlanDeleteCommentsMapper(data).toDomain();
	}

	async getComments(params: ActionPlanCommentsRequestDTO): Promise<ActionPlanCommentsDomain> {
		const url = this.basePath + '/v2/comments';
		const { data } = await this.getInstance().get<ActionPlanCommentsDTO>(url, { params });
		return new ActionPlanCommentsMapper(data).toDomain();
	}

	async getTasks(params: ActionPlanTasksRequestDTO): Promise<ActionPlanTasksResponseDTO[]> {
		const url = this.basePath + '/v2/task';
		const { data } = await this.getInstance().get<ActionPlanTasksResponseDTO[]>(url, { params });
		return data;
	}

	async createTask(params: ActionPlanCreateTasksRequestDTO): Promise<ActionPlanCreateTasksResponseDTO> {
		const url = this.basePath + '/v2/task';
		const { data } = await this.getInstance().post<ActionPlanCreateTasksResponseDTO>(url, params);
		return data;
	}

	async updateTask(params: ActionPlanUpdateTaskRequestDTO): Promise<ActionPlanUpdateTaskResponseDTO> {
		const url = this.basePath + '/v2/task';
		const { data } = await this.getInstance().put<ActionPlanUpdateTaskResponseDTO>(url, params);
		return data;
	}

	async updateTaskStatus(
		params: ActionPlanUpdateTaskStatusRequestDTO
	): Promise<ActionPlanUpdateTaskStatusResponseDTO> {
		const url = this.basePath + '/v2/task/completed';
		const { data } = await this.getInstance().put<ActionPlanUpdateTaskStatusResponseDTO>(url, params);
		return data;
	}

	async deleteTask(params: ActionPlanDeleteTaskRequestDTO): Promise<ActionPlanDeleteTaskResponseDTO> {
		const url = this.basePath + '/v2/task';
		const { data } = await this.getInstance().delete<ActionPlanDeleteTaskResponseDTO>(url, { params });
		return data;
	}

	async getTaskAttachmentDownloadUrl(params: ActionPlanTaskAttachmentDownloadUrlRequestDTO): Promise<string> {
		const url = this.basePath + '/v2/task/attachment/download';
		const { data } = await this.getInstance().get<string>(url, { params });
		return data;
	}

	async getEvidences(params: GetEvidencesRequestDTO): Promise<UploadFileItem[]> {
		const url = this.basePath + '/v2/task/attachment';
		const { data } = await this.getInstance().get(url, { params });
		return new ActionPlanAttachmentsMapper(data).toDomain();
	}

	async addEvidence(params: AddEvidenceRequestDTO): Promise<AddEvidenceResponseDTO> {
		const url = this.basePath + '/v2/task/attachment';
		const { data } = await this.getInstance().post(url, params);
		return data;
	}

	async updateEvidence(params: UpdateEvidenceRequestDTO): Promise<UpdateEvidenceResponseDTO> {
		const url = this.basePath + '/v2/task/attachment';
		const { data } = await this.getInstance().put(url, params);
		return data;
	}

	async deleteEvidence(params: DeleteEvidenceRequestDTO): Promise<DeleteEvidenceResponseDTO> {
		const url = this.basePath + '/v2/task/attachment';
		const { data } = await this.getInstance().delete(url, { params });
		return data;
	}

	async downloadPdfEdit(params: ActionPlanPdfEditRequestDTO): Promise<string> {
		const url = this.basePath + `/v2/${params.id}/pdf`;
		const { data } = await this.getInstance().post<string>(url, params);
		return data;
	}

	async update(params: UpdateActionPlanRequestDTO): Promise<UpdateActionPlanResponseDTO> {
		const url = this.basePath + `/v2/${params.id}`;
		const { data } = await this.getInstance().put<UpdateActionPlanResponseDTO>(url, params);
		return data;
	}

	async updateCardRank(params: UpdateCardRankRequestDTO): Promise<UpdateCardRankResponseDTO> {
		const url = this.basePath + '/v2/update-rank';
		const { data } = await this.getInstance().put(url, params);
		return data;
	}

	async getOriginOptions(): Promise<OriginOptionResponseDTO[]> {
		const url = this.basePath + '/v2/origin/option';
		const { data } = await this.getInstance().get<OriginOptionResponseDTO[]>(url);
		return data;
	}

	async getCountByStatus(params: GetCountByStatusActionPlansRequestDTO): Promise<ActionPlanCountByStatus> {
		const url = this.basePath + '/v2/report/count-by-status';
		const { data } = await this.getInstance().get<GetCountByStatusActionPlansResponseDTO>(url, { params });
		return data;
	}
}

const Service = Object.freeze(new ActionPlan());
export { Service };
