import moment, { type Moment } from 'moment';

interface OWASReportAttributes {
	id: string;
	report_name: string;
	men_exposed: number;
	women_exposed: number;
	other_exposed: number;
	capture_interval_seconds: number;
	activity_description: string;
	evaluator_id: string;
	sector_id: string;
	cellule_id: string;
	workstation_id: string;
	activity_id: string;
	analysis_date: string;
	collection_date: string;
	current_step: number;
	is_consolidated: boolean;
	comment: string | null;
}

export class OWASReport {
	public readonly id: string;
	public readonly report_name: string;
	public readonly men_exposed: number;
	public readonly women_exposed: number;
	public readonly other_exposed: number;
	public readonly total_exposed: number;
	public readonly capture_interval_seconds: number;
	public readonly activity_description: string;
	public readonly evaluator_id: string;
	public readonly sector_id: string;
	public readonly cellule_id: string;
	public readonly workstation_id: string;
	public readonly activity_id: string;
	public readonly analysis_date: Moment;
	public readonly collection_date: Moment | undefined;
	public readonly is_consolidated: boolean;
	public readonly current_step: number;
	public readonly comment: string | null;

	constructor({
		id,
		report_name,
		men_exposed,
		women_exposed,
		other_exposed,
		capture_interval_seconds,
		activity_description,
		evaluator_id,
		sector_id,
		cellule_id,
		workstation_id,
		activity_id,
		analysis_date,
		collection_date,
		current_step = 0,
		is_consolidated,
		comment
	}: OWASReportAttributes) {
		this.id = id;
		this.report_name = report_name;
		this.men_exposed = men_exposed;
		this.women_exposed = women_exposed;
		this.other_exposed = other_exposed;
		this.total_exposed = this.getTotalExposed();
		this.capture_interval_seconds = capture_interval_seconds;
		this.activity_description = activity_description;
		this.evaluator_id = evaluator_id;
		this.sector_id = sector_id;
		this.cellule_id = cellule_id;
		this.workstation_id = workstation_id;
		this.activity_id = activity_id;
		this.analysis_date = analysis_date ? moment(analysis_date) : moment();
		this.collection_date = collection_date ? moment(collection_date) : undefined;
		this.is_consolidated = is_consolidated;
		this.current_step = current_step;
		this.comment = comment;
	}

	getTotalExposed() {
		return this.men_exposed + this.women_exposed + this.other_exposed;
	}
}
