import { useQuery } from '@tanstack/react-query';

import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import type { AxiosErrorResponse, BaseReportsPaginated, BaseReportsPaginatedResponse, PaginatedData } from '@/types';

export type ListAllCustomReportResultDTO = BaseReportsPaginated;

export type ListAllCustomReportResultResponse = PaginatedData<BaseReportsPaginatedResponse>;

async function listCustomReportResultPaginated(
	params: ListAllCustomReportResultDTO
): Promise<ListAllCustomReportResultResponse> {
	const url = '/custom-report/result/reports/list-all';
	const { data } = await Api.get<ListAllCustomReportResultResponse>(url, {
		params
	});
	return data;
}

export const useListCustomReportResultPaginated = ({
	limit,
	offset,
	sector_id,
	company_id,
	cellule_id,
	report_name,
	activity_id,
	evaluator_id,
	original_name,
	created_at_end,
	workstation_id,
	is_consolidated,
	organization_id,
	custom_report_id,
	created_at_start,
	isSelectedReport,
	collection_date_end,
	collection_date_start
}: ListAllCustomReportResultDTO) => {
	const enabled = !!limit && !!custom_report_id && !!isSelectedReport && offset >= 0;
	const customReportResultReport = useQuery<ListAllCustomReportResultResponse, AxiosErrorResponse>(
		[
			QUERY_KEYS.GET_CUSTOM_REPORT_RESULT_PAGINATED,
			[
				limit,
				offset,
				sector_id,
				company_id,
				cellule_id,
				report_name,
				activity_id,
				evaluator_id,
				original_name,
				created_at_end,
				workstation_id,
				is_consolidated,
				organization_id,
				created_at_start,
				custom_report_id,
				collection_date_end,
				collection_date_start
			]
		],
		() =>
			listCustomReportResultPaginated({
				limit,
				offset,
				sector_id,
				company_id,
				cellule_id,
				report_name,
				activity_id,
				evaluator_id,
				original_name,
				created_at_end,
				workstation_id,
				is_consolidated,
				organization_id,
				created_at_start,
				custom_report_id,
				collection_date_end,
				collection_date_start
			}),
		{
			enabled,
			retry: 0
		}
	);
	return customReportResultReport;
};
