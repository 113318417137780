import React, { useContext } from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { queryClient } from '@/store/query';
import { Row, Col, Form, Steps, message } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

import { useApplicationContext } from '@/context/Application';
import { Informations } from './Informations';
import { MergeAEPContext } from './context';
import { Conclusion } from './Conclusion';
import { Signatures } from './Signatures';
import { Checklist } from './Checklist';
import history from '@/store/history';
import { PEADataDTO } from './types';
import { Buttons } from './Buttons';
import hooks from './hooks';

const { useForm } = Form;
const { useCreateEWA } = hooks;

export function Create() {
	const {
		currentStep,
		organizationId,
		nextStep,
		prevStep,
		setAnalyst,
		setFileName,
		setSectorId,
		setCreatedAt,
		setCompanyId,
		setWorkstation,
		setQueryParams,
		setCollectionDate
	} = useContext(MergeAEPContext);

	const { setData, data } = useContext(MergeAEPContext);
	const { organization, company } = useApplicationContext();

	const { mutateAsync: createNewSuperPEA, isLoading } = useCreateEWA();

	const [form] = useForm();
	const breakpoints = useBreakpoint();

	function clearFilter() {
		form.resetFields([
			'analyst',
			'fileName',
			'sectorId',
			'companyId',
			'createdAt',
			'workstation',
			'collectionDate'
		]);
		resetAllFilters();
		setQueryParams({
			organizationId,
			companyId: '',
			clearFilter: true
		});
	}

	function resetAllFilters() {
		setAnalyst('');
		setFileName('');
		setSectorId('');
		setCompanyId('');
		setCreatedAt([]);
		setWorkstation('');
		setCollectionDate([]);
	}

	async function handleNextStep() {
		try {
			const values = await form.validateFields();

			if (basicInformationWasChanged(values)) {
				setData({
					basicInformation: values
				});
			}

			nextStep();
		} catch (error) {
			return;
		}
	}

	function basicInformationWasChanged(values: any) {
		return Object.hasOwn(values, 'report_name');
	}

	async function onFinish() {
		const signatures = form.getFieldsValue(true).signatures;

		const body: PEADataDTO = {
			organization_id: organization.id,
			company_id: company.id,
			data: {
				signatures,
				pea_ids: data.pea_list ?? [],
				conclusion: data.conclusion ?? '',
				name: data.basicInformation?.report_name ?? '',
				collection_date: moment(data.basicInformation?.date_report).format(),
				reference_date: moment(data.basicInformation?.reference_date).format()
			}
		};

		createNewSuperPEA(body, {
			onSuccess: (data: any) => {
				queryClient.invalidateQueries(['super_ewa']);
				message.success(I18n.get('EWA created successfully'));
				let archive = document.createElement('a');
				archive.target = '_blank';
				archive.href = data.url;
				archive.click();
				archive.remove();
				history.push('/super-pea/file-history');
			},
			onError: (err: any) => {
				message.error(I18n.get(err.response.data.message));
			}
		});
	}

	const steps = [
		{
			id: 'basic_information',
			title: 'Basic Information',
			content: <Informations />
		},
		{
			id: 'select_ewa',
			title: 'Select EWA',
			content: <Checklist onClearFilter={clearFilter} />
		},
		{
			id: 'signatures',
			title: 'Signatures',
			content: <Signatures />
		},
		{
			id: 'conclusion',
			title: 'Conclusion',
			content: <Conclusion />
		}
	];

	function handlePrevStep() {
		currentStep === 1 && clearFilter();
		prevStep();
	}

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			initialValues={{
				...data
			}}
		>
			<Row justify="center" gutter={[0, 32]}>
				<Col>
					<Steps
						progressDot
						size="small"
						current={currentStep}
						direction={breakpoints.lg ? 'horizontal' : 'vertical'}
					>
						{steps.map(({ id, title }) => (
							<Steps.Step key={id} title={I18n.get(title)} />
						))}
					</Steps>
				</Col>
				<Col span={24}>
					<Row justify="center">{steps[currentStep].content}</Row>
				</Col>
				<Col span={12}>
					<Buttons
						steps={steps}
						prev={handlePrevStep}
						next={handleNextStep}
						isLoading={isLoading}
						currentStep={currentStep}
					/>
				</Col>
			</Row>
		</Form>
	);
}
