import { message } from 'antd';
import { AxiosError } from 'axios';
import { I18n } from '@aws-amplify/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Response } from '@/types';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/action-plans';

interface CreateTaskRequest {
	organization_id: string;
	action_plan_id: string;
	company_id: string;
	title: string;
	due_date: Date;
	responsible_user_id: string;
	is_completed?: boolean;
}

export const useCreateActionPlanTask = () => {
	const queryClient = useQueryClient();

	return useMutation<any, AxiosError<Response>, CreateTaskRequest>((params) => Service.createTask(params), {
		onError: (err) => {
			message.error(I18n.get(err?.response?.data.message));
		},
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_ACTION_PLAN_TASKS]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_ACTION_PLAN_HISTORY]);
			message.success(I18n.get('Task created successfully'));
		}
	});
};
