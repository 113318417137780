import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import Api from '@/services/api';

const { GET_COMPANIES_CONTRACT_PLANS } = QUERY_KEYS;

export type CompanyDTO = {
	name: string;
	start_date: Date;
	created_at: Date;
	company_id: string;
	is_active: boolean;
	max_upload: number;
	recurrence: string;
	max_minutes: number;
	hierarchy_id: string;
	used_minutes: number;
	url_logo: string | null;
	organization_id: string;
	processed_videos: number;
	expiration_plan_date: Date;
};

async function getAllCompanies(organization_id: string): Promise<CompanyDTO[]> {
	const { data } = await Api.get('/contract-plan/company', { params: { organization_id } });
	return data;
}

export const useGetCompanies = (organization_id: string) => {
	return useQuery({
		queryKey: [GET_COMPANIES_CONTRACT_PLANS, { organization_id }],
		queryFn: () => getAllCompanies(organization_id),
		enabled: !!organization_id,
		retry: 0
	});
};
