import React from 'react';
import { I18n } from '@aws-amplify/core';
import type { FormFieldProps } from './types';
import * as S from './styles';

export function TaskName({ formName = [''], isRequired }: Readonly<FormFieldProps>) {
	const title = I18n.get('New Task');

	return (
		<S.CustomFormItem name={[...formName, 'task_name']} required={isRequired}>
			<S.CustomTextInput maxLength={500} placeholder={title} autoComplete="off" />
		</S.CustomFormItem>
	);
}
