// import Api from '@/services/api';
// import type { AxiosInstance } from 'axios';

// export interface IRead<T, P = any> {
// 	findOne(params: P): Promise<T>;
// 	findAll(): Promise<T[]>;
// }

// export interface IWrite<T = any, P = any> {
// 	create(data: T): Promise<T>;
// 	update(...data: P[]): Promise<T>;
// 	destroy(id: string): Promise<void>;
// }

// export abstract class BaseService<T, P = any> implements IRead<T, P>, IWrite<T, P> {
// 	constructor(private readonly api: AxiosInstance = Api) {}

// 	getInstance(): AxiosInstance {
// 		return this.api;
// 	}

// 	// @ts-ignore
// 	findOne(params: P): Promise<T> {
// 		throw new Error('Method not implemented.');
// 	}

// 	findAll(): Promise<T[]> {
// 		throw new Error('Method not implemented.');
// 	}

// 	create(params: T): Promise<T> {
// 		throw new Error('Method not implemented.');
// 	}

// update(...params: P[]): Promise<T> {
// 	throw new Error('Method not implemented.');
// }

// 	destroy(id: string): Promise<void> {
// 		throw new Error('Method not implemented.');
// 	}
// }

import Api from '@/services/api';
import type { AxiosInstance } from 'axios';

export type BasicInfo = {
	id: string;
	company_id: string;
	organization_id: string;
};

export interface IRead<T> {
	findOne(params: BasicInfo): Promise<T>;
	findAll(): Promise<T[]>;
}

export interface IWrite<T> {
	create(data: T): Promise<T>;
	udpate(data: T): Promise<T>;
	destroy(id: string): Promise<void>;
}

export class BaseService {
	constructor(private readonly api: AxiosInstance = Api) {}

	getInstance(): AxiosInstance {
		return this.api;
	}
}
