import { CustomReportRiskResults } from './enum';

export type RiskLevel = {
	[key: number]: string;
};

export const riskLevels: RiskLevel = {
	1: 'Acceptable risk',
	2: 'Moderate risk',
	3: 'High Risk',
	4: 'Very high risk',
	5: 'Serious and imminent risk'
};

export const customReportPaletteMap: Record<number, string> = {
	[CustomReportRiskResults.LOW]: '#2CC852',
	[CustomReportRiskResults.MODERATE]: '#FFDE31',
	[CustomReportRiskResults.HIGH]: '#F78A38',
	[CustomReportRiskResults.VERY_HIGH]: '#E74150',
	[CustomReportRiskResults.EXTREME]: '#9B54E2'
};

export function rosaPaletteMap(score: number): string {
	return score > 5 ? '#E74150' : '#2CC852';
}
